import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import line from "../../assets/images/line.svg";
import Slider from "@mui/material/Slider";
import plus from "../../assets/images/plus.svg";
import { useDispatch } from "react-redux";
import {
  setPricePerDay as setPricePerDayStore,
  setRevenueShare as setRevenueShareStore,
} from "../../store/Items/actions";
import { useSelector } from "react-redux";

const RentFilters = (props) => {
  const [pricePerDay, setPrice] = useState([null, null]);
  const [revenueShare, setRevenueShare] = useState([0, 100]);

  const dispatch = useDispatch();

  const handleRevenueShare = (e, newValue) => {
    setRevenueShare(newValue);
  };

  const handleRevenueShareEnd = (e, newValue) => {
    console.log("REVENUE DISPATCH");

    dispatch(setRevenueShareStore({ min: newValue[0], max: newValue[1] }));
  };

  const handlePricePerDayChange = (newValue) => {
    console.log("PRICE PER DAY DISPATCH");
    dispatch(setPricePerDayStore(newValue));
  };

  const toggleRentFilters = () =>
    props.setRentFiltersOpen(!props.RentFiltersOpen);

  function valuetext(value) {
    return `${value}`;
  }

  const filterItems = useSelector((state) => state.Items);

  useEffect(() => {
    if (filterItems?.pricePerDay)
      setPrice([filterItems?.pricePerDay?.min, filterItems?.pricePerDay?.max]);
  }, [filterItems?.pricePerDay]);

  return (
    <div>
      <div
        onClick={toggleRentFilters}
        className={`${
          props.RentFiltersOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Rental</div>
        {props.RentFiltersOpen ? <img src={line} /> : <img src={plus} />}
      </div>
      {props.RentFiltersOpen && (
        <Collapse isOpen={props.RentFiltersOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <p className="mb-0 stat-font">Revenue Share %</p>

              <div className="mb-3 row-1 d-flex align-items-center">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={revenueShare}
                  onChange={(e, newValue) => handleRevenueShare(e, newValue)}
                  onChangeCommitted={handleRevenueShareEnd}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={1}
                  marks
                  min={0}
                  max={100}
                />
              </div>

              <p className="mb-0 stat-font">Price per Day</p>

              <div className="flex flex-row justify-between max-w-100 mt-1">
                <p>Min</p>
                <input
                  type="number"
                  className="bg-[#300a3c] w-[64px] rounded-md text-white"
                  onChange={(e) => {
                    setPrice([e.target?.value, pricePerDay[1]]);
                    handlePricePerDayChange({
                      min: e.target?.value,
                      max: pricePerDay[1],
                    });
                  }}
                  value={pricePerDay[0]}
                  step={1}
                ></input>
                <p>Max</p>
                <input
                  type="number"
                  className="bg-[#300a3c] w-[64px] rounded-md text-white"
                  onChange={(e) => {
                    setPrice([pricePerDay[0], e.target?.value]);
                    handlePricePerDayChange({
                      min: pricePerDay[0],
                      max: e.target?.value,
                    });
                  }}
                  value={pricePerDay[1]}
                  step={1}
                ></input>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default RentFilters;
