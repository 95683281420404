import { Fragment } from "react";
import Header from "../../components/Header";
import Welcome from "../../components/home/Welcome";
import About from "../../components/home/About";
import Gaming from "../../components/home/Gaming";
import Team from "../../components/home/Team";
import Roadmap from "../../components/home/Roadmap";
import Partners from "../../components/home/Partners";
import Footer from "../../components/home/Footer";

function Home() {
  return (
    <Fragment>
      <Header disableBoomBalance={true} />
      <Welcome />
      <About />
      <Gaming />
      <Team />
      <Roadmap />
      <Partners />
      <Footer />
    </Fragment>
  );
}

export { Home };
