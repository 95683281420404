/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import HunterOnChain_Banner1 from "../../assets/images/HunterOnChain-Banner1.png";
import { Row } from "react-bootstrap";
import ProductCard from "../../components/cards/ProductCard";

import { Button } from "reactstrap";
import SidebarAssets from "../../context/Layout/SidebarAssets";
import All from "../../components/Dropdown/All";
import SidebarMobile from "../../context/Layout/SidebarMobile";

import Sort from "../../components/Dropdown/Sort";
import { useSelector, useDispatch } from "react-redux";
import {
  getHunters,
  loaderonoff,
  ResetPendingHunters,
} from "../../store/Items/actions";
import Loader from "../../components/loader/Loader";
import { useSequenceWalletConnect } from "../../context";
import { Alert, Box } from "@mui/material";
import { LOCAL_COUNT_KEY } from "../../chain/chainConfig";

const AssetManagement = () => {
  const [FilterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setsortOpen] = useState(false);

  const { walletAddress } = useSequenceWalletConnect();
  const [sortAllOpen, setsortAllOpen] = useState(false);

  const [SeeMoreNumbers, setSeemorenumbuers] = useState(6);
  const allhunters = useSelector((state) => state.Items.allhunters);
  const playable = useSelector((state) => state.Items.playable);
  const items = useSelector((state) => state.Items.items);
  const commontrue = useSelector((state) => state.Items.commontrue);
  const uncommontrue = useSelector((state) => state.Items.uncommontrue);
  const raretrue = useSelector((state) => state.Items.raretrue);
  const epictrue = useSelector((state) => state.Items.epictrue);
  const legendarytrue = useSelector((state) => state.Items.legendarytrue);
  const sale = useSelector((state) => state.Items.onSale);
  const hp = useSelector((state) => state.Items.hp);
  const range = useSelector((state) => state.Items.range);
  const attack = useSelector((state) => state.Items.attack);
  const level = useSelector((state) => state.Items.level);
  const attackSpeed = useSelector((state) => state.Items.speed);
  const mvt = useSelector((state) => state.Items.mvt);
  const damage = useSelector((state) => state.Items.damage);
  const searchString = useSelector((state) => state.Items.searchString);
  const order = useSelector((state) => state.Items.order);
  const orderBy = useSelector((state) => state.Items.orderBy);
  const isLent = useSelector((state) => state.Items.lentTrue);
  const isBorrowed = useSelector((state) => state.Items.borrowedTrue);

  const loaderon = useSelector((state) => state.Items.loaderon);

  const toggleFilter = () => {
    setFilterOpen(!FilterOpen);
  };

  const togglesort = () => {
    setsortOpen(!sortOpen);
  };

  const dispatch = useDispatch();

  // Once loaded, reset the local storage count
  useEffect(() => {
    localStorage.setItem(LOCAL_COUNT_KEY, "0");
    dispatch(ResetPendingHunters());
  }, []);

  useEffect(() => {
    if (walletAddress) {
      dispatch(loaderonoff(true));
      let params = {
        common: commontrue,
        uncommon: uncommontrue,
        rare: raretrue,
        epic: epictrue,
        legendary: legendarytrue,
        minLevel: level.min,
        maxLevel: level.max,
        minSpeed: mvt.min,
        maxSpeed: mvt.max,
        minAttackSpeed: attackSpeed.min,
        maxAttackSpeed: attackSpeed.max,
        maxHp: hp.max,
        minHp: hp.min,
        minDamage: damage.min,
        maxDamage: damage.max,
        searchString: searchString,
        orderBy: orderBy,
        order: order,
        isLent: isLent,
        isBorrowed: isBorrowed,
      };

      if (params) {
        if (playable) {
          dispatch(getHunters(params, walletAddress));
          dispatch(loaderonoff(false));
        } else {
          dispatch(getHunters({}, walletAddress));
          dispatch(loaderonoff(false));
        }
      }
    }
  }, [
    playable,
    items,
    commontrue,
    uncommontrue,
    raretrue,
    epictrue,
    legendarytrue,
    sale,
    level,
    hp,
    damage,
    mvt,
    attack,
    attackSpeed,
    walletAddress,
    searchString,
    order,
    orderBy,
    isLent,
    isBorrowed,
  ]);

  const seemorehandler = () => {
    setSeemorenumbuers(SeeMoreNumbers + 25);
  };

  return (
    <>
      {loaderon && <Loader />}
      <div className="container-x">
        <div className="hero-banner">
          <img src={HunterOnChain_Banner1} alt="HunterOnChain-Banner1" />
        </div>
        <div className="sortbar">
          <h3>My Assets</h3>
          <div className="sort-dropdown">
            <All sortAllOpen={sortAllOpen} setsortAllOpen={setsortAllOpen} />
          </div>
          <div className="Filterbtn">
            <div className="drop Filterbtn">
              <Button
                onClick={() => setsortOpen(!sortOpen)}
                as="div"
                className="d-flex drop-btn align-items-center"
              >
                Sort
              </Button>
            </div>
            <div className="filterbtn-margin drop Filterbtn">
              <Button
                onClick={() => setFilterOpen(!FilterOpen)}
                as="div"
                className="d-flex drop-btn align-items-center"
              >
                Filter
              </Button>
            </div>
          </div>
        </div>
        <span className="border-b" />
        <div className="mt-30">
          <Row className="mx-0">
            <div className="col-left">
              <SidebarAssets />
            </div>
            {/* mobile sidebar Filter  */}

            <SidebarMobile
              FilterOpen={FilterOpen}
              toggleFilter={toggleFilter}
            />

            {/* mobile sort filter */}

            <Sort sortOpen={sortOpen} togglesort={togglesort} />

            <div className="col-right">
              <div className="d-flex gap-4 flex-wrap">
                {walletAddress ? (
                  <Row className="mx-0">
                    <ProductCard
                      Seemorenumbuers={SeeMoreNumbers}
                      setSeemorenumbuers={setSeemorenumbuers}
                    />
                  </Row>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Alert severity="error">Please connect your wallet.</Alert>
                  </Box>
                )}
              </div>

              {allhunters.length > 0 ? (
                allhunters.length > SeeMoreNumbers ? (
                  <div className="d-flex justify-content-center">
                    <div onClick={seemorehandler} className="seemore ">
                      See more
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : walletAddress ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Alert severity="error">No matching hunters found.</Alert>
                </Box>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AssetManagement;
