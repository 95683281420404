import { Contract, ethers, utils } from "ethers";
import { CONTRACT_ADDRESSES } from "./chainConfig";
import { sequence } from "0xsequence";

const publicRpcUrl = "https://rpc.ankr.com/polygon";

const FAUCET_INTERFACE = new utils.Interface([
  "function withdrawTime(address) public view returns (uint256)",
  "function allowedToWithdraw(address) public view returns (bool)",
  "function requestTokens() external",
]);

async function waitHash(txHash) {
  const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);
  let waitInterval = -1;
  await new Promise((resolve) => {
    waitInterval = setInterval(async () => {
      const receipt = await provider.getTransactionReceipt(txHash);
      if (receipt != null) {
        resolve();
      }
    }, 3000);
  });
  clearInterval(waitInterval);
}

export async function requestToken(signer, connector) {
  const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);

  const faucetContract = new Contract(
    CONTRACT_ADDRESSES[137].FAUCET,
    FAUCET_INTERFACE,
    provider
  );

  const requestData = faucetContract.interface.encodeFunctionData(
    "requestTokens",
    []
  );
  const buyTx = { to: faucetContract.address, data: requestData };
  let txHash;
  try {
    if (connector?.id === "sequence") {
      const wallet = await sequence.getWallet();
      const sequenceSigner = wallet.getSigner(137);
      txHash = (await sequenceSigner.sendTransaction([buyTx])).hash;
    } else {
      txHash = await signer.sendTransaction(buyTx);
    }
    await waitHash(txHash);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Claim transaction failed",
    };
  }

  return {
    success: true,
  };
}

export async function getWithdrawTime(signerAddress) {
  const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);

  const faucetContract = new Contract(
    CONTRACT_ADDRESSES[137].FAUCET,
    FAUCET_INTERFACE,
    provider
  );

  try {
    const result = await faucetContract.withdrawTime(signerAddress);

    return {
      time: result,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Couldn't read withdraw time",
    };
  }
}
