/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import "swiper/css/bundle";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";
import hocPlayNowBanner from "../../assets/daily/playNow.png";

import { isMobile } from "react-device-detect";
import storerare from "../../assets/store/storerare.png";
import cc from "../../assets/store/CharacterCard.png";
import { BigNumber, Contract, ethers } from "ethers";
import { immutableConfig } from "../../config";
import { cn } from "../../helpers/functions.helper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PassportProvider, usePassport } from "../../context/PassportProvider";
import t3ChestGif from "../../assets/videos/RotatingChest-T3.gif";
import toast, { Toaster } from "react-hot-toast";

const LiveComponent = ({ totalMinted, supply }) => {
  return (
    <div className="flex flex-row justify-start mb-3">
      <div className="flex flex-row p-2 bg-[#2d1d36]">
        <div className="flex flex-row">
          <div className="h-3 w-3 rounded-full bg-[#00d200] mr-2 self-center" />
          <span className="text-[#00d200]">Live</span>
        </div>
      </div>

      <div className="flex flex-col justify-center ml-2 w-full">
        <div className="flex flex-row justify-between self-center w-full">
          <span className="opacity-60">Minted</span>
          <div>
            <span className="font-bold">
              {((totalMinted / supply) * 100).toFixed(2)}%
            </span>
            <span className="opacity-60 px-1">
              {`(${totalMinted}/${supply})`}
            </span>
          </div>
        </div>
        <div className="h-3 w-full rounded-full bg-[#6e3f89] relative">
          <div
            className="h-3 rounded-full bg-[#ff7e00] absolute h-0 left-0 w-[73%]"
            style={{
              width: `${(totalMinted / supply) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const stages = [
  {
    name: "Closed",
    supply: 0,
    id: 0,
  },
  {
    name: "Guaranteed Mint",
    startDate: new Date("2024-08-06 13:00Z"),
    endDate: new Date("2024-08-06 14:00Z"),
    supply: 3250,
    price: "80000000000000000000",
    maxPerWallet: 1,
    id: 1,
  },
  {
    name: "Whitelisted Mint",
    startDate: new Date("2024-08-06 14:00Z"),
    endDate: new Date("2024-08-06 15:00Z"),
    supply: 2000,
    price: "90000000000000000000",
    maxPerWallet: 4,
    id: 2,
  },
  {
    name: "Public Mint",
    startDate: new Date("2024-08-06 15:00Z"),
    endDate: new Date("2024-08-07 15:00Z"),
    supply: 750,
    price: "100000000000000000000",
    maxPerWallet: 3,
    id: 3,
  },
];

function getTimeLeft(futureDate) {
  const now = new Date();
  const future = new Date(futureDate);

  // Calculate the difference in milliseconds
  const diff = future - now;

  // Check if the future date is in the past
  if (diff <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Convert milliseconds to days, hours, minutes, and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

function Mint() {
  const [playNowBanner, setPlayNowBanner] = useState(
    "https://hoc-imx.boomland.io"
  );
  const [currentStage, setCurrentStage] = useState();
  const [isOnWhitelist, setIsOnWhitelist] = useState(false);
  const [totalMinted, setTotalMinted] = useState(0);
  const [mintedGuaranteed, setMintedGuaranteed] = useState(0);
  const [mintedWhitelist, setMintedWhitelist] = useState(0);
  const [mintedPublic, setMintedPublic] = useState(0);
  const [timeLeft, setTimeLeft] = useState({});
  const [currentSupply, setCurrentSupply] = useState(0);

  const [mintAmount, setMintAmount] = useState(1);
  const [isMinting, setIsMinting] = useState(false);

  const { connect, signer, disconnect, address } = usePassport();

  const updateTotalMinted = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );
    const totalMinted = await mintContract.totalMinted();
    setTotalMinted(+totalMinted);
  };

  const getCurrentStage = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );
    const stage = await mintContract.currentStage();
    console.log({ stage });
    setCurrentStage(stages[stage]);
  };

  const getTime = () => {
    const time = {};

    stages.forEach((stage) => {
      const timeLeft = getTimeLeft(
        currentStage?.id === stage.id ? stage.endDate : stage.startDate
      );
      time[stage.id] = timeLeft;
    });

    setTimeLeft(time);
  };

  const getCurrentSupply = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );

    let exceedSupply = 0;

    if (currentStage?.id === 2) {
      const totalMintedOnPhase1 = await mintContract.guaranteedMinted();
      exceedSupply = stages[2].supply - totalMintedOnPhase1;
    } else if (currentStage?.id === 3) {
      const totalMintedOnPhase1 = await mintContract.guaranteedMinted();
      const totalMintedOnPhase2 = await mintContract.whitelistedMinted();

      exceedSupply =
        stages[1].supply -
        +totalMintedOnPhase1 +
        (stages[2].supply - +totalMintedOnPhase2);
    }

    setCurrentSupply(currentStage.supply + exceedSupply);
  };

  useEffect(() => {
    getCurrentStage();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!currentStage) return;

    updateTotalMinted();
    getCurrentSupply();
  }, [currentStage]);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  useEffect(() => {
    if (isMobile) {
      const operatingSystem = getMobileOperatingSystem();

      operatingSystem === "iOS"
        ? setPlayNowBanner("https://testflight.apple.com/join/mnB642dv")
        : setPlayNowBanner(
            "https://play.google.com/store/apps/details?id=com.hunters.on.chain.mainnet"
          );
    } else {
      setPlayNowBanner("https://hoc.boomland.io");
    }
  }, [isMobile]);

  const checkWhitelist = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );

    let isOnList = false;

    if (currentStage?.id === 1)
      isOnList = await mintContract.guaranteedList(address);
    else if (currentStage?.id === 2)
      isOnList = await mintContract.whitelist(address);
    else if (currentStage?.id === 3) isOnList = true;

    setIsOnWhitelist(isOnList);
  };

  const getTotalMinted = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );
    const minted = await mintContract.totalMinted();
    setTotalMinted(minted);
  };

  const getMintedPerPhase = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      immutableConfig.rpcUrl
    );
    const mintContract = new Contract(
      immutableConfig.contracts.mint.address,
      immutableConfig.contracts.mint.abi,
      provider
    );

    let minted = 0;
    if (currentStage?.id === 1) {
      minted = await mintContract.guaranteedMinted();
      setMintedGuaranteed(+minted);
    }
    if (currentStage?.id === 2) {
      minted = await mintContract.whitelistedMinted();
      setMintedWhitelist(+minted);
    }
    if (currentStage?.id === 3) {
      minted = await mintContract.publicMinted();
      setMintedPublic(+minted);
    }
  };

  useEffect(() => {
    if (currentStage?.id) {
      checkWhitelist();
      getMintedPerPhase();
    }
  }, [currentStage, address]);

  useEffect(() => {
    getTotalMinted();
  }, []);

  const mint = async () => {
    if (!mintAmount) return;
    try {
      setIsMinting(true);
      const mintContract = new Contract(
        immutableConfig.contracts.mint.address,
        immutableConfig.contracts.mint.abi,
        signer
      );

      const tx = await mintContract.mint(mintAmount, {
        gasLimit: 30000000,
        value: BigNumber.from(currentStage.price).mul(mintAmount),
      });

      await tx.wait(1);
      setIsMinting(false);
      toast.success("Success!");
    } catch (e) {
      setIsMinting(false);
      if (e.message?.includes("Sale is closed"))
        toast.error("Sale is closed, please come back later.");
      else if (e.message?.includes("Limit reached for wallet"))
        toast.error("Mint limit reached for wallet.");
      else if (e.message?.includes("Exceeds supply"))
        toast.error(
          "There are no more chests available for this phase, supply has ended."
        );
      else toast.error("An Error happened, please try again.");
    }
  };

  return (
    <Fragment>
      <div className="container-x">
        <Toaster />

        <a href={playNowBanner}>
          <img
            src={hocPlayNowBanner}
            alt="Play Now Banner"
            className="w-full py-4"
          />
        </a>

        {/* Title Section */}
        <div className="sortbar">
          <h3 style={{ fontSize: isMobile ? 18 : 24 }}>
            T3 GENESIS CHEST <b>MINT</b>
          </h3>
        </div>
        <span className="border-b" />
        {/* Reward Information Section */}
        <div className="mt-30 ">
          <Row className="justify-center gap-5">
            <Col className="bg-[#2d1c35] shadow-lg rounded-2xl md:h-[520px] flex justify-center py-2.5">
              <img
                src={t3ChestGif}
                className="flex rounded-lg h-[500px] w-[500px] self-center"
              />
            </Col>
            {/* Claim Reward Section */}
            <Col className="bg-[#2d1c35] shadow-lg rounded-2xl  md:h-[580px] py-2 flex flex-col">
              {address && (
                <div className="flex justify-between">
                  <span className="text-md">Mint Stages</span>

                  <div className="flex flex-row">
                    <span className="mr-2 self-center mb-1">{`${address.slice(
                      0,
                      4
                    )}...${address.slice(
                      address.length - 5,
                      address.length
                    )}`}</span>
                    <button
                      className="flex self-center  flex-row text-center rounded-md self-center mb-2 px-2 py-1 opacity-70 bg-gray-500 bg-opacity-60"
                      onClick={disconnect}
                    >
                      <span className="text-md font-bold text-center self-center">
                        Disconnect
                      </span>
                    </button>
                  </div>
                </div>
              )}
              <div className="px-3 flex flex-col justify-start h-full w-full">
                <div
                  className={cn([
                    "p-0.5 rounded-lg my-1",
                    currentStage?.id === 1 &&
                      "bg-gradient-to-r from-[#7b2cb0] to-[#deaa0f]",
                  ])}
                >
                  <div
                    className={cn([
                      `flex flex-col bg-[#24162b] rounded-lg py-1 px-2 border-2 border-[#302339]`,
                    ])}
                  >
                    {currentStage?.id === 1 && (
                      <LiveComponent
                        supply={stages[1].supply}
                        totalMinted={mintedGuaranteed}
                      />
                    )}

                    <div className="flex flex-row justify-between">
                      <span className="rounded-full px-2 bg-[#3b2547] font-bold text-center self-center">
                        Phase 1
                      </span>
                      <div>
                        <span
                          className={cn([
                            `opacity-60 font-bold text-center self-center`,
                            currentStage?.id === 1 && "text-[#ff7e00]",
                          ])}
                        >
                          {currentStage?.id === 1 ? "ENDS IN" : "STARTS IN"}
                        </span>
                      </div>
                      <div className="flex flex-row justify-start">
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[1]?.days}D
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[1]?.hours}H
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[1]?.minutes}M
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[1]?.seconds}S
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-row mt-3">
                      <span>
                        GUARANTEED MINT <b>{stages[1].supply}</b>
                      </span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>{stages[1].maxPerWallet} PER WALLET</span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>
                        PRICE {Number(stages[1].price) / 1000000000000000000}{" "}
                        $IMX
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={cn([
                    "p-0.5 rounded-lg my-1",
                    currentStage?.id === 2 &&
                      "bg-gradient-to-r from-[#7b2cb0] to-[#deaa0f]",
                  ])}
                >
                  <div className="flex flex-col bg-[#24162b] rounded-lg py-1 px-2 border-2 border-[#302339]">
                    {currentStage?.id === 2 && (
                      <LiveComponent
                        supply={currentSupply}
                        totalMinted={mintedWhitelist}
                      />
                    )}
                    <div className="flex flex-row justify-between">
                      <span className="rounded-full px-2 bg-[#3b2547] font-bold text-center self-center">
                        Phase 2
                      </span>
                      <div>
                        <span
                          className={cn([
                            `opacity-60 font-bold text-center self-center`,
                            currentStage?.id === 2 && "text-[#ff7e00]",
                          ])}
                        >
                          {currentStage?.id === 2 ? "ENDS IN" : "STARTS IN"}
                        </span>
                      </div>
                      <div className="flex flex-row justify-start">
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[2]?.days}D
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[2]?.hours}H
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[2]?.minutes}M
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[2]?.seconds}S
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row mt-3">
                      <span>
                        WHITELISTED MINT <b>{stages[2].supply}</b>
                      </span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>{stages[2].maxPerWallet} PER WALLET</span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>
                        PRICE {Number(stages[2].price) / 1000000000000000000}{" "}
                        $IMX
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={cn([
                    "p-0.5 rounded-lg my-1",
                    currentStage?.id === 3 &&
                      "bg-gradient-to-r from-[#7b2cb0] to-[#deaa0f]",
                  ])}
                >
                  <div className="flex flex-col bg-[#24162b] rounded-lg py-1 px-2 border-2 border-[#302339]">
                    {currentStage?.id === 3 && (
                      <LiveComponent
                        supply={currentSupply}
                        totalMinted={mintedPublic}
                      />
                    )}
                    <div className="flex flex-row justify-between">
                      <span className="rounded-full px-2 bg-[#3b2547] font-bold text-center self-center">
                        Phase 3
                      </span>
                      <div>
                        <span
                          className={cn([
                            `opacity-60 font-bold text-center self-center`,
                            currentStage?.id === 3 && "text-[#ff7e00]",
                          ])}
                        >
                          {currentStage?.id === 3 ? "ENDS IN" : "STARTS IN"}
                        </span>
                      </div>
                      <div className="flex flex-row justify-start">
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[3]?.days}D
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[3]?.hours}H
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[3]?.minutes}M
                        </span>
                        <span className="bg-[#1c1123] p-0.5 text-md mx-2">
                          {timeLeft?.[3]?.seconds}S
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row mt-3">
                      <span>
                        PUBLIC MINT <b>{stages[3].supply}</b>
                      </span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>{stages[3].maxPerWallet} PER WALLET</span>
                      <div className="h-1.5 w-1.5 rounded-full bg-white self-center mx-2" />
                      <span>
                        PRICE {Number(stages[3].price) / 1000000000000000000}{" "}
                        $IMX
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-start bg-[#24162b] rounded-lg py-1 px-2 border-2 border-[#302339]">
                  <div className="flex flex-row justify-start">
                    <div className="flex flex-row p-2 bg-[#2d1d36]">
                      {currentStage?.supply ? (
                        <div className="flex flex-row">
                          <div className="h-3 w-3 rounded-full bg-[#00d200] mr-2 self-center" />
                          <span className="text-[#00d200]">Live</span>
                        </div>
                      ) : (
                        <div className="font-bold">Upcoming</div>
                      )}
                    </div>

                    <div className="flex flex-col justify-center ml-2 w-full mt-2">
                      <div className="flex flex-row justify-between self-center w-full">
                        <span className="opacity-60">Total Minted</span>
                        <div>
                          <span className="font-bold">
                            {(
                              (totalMinted /
                                stages.reduce(
                                  (partialSum, a) => partialSum + a.supply,
                                  0
                                )) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                          <span className="opacity-60 px-1">
                            {`(${totalMinted}/${stages.reduce(
                              (partialSum, a) => partialSum + a.supply,
                              0
                            )})`}
                          </span>
                        </div>
                      </div>
                      <div className="h-3 w-full rounded-full bg-[#6e3f89] relative">
                        <div
                          className="h-3 rounded-full bg-[#ff7e00] absolute h-0 left-0 w-[73%]"
                          style={{
                            width: `${
                              (totalMinted /
                                stages.reduce(
                                  (partialSum, a) => partialSum + a.supply,
                                  0
                                )) *
                              100
                            }%`,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-start">
                    <div className="flex flex-col self-start my-2">
                      <span className="font-bold">Price</span>
                      <div className="flex flex-row bg-[#1f1326] border0sm px-2 py-1 text-3xl font-bold">
                        $IMX{" "}
                        {currentStage?.price
                          ? currentStage?.price / 1000000000000000000
                          : "-"}
                      </div>
                    </div>

                    {address && isOnWhitelist && (
                      <div className="flex flex-row justify-center ml-10 ">
                        <button
                          onClick={() =>
                            setMintAmount(
                              mintAmount - 1 < 0 ? 0 : mintAmount - 1
                            )
                          }
                        >
                          <RemoveIcon height={110} width={110} />
                        </button>

                        <span className="p-2 bg-[#1f1326] text-4xl font-bold text-center self-center mx-3">
                          {mintAmount}
                        </span>
                        <div className="flex flex-row justify-center">
                          <button
                            onClick={() =>
                              setMintAmount(
                                mintAmount + 1 > currentStage?.maxPerWallet
                                  ? mintAmount
                                  : mintAmount + 1
                              )
                            }
                          >
                            <AddIcon />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {!address && (
                    <button
                      onClick={connect}
                      className="bg-gradient-to-r from-[#9734d9] to-[#f5bc0c] shadow-sm shadow-[#00d200] hover:bg-green-600 rounded-lg py-2 text-xl font-bold mb-2"
                    >
                      Connect your Immutable Wallet
                    </button>
                  )}

                  {address && !isOnWhitelist && (
                    <button
                      disabled
                      className="bg-gray-700 shadow-sm shadow-[#00d200] rounded-lg py-2 text-xl font-bold mb-2"
                    >
                      You are not on the whitelist
                    </button>
                  )}

                  {address && isOnWhitelist && (
                    <div className="flex flex-col">
                      <button
                        onClick={mint}
                        className="bg-[#00d200] shadow-sm shadow-[#00d200] rounded-lg py-2 text-xl font-bold mb-2"
                      >
                        {isMinting ? (
                          <ClipLoader
                            css={spinnerCss}
                            size={20}
                            color={"#fff"}
                          />
                        ) : (
                          "MINT"
                        )}
                      </button>
                      <button
                        className="self-end py-1 text-sm opacity-60"
                        onClick={disconnect}
                      >
                        Disconnect Wallet
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-30 store-card-bg">
          <h4 className="store-header-h4">What’s inside the Chest?</h4>
          <Row gap="4">
            <Col className="mb-4 mb-lg-0" sm={12} md={12} lg={5} xl={5}>
              <Card className="tiercard store-card-shadow">
                <CardHeader className="store-card-headername d-flex justify-content-between align-items-center">
                  <p className="">HUNTERS PER CHEST</p>
                  <p className="">1</p>
                </CardHeader>
                <CardBody className="store-card-info">
                  <div className="px-2">
                    <p className="store-card-body-title">
                      Genesis Artifact equipped
                    </p>
                    <p className="store-card-body-description">{"Rare"} BGEM</p>
                    <p className="store-card-body-description">
                      {"25%"} More Earnings
                    </p>
                    <p className="store-card-body-description">
                      Premium Pass for Future Game Content
                    </p>
                    <p className="store-card-body-description">
                      Boost in Boom Power (Tier {"3"})
                    </p>
                  </div>
                  <div>
                    <img
                      src={storerare}
                      alt="Genesis Artifact"
                      style={{
                        maxWidth: "112px",
                        maxHeight: "112px",
                        width: "120px",
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7}>
              <div>
                <img src={cc} alt="Character Cards" />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="flex flex-col justify-center mt-12">
        <div className="flex flex-row mt-8 w-full items-center justify-center">
          <a
            href="https://discord.com/invite/boomland"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="Socials Discord" className="w-12 px-1" />
          </a>
          <a
            href="https://t.me/BoomlandAnnouncements"
            target="_blank"
            rel="noreferrer"
          >
            <img src={teligram} alt="Socials X" className="w-12 px-1" />
          </a>
          <a
            href="https://twitter.com/BoomLandGames"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="Socials Medium" className="w-12 px-1" />
          </a>
          <a
            href="https://boomlandgames.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={medium} alt="Socials" className="w-12 px-1" />
          </a>
        </div>
        <div className="self-center py-1">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </Fragment>
  );
}

const MintComponent = () => (
  <PassportProvider>
    <Mint />
  </PassportProvider>
);
export default MintComponent;
