import React from "react";
import AboutSwiperMeet from "./AboutSwiperMeet";

class About extends React.Component {
  render() {
    return (
      <section className="section section-home section-about">
        <div className="container">
          <div className="row section-header">
            <div className="col-lg-12 col-10 offset-1 offset-lg-0">
              <h2 className="section-title">MEET BOOMLAND</h2>
              <p className="section-paragraph">
              <br/>BoomLand is Blockchain game developer and publisher with its own NFT marketplace and decentralized ecosystem.
                Our mission is to push the mass adoption of Web3 gaming by targeting and onboarding traditional Web2 players.
                Our first game, “Hunters On-Chain”, is a mobile first, multiplayer RPG.<br/><br/>
                The studio is led by a team of Veterans of the Mobile gaming industry responsible for publishing over 200 games, generating more than 1.5 Billion downloads to date.
              </p>
            </div>
          </div>
        </div>


      </section>
    );
  }
}

export default About;
