/**
 * @file chest.js
 * @description Holds chest functions
 * @typedef {{
 *  type: number;
 *  level: number;
 *  head: number;
 *  body: number;
 *  acc1: number | null;
 *  acc2: number | null;
 *  rarity: number;
 *  hp: number;
 *  damage: number;
 *  speed: number;
 *  attackSpeed: number;
 *  maxDailyHunterLimit: number;
 *  generatedName: string;
 *  name: string;
 * }} HunterData
 */

import { Contract, utils } from "ethers";
import { POST_HUNTER } from "../helpers/url.helper";
import { CONTRACT_ADDRESSES, CHAIN_ID } from "./chainConfig";
import { getChestItems } from "./chestItems";
import { BASE_URL } from "../config";

const MAGIC_EDEN_INTERFACE = new utils.Interface([
  "function approve(address, uint256) external",
  "function setApprovalForAll(address, bool) external",
  "function isApprovedForAll(address, address) external view returns(bool)",
]);

const MAGIC_EDEN_OPENING_INTERFACE = new utils.Interface([
  "function open(uint256,uint256) external",
]);

/**
 * @param {import("ethers").Signer} signer
 * @param {(state: string) => void | undefined} _setLastState
 * @returns {Promise<{
 *  success: true,
 *  data: HunterData,
 * } | {
 *  success: false,
 *  message: string
 * }>}
 */
export async function openChestAndGetHunter(
  chestType,
  signer,
  _setLastState,
  _incrementPendingHunters
) {
  if (![1, 2].includes(chestType)) {
    return {
      success: false,
      message: "Invalid Chest Type",
    };
  }

  const setLastState = (state) => {
    if (_setLastState) _setLastState(state);
  };

  const incrementPendingHunters = () => {
    if (_incrementPendingHunters) _incrementPendingHunters();
  };

  setLastState(1);

  const signerAddress = await signer.getAddress();

  const getChestsResponse = await getChestItems(signerAddress);

  const { tier1_items, tier2_items } = getChestsResponse;
  const items = chestType === 1 ? tier1_items : tier2_items;

  if (items.length === 0) {
    return {
      success: false,
      message: "Account has no chests",
    };
  }

  const randomItem = items[Math.floor(Math.random() * items.length)];

  const magicEdenChestContractAddress =
    chestType === 1
      ? CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN
      : CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN_TIER2;

  const magicEdenChestContract = new Contract(
    magicEdenChestContractAddress,
    MAGIC_EDEN_INTERFACE,
    signer
  );
  const magicEdenChestOpeningContract = new Contract(
    CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN_OPENING,
    MAGIC_EDEN_OPENING_INTERFACE,
    signer
  );

  console.log("items", items);
  console.log("randomItem", randomItem);
  console.log("magicEdenChestOpeningContract", magicEdenChestOpeningContract);

  setLastState(2);

  try {
    const approveTransaction = await magicEdenChestContract.approve(
      magicEdenChestOpeningContract.address,
      String(randomItem)
    );
    await approveTransaction.wait();
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Approve transaction failed",
    };
  }

  setLastState(3);

  let openingTransaction = null;

  try {
    openingTransaction = await magicEdenChestOpeningContract.open(
      randomItem,
      chestType
    );
    console.log("openingTransaction", openingTransaction);
    await openingTransaction.wait();
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Opening transaction failed",
    };
  }

  if (!openingTransaction) {
    return {
      success: false,
      message: "Opening transaction failed",
    };
  }

  setLastState(4);
  let hunterDataResponse = null;
  try {
    hunterDataResponse = await fetch(BASE_URL + POST_HUNTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        txHash: openingTransaction.hash,
      }),
    }).then((response) => response.json());
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "API call failed",
    };
  }

  if (hunterDataResponse.resultCode === 0) {
    console.error(hunterDataResponse);
    return {
      success: false,
      message: "Get hunter from tx failed",
      error: hunterDataResponse.error,
    };
  }

  const hunter = hunterDataResponse?.response?.hunterDetails;
  if (!hunter) {
    return {
      success: false,
      message: "No hunter detail in response",
    };
  }

  incrementPendingHunters();

  return {
    success: true,
    data: hunter,
  };
}
