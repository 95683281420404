import React from "react";
import { WalletConnectProvider } from "..";
import { MetamaskConnectProvider } from "../MetamaskConnectContext";
import DefiHeader from "./DefiHeader";

const MainLayout = ({ children }) => {
  return (
    <WalletConnectProvider>
      <MetamaskConnectProvider>
        <div className="main">
          <DefiHeader />
          {children}
        </div>
      </MetamaskConnectProvider>
    </WalletConnectProvider>
  );
};

export default MainLayout;
