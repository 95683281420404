import React from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Input,
  Label,
  Col,
} from "reactstrap";

import Name_logo from "../../assets/images/Name-logo.png";
// import Name_logo from '../../assets/images/huntersonchainlogo.png'
import energy from "../../assets/images/energy.png";

import Damage from "../../assets/images/Damage.png";
import HP from "../../assets/images/HP.png";
import Range from "../../assets/images/Range.png";
import Attack from "../../assets/images/Attack.png";
import Speed from "../../assets/images/Speed.png";
import Mvt from "../../assets/images/Mvt.png";

import clock from "../../assets/images/clock.svg";
import $BOOM from "../../assets/images/$BOOM.svg";

import p1 from "../../assets/images/p1.svg";
import p2 from "../../assets/images/p2.svg";
import p3 from "../../assets/images/p3.svg";
import p4 from "../../assets/images/p4.svg";
import storerare from "../../assets/store/storerare.png";

// import one from '../../assets/asset-images/Artifacts/1.png'

import heroes1 from "../../assets/images/heroes1.png";
import genesisArtifact from "../../assets/asset-images/Perks_Numbered/aGenesis.png";
import epicGenesisArtifact from "../../assets/asset-images/Perks_Numbered/a45023.png";
import { useSelector } from "react-redux";

const rangeArray = [
  "Long",
  "Short",
  "Medium",
  "Medium",
  "Medium",
  "Long",
  "Long",
  "Short",
  "Long",
  "Short",
  "Long",
  "Long",
  "Medium",
  "Long",
  "Very Long",
  "Long",
  "Long",
  "Short",
  "Very Short",
  "Medium",
  "Long",
  "Long",
  "Long",
  "Medium",
  "Long",
  "Medium",
  "Long",
  "Long",
  "Medium",
  "Medium",
  "Medium",
  "Long",
  "Medium",
  "Short",
  "Long",
  "Long",
  "",
  "Long",
  "Short",
  "",
  "Very Long",
  "",
  "",
  "Long",
];
const attackArray = [
  "Ricochet Arrow",
  "Slash",
  "Fireball",
  "Toxic Cone",
  "Energy Ball",
  "Dark Circle",
  "Spiders Spawn",
  "Fire Slash",
  "Roots Force",
  "Stealth Attack",
  "Command Imps",
  "Frostbolt",
  "Triple Axe",
  "Rock Throw",
  "Snipe Arrow",
  "Parley",
  "Drain Life",
  "Frenzy",
  "Rage Attack",
  "Shotgun",
  "Boom!",
  "Tornado",
  "Rolling log",
  "Royal Jewels",
  "Shuriken",
  "Gears",
  "Hammer Time",
  "Stealth Shot",
  "Haunt",
  "Hat",
  "Vampire Bite",
  "Disease",
  "Tentacles",
  "Charge",
  "Lightning",
  "Burn",
  "",
  "Ice Zone",
  "Kong Form",
  "",
  "Hook",
  "",
  "",
  "Death Master",
];

/**
 * @typedef {{ tokenId: number, type: number, amount: number, rarity: number }} ItemData
 * @param { null
 * | { chestItem: import("../../chain/chest").HunterData, itemType: 'HUNTER' }
 * | { chestItem: ItemData, itemType: 'ARTIFACT' }
 * | { chestItem: ItemData, itemType: 'EQUIPMENT' }
 * | { chestItem: ItemData, itemType: 'SHARDS' }
 * } props
 * @returns
 */
const Storecard = (props) => {
  const { chestItem, itemType, chestType } = props;
  // const chestItem = {
  //       amount: 4,
  //       rarity: 2,
  //       tokenId: 5,
  //       type: 1,
  //       imgid: '005',
  //       generatedName: '005204000102',
  //       level: 1,
  //       name: 'Voodoo'
  //     }

  // const itemType = 'HUNTER'

  // If one of the props is null, return an empty div
  if (chestItem == null || itemType == null) {
    return <div></div>;
  }

  const getItemImage = () => {
    switch (itemType) {
      case "ARTIFACT":
        return require(`../../assets/asset-images/Perks_Numbered/a${chestItem?.tokenId}.png`);
      case "EQUIPMENT":
        return require(`../../assets/asset-images/Equipments/${chestItem?.type}.png`);
      case "SHARDS":
        return require(`../../assets/asset-images/Shards/${chestItem?.tokenId}.png`);
    }
  };

  const getHunterWebm = () => {
    if (itemType !== "HUNTER") {
      return null;
    }

    const imgId = chestItem.generatedName.slice(0, 3);
    return `https://hunt-nft.cdn.boombit.cloud/Gifs/${imgId}/${chestItem.generatedName}.gif`;
  };

  const getBgClass = (rarity) => {
    switch (rarity) {
      case 0:
        return "bg-common";
      case 1:
        return "bg-uncommon";
      case 2:
        return "bg-rare";
      case 3:
        return "bg-epic";
      case 4:
        return "bg-legendary";
      default:
        return "";
    }
  };

  const getTextClass = ({ rarity }) => {
    switch (rarity) {
      case 0:
        return "text-common";
      case 1:
        return "text-uncommon";
      case 2:
        return "text-rare";
      case 3:
        return "text-epic";
      case 4:
        return "text-legendary";
      default:
        return "";
    }
  };

  const getRarityName = ({ rarity }) => {
    switch (rarity) {
      case 0:
        return "COMMON";
      case 1:
        return "UNCOMMON";
      case 2:
        return "RARE";
      case 3:
        return "EPIC";
      case 4:
        return "LEGENDARY";
      default:
        return "";
    }
  };

  // const m = {
  //   amount: 4,
  //   rarity: 2,
  //   tokenId: 5,
  //   type: 1,
  //   imgid: '005',
  //   generatedName: '005204000102',
  //   level: 1,
  //   name: 'Voodoo'
  // }

  //  console.log(m)
  // const imgid = m?.generatedName.slice(0, 3)

  // console.log(`https://hunt-nft.cdn.boombit.cloud/Recordings/imgid/${m?.details?.generatedName}.webm`)

  return (
    <Card className="store-card-mw productcard ">
      <div
        className={`cardbg-video ${getBgClass(
          chestItem
        )} relative d-flex align-items-center justify-content-center`}
      >
        <img
          className="name-logo"
          src={require("../../assets/images/Name-logo.png")}
          alt="Name_logo"
        />
        <img
          className="herovideo"
          src={getHunterWebm()}
          style={{ width: "100%" }}
        />
        {/* <img className='heroimg' alt="heroes_3" src={heroes1}/> */}

        <div className="powerbar">
          <div className="mb-2"></div>
          <div className="mb-2"></div>
          <div className="mb-2"></div>
          <div className="mb-2">
            <img
              src={chestType === 1 ? genesisArtifact : chestType === 2 ? epicGenesisArtifact: storerare}
              alt="powerbadge"
            />
          </div>
        </div>
      </div>
      <CardBody className="p-0">
        <div className="name-card d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0 name">{chestItem?.name}</p>
            <p className={`mb-0 rarity ${getTextClass(chestItem)}`}>
              {getRarityName(chestItem)}
            </p>
          </div>
          <span className="mb-0 level">LEVEL {chestItem?.level + 1}</span>
        </div>
      </CardBody>
      <CardBody className="p-0 stats-card">
        <div className="mb-0 row name-card">
          <div className="col stat-direction">
            <div className="col   single-stat d-flex align-items-center">
              <img src={Damage} alt="Damage" />
              <div className="stats-w-min statsbadge">{chestItem?.damage}</div>
            </div>
            <div className="col   single-stat  d-flex align-items-center">
              <img src={HP} alt="HP" />
              <div className="stats-w-min statsbadge">{chestItem?.hp}</div>
            </div>
          </div>
          <div className="col stat-direction">
            <div className="col middle-state single-stat d-flex align-items-center">
              <img src={Range} alt="Range" />
              <div className="statsbadge">{rangeArray[chestItem?.type]}</div>
            </div>
            <div className="col middle-state single-stat  d-flex align-items-center">
              <img src={Attack} alt="HP" />
              <div className="statsbadge">{attackArray[chestItem?.type]}</div>
            </div>
          </div>
          <div className="col stat-direction">
            <div className="col   single-stat d-flex align-items-center">
              <img src={Speed} alt="Speed" />
              <div className="stats-w-min statsbadge">
                {chestItem?.attackSpeed}
              </div>
            </div>
            <div className="col   single-stat  d-flex align-items-center">
              <img src={Mvt} alt="HP" />
              <div className="stats-w-min statsbadge">{chestItem?.speed}</div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardBody>
        <div className="price-card d-flex align-items-center justify-content-end"></div>
      </CardBody>
    </Card>
  );
};

export default Storecard;
