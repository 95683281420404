import React from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);

class RoadmapSwiperTimeline extends React.Component {
  render() {
    return (
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true}
        autoHeight={true}
        pagination={{ clickable: true }}
        onSlideChange={(data) => {
          var $index = data.realIndex;
          var $timeline = document.getElementById("roadmap-timeline");
          var $fill = document.getElementById("roadmap-timeline-fill");
          var $cursor = document.getElementById("roadmap-timeline-cursor");
          var $cursor_dot = document.getElementById(
            "roadmap-timeline-cursor-dot"
          );
          if ($index === 0) {
            $timeline.setAttribute("data-fill", "25");
            $fill.style.width = "25%";
            $cursor.style.left = "25%";
            $cursor_dot.style.left = "25%";
          } else if ($index === 1) {
            $timeline.setAttribute("data-fill", "50");
            $fill.style.width = "50%";
            $cursor.style.left = "50%";
            $cursor_dot.style.left = "50%";
          } else if ($index === 2) {
            $timeline.setAttribute("data-fill", "75");
            $fill.style.width = "75%";
            $cursor.style.left = "75%";
            $cursor_dot.style.left = "75%";
          } else if ($index === 3) {
            $timeline.setAttribute("data-fill", "100");
            $fill.style.width = "100%";
            $cursor.style.left = "100%";
            $cursor_dot.style.left = "100%";
          }
        }}
        className="text-center"
      >
        <SwiperSlide>
          <h3 className="roadmap-subtitle mb-3">Q1 2024</h3>

          <ul className="roadmap-list list-unstyled mb-5">
            <li>New Chain Announcement</li>
            <li>Marketing Collaboration Campaign</li>
            <li>Revised Rewards for Hardcore Hunt</li>
            <li>Clan War Event</li>
            <li>Artifact Upcycling</li>
            <li>Implementing Community Suggestions</li>
            <li>Co-Op Mode</li>
            <li>$BOOM Listing</li>
          </ul>
        </SwiperSlide>
        <SwiperSlide>
          <h3 className="roadmap-subtitle mb-3">Q2 2024</h3>

          <ul className="roadmap-list list-unstyled mb-5">
            <li>Move to New Chain</li>
            <li>Burn Mechanics</li>
            <li>Summoning</li>
            <li>Rental Whitelisting</li>
            <li>Implementing Community Suggestions (cont.)</li>
            <li>New and Additional Leaderboards</li>
            <li>Seasonal In-Game Events</li>
            <li>T3 Chests</li>
          </ul>
        </SwiperSlide>
        <SwiperSlide>
          <h3 className="roadmap-subtitle mb-3">H2 2024</h3>

          <ul className="roadmap-list list-unstyled mb-5">
            <li>Season Pass</li>
            <li>New Hunters</li>
            <li>New Missions</li>
            <li>Duel Mode</li>
          </ul>
        </SwiperSlide>
      </Swiper>
    );
  }
}

export default RoadmapSwiperTimeline;
