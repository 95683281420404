/* eslint-disable import/no-anonymous-default-export */
import MarketplaceABI from "../contracts/marketplace.json";
import HunterABI from "../contracts/hunter.json";
import ArtifactABI from "../contracts/artifact.json";
import EquipmentABI from "../contracts/equipment.json";
import ShardABI from "../contracts/shard.json";
import VestingABI from "../contracts/VestingABI.json";
import HunterLendingABI from "../contracts/hunterLendingAbi.json";
import ERC20ABI from "../contracts/erc20.json";
import { getCookie } from "typescript-cookie";
import SWAPABI from "../contracts/swap.json";
import MintAbi from "../contracts/mintAbi.json";
import ClaimAbi from "../contracts/claimAbi.json";
import bgemAbiImx from "../contracts/bgemImxAbi.json";
import genesisTier3 from "../contracts/genesisTier3.json";
import chestOpening from "../contracts/chestOpening.json";

const isMainNetwork = true;

export const mainnetConfig = {
  Contract: {
    boom: {
      address: "0x51f005D61426e537e6afeE52F9b1aB0E63046d60",
      abi: ERC20ABI,
    },
    bgem: {
      address: "0x1386617a1bb2a6aa712ab3616bcaf1211152d1e8",
      abi: ERC20ABI,
    },
    swap: {
      address: "0xbE89b220C99E3B6154D56E02d6FE376f7bE26cd0",
      abi: SWAPABI,
    },
    maketplace: {
      address: "0x912c9C15149989DaeA984E747fb5A63d8f3fCbA9",
      abi: MarketplaceABI,
    },
    hunterLending: {
      address: "0xBc51dEaD4297515feBf210c216883cB84986D750",
      abi: HunterLendingABI,
    },
    hunter: {
      address: "0x20B807b9AF56977EF475C089A0e7977540743560",
      abi: HunterABI,
    },
    artifact: {
      address: "0xd4D53d8D61adc3B8114C1cd17B89393640db9733",
      abi: ArtifactABI,
    },
    equipment: {
      address: "0x74d4567fd8B0b873B61FA180618a82183012F369",
      abi: EquipmentABI,
    },
    shard: {
      address: "0x44b3f42e2BF34F62868Ff9e9dAb7C2F807ba97Cb",
      abi: ShardABI,
    },
    staking: {
      address: "0x23f4569002a5A07f0Ecf688142eEB6bcD883eeF8",
      abi: MarketplaceABI,
    },
    vesting: {
      address: "0xd53aD02E42E67FD7234597880cf893d6B907DceE",
      abi: VestingABI,
    },
  },
  NetId: 137,
  NetConfig: {
    chainId: `0x89`,
    chainName: "Polygon Network",
    rpcUrls: ["https://rpc.ankr.com/polygon"],
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  Wallet: {
    METAMASK: "metamask",
    SEQUENCE: "sequence",
    SEQUENCE_TESTNET: "sequence_testnet",
  },
};

const testnetConfig = {
  Contract: {
    boom: {
      address: "0x55Cd6eE720064F9c909b9B590f2c4d4aa1845416",
      abi: ERC20ABI,
    },
    bgem: {
      address: "0xF4e98ed7DdB22a2C19e26395aB7dA414654754Cb",
      abi: ERC20ABI,
    },
    maketplace: {
      address: "0xE0c553f82318b256092a2411Fc5E26CBA7C92a29",
      abi: MarketplaceABI,
    },
    swap: {
      address: "0xbE89b220C99E3B6154D56E02d6FE376f7bE26cd0",
      abi: SWAPABI,
    },
    hunterLending: {
      address: "0xe7D9721008F916114826b6c3Cf06BDc8B0E634f3",
      abi: HunterLendingABI,
    },
    hunter: {
      address: "0x7315d33F46A87b778bE375307Bf853D8951442F9",
      abi: HunterABI,
    },
    artifact: {
      address: "0x886D9Bb7b23BefEb22877222F4643E33f808Dd70",
      abi: ArtifactABI,
    },
    equipment: {
      address: "0xB1e0991743592ff8654FC00cA0374C88F189ecb4",
      abi: EquipmentABI,
    },
    shard: {
      address: "0x7E3353a9f992431059D7F340EF4c77016F9be8aB",
      abi: ShardABI,
    },
    staking: {
      address: "0x23f4569002a5A07f0Ecf688142eEB6bcD883eeF8",
      abi: MarketplaceABI,
    },
    vesting: {
      address: "0x5dE92a62E2f8fB074e20bF6F3F8C5b86C1EF6f70",
      abi: VestingABI,
    },
  },
  NetId: 80001,
  NetConfig: {
    chainId: `0x13881`,
    chainName: "Mumbai Network",
    rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mumbai/public"],
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  Wallet: {
    METAMASK: "metamask",
    SEQUENCE: "sequence",
    SEQUENCE_TESTNET: "sequence_testnet",
  },
};

const devnetConfig = {
  Contract: {
    boom: {
      address: "0xe28a5eEccEa87c34F4aE2D90197d66EE2A87f13A",
      abi: ERC20ABI,
    },
    bgem: {
      address: "0x3384f3f87fcdfd83141a86e37d070e3b17e0fd60",
      abi: ERC20ABI,
    },
    maketplace: {
      address: "0x912c9C15149989DaeA984E747fb5A63d8f3fCbA9",
      abi: MarketplaceABI,
    },
    hunterLending: {
      address: "0xCCfECE0f4E719A0FebA4b48EC1597d15f6D23EA8",
      abi: HunterLendingABI,
    },
    hunter: {
      address: "0xf4746aDf7F08B9d85749fe77A5A7740aD4dFC91A",
      abi: HunterABI,
    },
    artifact: {
      address: "0xB775Cf5230DAb7dcC392aEe56d6655a454328ee3",
      abi: ArtifactABI,
    },
    equipment: {
      address: "0xb360978Be0Da50Ce2B31c6E904251636b81F0369",
      abi: EquipmentABI,
    },
    shard: {
      address: "0x02F350947e042Af90B9FC79Dfa1E746ecB7004eb",
      abi: ShardABI,
    },
    staking: {
      address: "0x23f4569002a5A07f0Ecf688142eEB6bcD883eeF8",
      abi: MarketplaceABI,
    },
    vesting: {
      address: "0x5dE92a62E2f8fB074e20bF6F3F8C5b86C1EF6f70",
      abi: VestingABI,
    },
  },
  NetId: 80001,
  NetConfig: {
    chainId: `0x13881`,
    chainName: "Mumbai Network",
    rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mumbai/public"],
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  Wallet: {
    METAMASK: "metamask",
    SEQUENCE: "sequence",
    SEQUENCE_TESTNET: "sequence_testnet",
  },
};

const imtblConfig = {
  mainnet: {
    BASE_URL: "https://api-imx.boomland.io/api/v1",
    rpcUrl: "https://rpc.immutable.com",
    chainName: "imtbl-zkevm-mainnet",
    apiBaseUrl: "https://api.immutable.com",
    genesisTier3: "0x8fC74d44E08Ca19Ea794103527be82cCc133E72b",
    contracts: {
      mint: {
        address: "0x1284f4459987fE35bB81F8C0A5C1A8cf54095bBD",
        abi: MintAbi,
      },
      bgem: {
        address: "0xa2256284837aB824E21c424645f6BFea81B92cB1",
        abi: bgemAbiImx,
      },
      redeem: {
        address: "0x691e10FDe026A5D9421FD3Ce28795E97902a2af0",
        abi: ClaimAbi,
      },
      genesisTier3: {
        address: "0x8fC74d44E08Ca19Ea794103527be82cCc133E72b",
        abi: genesisTier3,
      },
      chestOpening: {
        address: "0x7c69149cf90c9B6AF6A5d1C5E6801D800FD63fA9",
        abi: chestOpening,
      },
    },
    clientId: "8nZ7Y7ukCOpFBE8i8JJeeOzGKRfOWcLI",
    publishableKey: "pk_imapik-lTe-@VfkQKuo830Sqzlm",
    environment: "production",
    chainName: "imtbl-zkevm-mainnet",
    passportUrl: "https://boomland.io",
  },
  testnet: {
    BASE_URL: "https://api-alphanet.boomland.io/api/v1",
    rpcUrl: "https://rpc.testnet.immutable.com",
    chainName: "imtbl-zkevm-testnet",
    apiBaseUrl: "https://api.sandbox.immutable.com",
    contracts: {
      genesisTier3: {
        address: "0xAc4ce91e4aba098208ebebA34A1fb80f7da74b11",
        abi: genesisTier3,
      },
      chestOpening: {
        address: "0x2fb6c4FFD3aAc5cd9ed7DEb5d72CfAE44b4DE664",
        abi: chestOpening,
      },
      mint: {
        address: "0xE03AC0c0B6E521AC63AA692ba1ae7cD353f133fE",
        abi: MintAbi,
      },
      bgem: {
        address: "0xa2256284837aB824E21c424645f6BFea81B92cB1",
        abi: bgemAbiImx,
      },
      redeem: {
        address: "0xFebAcE9b37e15402771f57A4946d0Ad21e2C8320",
        abi: ClaimAbi,
      },
      genesisTier3: {
        address: "0x8fC74d44E08Ca19Ea794103527be82cCc133E72b",
        abi: genesisTier3,
      },
    },
    clientId: "WLlrwl6GYbjbtiEdKw3JNXW889YhYgLM",
    publishableKey: "pk_imapik-test-fVOh8uQmgfNJSoiOpfti",
    environment: "sandbox",
    chainName: "imtbl-zkevm-testnet",
    passportUrl: "http://localhost:3000",
  },
};

export const immutableConfig =
  process.env.STAGE === "production"
    ? imtblConfig.mainnet
    : imtblConfig.mainnet;

export default isMainNetwork ? mainnetConfig : mainnetConfig;

export const BASE_URL = isMainNetwork
  ? "https://api.boomland.io/api/v1"
  : "https://api-testnet.boomland.io/api/v1";

export const rangeArray = [
  "Long",
  "Short",
  "Medium",
  "Medium",
  "Medium",
  "Long",
  "Long",
  "Short",
  "Long",
  "Short",
  "Long",
  "Long",
  "Medium",
  "Long",
  "Very Long",
  "Long",
  "Long",
  "Short",
  "Very Short",
  "Medium",
  "Long",
  "Long",
  "Long",
  "Medium",
  "Long",
  "Medium",
  "Long",
  "Long",
  "Medium",
  "Medium",
  "Medium",
  "Long",
  "Medium",
  "Short",
  "Long",
  "Long",
  "",
  "Long",
  "Short",
  "",
  "Very Long",
  "",
  "",
  "Long",
];

export const attackArray = [
  "Ricochet Arrow",
  "Slash",
  "Fireball",
  "Toxic Cone",
  "Energy Ball",
  "Dark Circle",
  "Spiders Spawn",
  "Fire Slash",
  "Roots Force",
  "Stealth Attack",
  "Command Imps",
  "Frostbolt",
  "Triple Axe",
  "Rock Throw",
  "Snipe Arrow",
  "Parley",
  "Drain Life",
  "Frenzy",
  "Rage Attack",
  "Shotgun",
  "Boom!",
  "Tornado",
  "Rolling log",
  "Royal Jewels",
  "Shuriken",
  "Gears",
  "Hammer Time",
  "Stealth Shot",
  "Haunt",
  "Hat",
  "Vampire Bite",
  "Disease",
  "Tentacles",
  "Charge",
  "Lightning",
  "Burn",
  "",
  "Ice Zone",
  "Kong Form",
  "",
  "Hook",
  "",
  "",
  "Death Master",
];
