import React, { useContext, useEffect, useState } from "react";
import config from "../config";
import { useSequence } from "../hooks/useSequence";
import Config from "../config";
import { ethers } from "ethers";

const SequenceWalletConnectContext = React.createContext(null);

export const SequenceWalletConnectProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [connectWalletError, setConnectWalletError] = useState(null);
  const [boomBalance, setBoomBalance] = useState(0);

  const {
    connect,
    isConnected,
    disconnectSequence,
    address,
    userAddresses,
    setUserAddresses,
    sequenceWallet,
  } = useSequence();

  const connectWallet = async (type, opts) => {
    try {
      if (type === config.Wallet.SEQUENCE) {
        setConnectedWallet(config.Wallet.SEQUENCE);
        await connect(false, opts);
      } else {
        setConnectedWallet(config.Wallet.SEQUENCE_TESTNET);
        await connect(true, opts);
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const getBoomBalance = async () => {
    const BoomContract = new ethers.Contract(
      Config.Contract.boom.address,
      Config.Contract.boom.abi,
      sequenceWallet.getSigner(Config.NetId)
    );
    const boomBalance = await BoomContract.balanceOf(address);

    const boom = boomBalance.toNumber() / 100;

    setBoomBalance(boom);
  };

  const disconnectWallet = async (type) => {
    disconnectSequence(type);
  };
  useEffect(() => {
    if (isConnected) {
      setWalletConnected(true);
      setConnectedWallet(config.Wallet.SEQUENCE);
      setWalletAddress(address);
      getBoomBalance();
    } else {
      setConnectedWallet(null);
      setWalletConnected(false);
      setWalletAddress("");
      disconnectSequence();
    }
  }, [isConnected]);

  return (
    <SequenceWalletConnectContext.Provider
      value={{
        setWalletAddress,
        walletAddress,
        isWalletConnected,
        connectedWallet,
        connectWallet,
        disconnectWallet,
        connectWalletError,
        setConnectWalletError,
        userAddresses,
        setUserAddresses,
        sequenceWallet,
        boomBalance,
        getBoomBalance,
      }}
    >
      {children}
    </SequenceWalletConnectContext.Provider>
  );
};

export const useSequenceWalletConnect = () =>
  useContext(SequenceWalletConnectContext);
