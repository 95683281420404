import React, { useEffect, useState } from "react";
import { Col, Row, Progress } from "reactstrap";
import { Pagination, Skeleton, Stack, useMediaQuery } from "@mui/material";
import $BOOM2 from "../../assets/images/$BOOM2.svg";
import $BOOM26 from "../../assets/images/$BOOM26.svg";
import Rectangle from "../../assets/images/Rectangle.svg";
import greenstat from "../../assets/images/greenstat.svg";
import play_to_earn4 from "../../assets/images/play-to-earn4.svg";
import info from "../../assets/images/info.svg";
import downErrow from "../../assets/images/downErrow.svg";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import Changecoinupper from "../../components/Dropdown/Changecoinupper";
import { DefiDashboard } from "../../components/Graphs/DefiDashboard";

import { ethers } from "ethers";
import config from "../../config";
import { useWeb3React } from "@web3-react/core";

const Vesting = () => {
  const [loading, setLoading] = useState(false);
  const { active, account, library } = useWeb3React();
  const [vestingData, setVestingData] = useState({});
  const [scheduleId, setScheduleId] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [releaseAmount, setReleaseAmount] = useState();
  const isMobile = useMediaQuery("(max-width:600px)");

  const getContract = () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const VestingContract = new ethers.Contract(
      config.Contract.vesting.address,
      config.Contract.vesting.abi,
      signer
    );
    return VestingContract;
  };

  const load = async () => {
    const VestingContract = getContract();
    const count = await VestingContract.getVestingSchedulesCountByBeneficiary(
      account
    );

    setTotalPage(count);
    if (count > 0) {
      const vestingSchedule =
        await VestingContract.getVestingScheduleByAddressAndIndex(account, 0);
      const _releaseAmount = await VestingContract.computeReleasableAmount(
        vestingSchedule.vestingSchedulesId
      );
      setReleaseAmount(_releaseAmount);
      setScheduleId(vestingSchedule.vestingSchedulesId);
      setVestingData(vestingSchedule);
    }
  };

  const claim = async () => {
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(library.provider);
      const signer = provider.getSigner();

      const VestingContract = new ethers.Contract(
        config.Contract.vesting.address,
        config.Contract.vesting.abi,
        signer
      );

      await VestingContract.release(scheduleId, releaseAmount.toString());
      load();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const formatDate = (val) => {
    const date = new Date(Number(val));
    const _formatDate =
      date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() !=
      0
        ? date.getHours()
        : "00" + ":" + date.getMinutes() != 0
        ? date.getMinutes()
        : "00" + ":" + date.getSeconds() != 0
        ? date.getSeconds()
        : "00";
    return _formatDate;
  };

  const formatDuration = (val) => {
    const day = Math.floor(Number(val) / 60 / 60 / 24);
    const hours = Math.floor((val - day * 3600 * 24) / 3600);
    const minutes = Math.floor((val - day * 3600 * 24 - hours * 3600) / 60);
    const seconds = val - day * 3600 * 24 - hours * 3600 - minutes * 60;

    return `${day ? day + " d " : ""} ${hours ? hours + " h " : ""} ${
      minutes ? minutes + " min" : ""
    } ${seconds ? seconds + " s" : ""}`;
  };

  useEffect(() => {
    if (active && account) {
      load();
    } else {
      setVestingData({});
    }
  }, [active, account]);

  const loadPageData = async () => {
    try {
      const VestingContract = getContract();
      const vestingSchedule =
        await VestingContract.getVestingScheduleByAddressAndIndex(
          account,
          page - 1
        );
      const _releaseAmount = await VestingContract.computeReleasableAmount(
        vestingSchedule.vestingSchedulesId
      );
      setReleaseAmount(_releaseAmount);
      setScheduleId(vestingSchedule.vestingSchedulesId);
      setVestingData(vestingSchedule);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (account && page) loadPageData();
  }, [page, account]);

  return (
    <div className="container-x">
      <Stack pt={5}>
        <div className="sortbar">
          <h3>Your Vesting</h3>
        </div>
        <span className="border-b" />
        <div className="mt-30">
          <Row className="d-flex justify-content-center align-items-center">
            <Col sm={12} md={12} lg={8} xl={8}>
              <Row className="d-flex">
                <Col sm={12} md={12} lg={6} xl={6}>
                  <div className="defi-cards-bg">
                    <div className="d-flex justify-content-between align-items-center">
                      <p>VESTING AMOUNT</p>
                      <div className="private-sale-btn">PRIVATE SALE</div>
                    </div>
                    <h5 className="mb-0">
                      {vestingData.length > 0 ? (
                        <>{vestingData.amountTotal / 100} BOOM</>
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </h5>
                    <div className="mt-4">
                      <div className="d-flex justify-content-between justify-content-center">
                        <p>VESTING DURATION</p>
                        <p>
                          {vestingData.length > 0 ? (
                            <>{formatDuration(vestingData.duration)}</>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </p>
                      </div>
                      <div className="mt-2 d-flex justify-content-between justify-content-center">
                        <p>Vesting period</p>
                        <p>
                          {vestingData.length > 0 ? (
                            <>
                              {formatDuration(vestingData.slicePeriodSeconds)}
                            </>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </p>
                      </div>
                      <div className="mt-2 d-flex justify-content-between justify-content-center">
                        <p>Start date</p>
                        <p>
                          {vestingData.length > 0 ? (
                            <>{formatDate(vestingData.start * 1000)}</>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </p>
                      </div>
                      <div className="mt-2 d-flex justify-content-between justify-content-center">
                        <p>End date</p>
                        <p>
                          {vestingData.length > 0 ? (
                            <>
                              {formatDate(
                                Number(vestingData.start) * 1000 +
                                  Number(vestingData.duration) * 1000
                              )}
                            </>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 defi-cards-bg">
                    <p>REMAINING CLIFF TIME</p>
                    <div className="my-2">
                      <Progress
                        className="vesting-progress"
                        value={
                          Number(vestingData.cliff) -
                          Math.floor(new Date().getTime() / 1000)
                        }
                        max={
                          Number(vestingData.cliff) - Number(vestingData.start)
                        }
                      />
                    </div>
                    <p>
                      {vestingData.length > 0 ? (
                        <>
                          {Number(vestingData.cliff) >
                          Math.floor(new Date().getTime() / 1000)
                            ? formatDuration(
                                Number(vestingData.cliff) -
                                  Math.floor(new Date().getTime() / 1000)
                              )
                            : "0 mins"}{" "}
                          Left
                        </>
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <div className="mt-3 mt-lg-0 defi-cards-bg">
                    <div className="mx-4">
                      <p>CLAIM TOKENS</p>
                      <h5 className="mb-0">
                        {active ? (
                          <>{releaseAmount / 100} BOOM</>
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </h5>
                    </div>
                    <div className="vesting-boom-img my-2 d-flex justify-content-center align-items-center">
                      <img src={$BOOM26} />
                    </div>

                    <div className="mt-4">
                      <div className="buy-btn-defi" onClick={() => claim()}>
                        {loading ? "Claiming..." : "Claim"}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Stack
              pt={5}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                count={totalPage}
                color="secondary"
                page={page}
                onChange={(e, val) => setPage(val)}
                sx={{ "& *": { color: "white !important" } }}
              />
            </Stack>
          </Row>
        </div>

        <div className="defi-footer">
          <div className="defi-footer-content">
            <a href="https://discord.com/invite/boomland" target="_blank">
              <img src={discord} />
            </a>
            <a href="https://t.me/BoomlandAnnouncements" target="_blank">
              <img src={teligram} />
            </a>
            <a href="https://twitter.com/BoomLandGames" target="_blank">
              <img src={twitter} />
            </a>
            <a href="https://boomlandgames.medium.com/" target="_blank">
              <img src={medium} />
            </a>
          </div>
        </div>
        <div className="defi-copright">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </Stack>
    </div>
  );
};

export default Vesting;
