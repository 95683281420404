import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Lazy} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css/bundle';

import Header from "../../components/Header";
import { useMetamaskConnect } from "../../context";
import { chestData } from "../../data";

import "../Store/store.css";
import { SocialMediaContainer } from "../../components/SocialMediaContainer";

import Tier from "../../assets/store/tier.png";
import TierSm from "../../assets/store/tier-sm.png";
import ShoppingBasket from "../../assets/store/shopping-basket.png";
import BuyBox from "../../assets/chest/buy-box.png";


import {Route, Link, Routes, useParams} from 'react-router-dom';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);


export function Chest() {
	const params = useParams();
	const currentChestId = parseInt(params.chestId);
	const currentChestData = chestData[currentChestId];

	const numberize = (num) => {
	  const parsed = Number.parseInt(num, 10);
	  if (Number.isNaN(parsed)) {
	    return 0;
	  }
	  return parsed;
	}

		const loadCart = () => {
			let grandTotal = 0;
			let i = 0;
			let quantity = 0;
			let totalQuantity = 0;
			for (i = 0; i < 3; i++) {
				quantity = numberize(window.sessionStorage.getItem("chest"+i));
				if (quantity > 0) {
					totalQuantity += quantity;
					grandTotal += quantity * chestData[i].price;
					document.getElementById('cpchest'+i).removeAttribute('hidden');
					document.getElementById('cpchest'+i+'qty').innerHTML = quantity;
					document.getElementById('cpchest'+i+'price').innerHTML = ( chestData[i].price).toFixed(4);
				} else {
					document.getElementById('cpchest'+i).setAttribute('hidden', true);
				}
			}
			document.getElementById('cartCount').innerHTML = totalQuantity+'/5';
			if (grandTotal > 0) {
				document.getElementById('cartEmpty').setAttribute('hidden', true);
				document.getElementById('cartAction').removeAttribute('hidden');
				document.getElementById('cartProducts').removeAttribute('hidden');
				document.getElementById('cartTotal').removeAttribute('hidden');
				document.getElementById('cartTotalAmount').innerHTML = grandTotal.toFixed(4);
			} else {
				document.getElementById('cartEmpty').removeAttribute('hidden');
				document.getElementById('cartAction').setAttribute('hidden', true);
				document.getElementById('cartProducts').setAttribute('hidden', true);
				document.getElementById('cartTotal').setAttribute('hidden', true);
				document.getElementById('cartTotalAmount').innerHTML = 0;
			}
			return grandTotal;
		}


	const cartCount = () => {
		let cartCount = numberize(window.sessionStorage.getItem("chest0"));
		cartCount += numberize(window.sessionStorage.getItem("chest1"));
		cartCount += numberize(window.sessionStorage.getItem("chest2"));
		return cartCount;
	}

	const addToCart = () => {
		let cartCountNum = cartCount();
		let quantityInput = numberize(document.getElementById('qty').value);
		if (cartCountNum < 6 && quantityInput < (6-cartCountNum)) {
			let newQuantity = numberize(window.sessionStorage.getItem("chest"+currentChestId));
			newQuantity += quantityInput;
			window.sessionStorage.setItem("chest"+currentChestId, newQuantity);
		}
		if (cartCountNum > 5) {
			window.sessionStorage.setItem("chest0", 0);
			window.sessionStorage.setItem("chest1", 0);
			window.sessionStorage.setItem("chest2", 0);
		}
		loadCart();
	}

	const buyNow = () => {
		addToCart();
		var $nav_header = document.getElementById('nav-header');
		$nav_header.setAttribute('data-toggle', 'false');
		document.getElementById('header').classList.remove('header-open');
		$nav_header.classList.remove('d-block');
		$nav_header.classList.add('d-none');
		document.getElementById('cartCenter').classList.add('active');
		document.getElementById('header').style.backgroundColor = "rgba(0, 0, 0, 0.7)";
	}

	const increaseItem = () => {
		let priceInput = document.getElementById('priceInput');
		let quantityInput = document.getElementById('qty');
		if (parseInt(quantityInput.value) < 5) {
			quantityInput.value = parseInt(quantityInput.value) + 1;
			priceInput.value = parseFloat(quantityInput.value * currentChestData.price).toFixed(2);
		}
	}

	const decreaseItem = () => {
		let priceInput = document.getElementById('priceInput');
		let quantityInput = document.getElementById('qty');
		if (quantityInput.value > 1) {
			quantityInput.value = parseInt(quantityInput.value) - 1;
			priceInput.value = parseFloat(quantityInput.value * currentChestData.price).toFixed(2);
		}
	}



    return (<Fragment>
            <Header />
            <div className="section section-home section-welcome2">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<img src={Tier} className="img-fluid d-none d-md-block"/>
										<img src={TierSm} className="img-fluid d-block d-md-none"/>
									</div>
								</div>
							</div>
							<div id="chest" className="container">

								<div className="row">
									<div className="col-12">
										<div className="d-flex align-items-center justify-content-between card card-black store-header">
											<h1>
												<a href="/store">
													<i className="fa fa-chevron-left me-2"></i>
												</a>
												Guild Safe
											</h1>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="card-black">
											<div className="container-fluid">
												<div className="row">
													<div className="order-1 order-md-2 col-12 col-md-8 mb-3 mb-md-0">
														<img id="chestBanner" src={currentChestData.banner} className="img-fluid" />
													</div>
													<div className="order-2 order-md-1 col-12 col-md-4">
														<h4 className="card-title mb-2">Buy chest</h4>
														<div className="p-2 form-black mb-3">
															<input type="text" id="priceInput" className="form-control fc-buybox" value={currentChestData.price} readOnly />
														</div>
														<div className="input-group d-flex justify-content-between align-items-center mb-3 form-black p-2">
															<button className="btn btn-silent-in" type="button" onClick={() => { decreaseItem() }}>
															-
															</button>
															<input type="text" id="qty" className="form-control fc-silent-in" value="1" step="1" min="1" max="5" disabled />
															<button className="btn btn-silent-in" type="button" onClick={() => { increaseItem() }}>
															+
															</button>
														</div>
														<button className="btn btn-block btn-cta mb-3" type="button" onClick={() => { buyNow() }}>
															BUY NOW
														</button>
														<button className="btn btn-block btn-silent" type="button" onClick={() => { addToCart() }}>
															ADD TO BAG
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="card card-black">
											<h2 className="card-title mb-2">What's inside the chest?</h2>
											<div className=" overflow-x-scroll">
												<img id="chestInside" src={currentChestData.inside} className="full-width h-286" />
											</div>
										</div>
									</div>
								</div>

							</div>
            </div>
            <SocialMediaContainer />
            </Fragment>
    );
}
