import {
  GET_ALL_GAMES,
  GET_ALL_GAMES_FAIL
} from "./actionTypes"

  const INIT_STATE = {
    allgames: [],
    allgameserror: {}
  }
  
  const userReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ALL_GAMES:
        return {
          ...state,
          allgames: action.payload,
        }
      case GET_ALL_GAMES_FAIL:
        return {
          ...state,
          allgameserror: action.payload
        }
      default:
        return state
    }
  }
  
  export default userReducer
  