import React, { useEffect, useState } from "react";
import search from "../../assets/images/search.svg";
import { setSearchString } from "../../store/Items/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const SearchText = () => {
  const [searchvalue, setsearchvalue] = useState();
  const dispatch = useDispatch();

  const changeSearchString = (val) => {
    setsearchvalue(val);
    dispatch(setSearchString(val));
  };

  const filterItems = useSelector((state) => state.Items);

  useEffect(() => {
    setsearchvalue(filterItems?.searchString);
  }, []);

  return (
    <div className="search-bar position-relative w-100">
      <input
        type="text"
        id="simple-search"
        className=""
        placeholder="Search"
        value={searchvalue}
        onChange={(e) => changeSearchString(e.target.value)}
        required
      />
      <img
        className="search-icon position-absolute"
        src={search}
        alt="serchicon"
      />
    </div>
  );
};

export default SearchText;
