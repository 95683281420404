/* eslint-disable no-unused-vars */
import axios from "axios";
// import { data } from "browserslist"
import { BASE_URL } from "../config";

const client = axios.create({
  baseURL: BASE_URL,
});

//axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + authHeader()
client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// Add a request interceptor
// client.interceptors.request.use(
//   config => {
//     // const token = authHeader()
//     // if (token) {
//     //   config.headers['Authorization'] = 'Bearer ' + token;
//     // }
//     config.headers['Content-Type'] = 'application/json';
//     return config;
//   },
//   error => {
//     debugger
//     Promise.reject(error)
//   });

// client.interceptors.response.use(response => {
//   debugger
//   return response;
// }, error => {
//   // debugger
//   // if (error.response.status === 401) {
//   //   console.log(error)
//   // }
//   debugger
//   return error;
// })

// export async function getd(url,data, config = {}) {

//   var customUrl = url;
//   config = {body: {account:"0x3120E153E89B7850B158d1a93d4289066a14ccd5"}}
//   console.log(url,data,config)
//   return await client
//     .get(customUrl , {...config })
//     .then(response => {
//       console.log(response)
//       return response
//     })
// }

export async function get(url, params, config = {}) {
  return await client.get(url, { params }, { ...config }).then((response) => {
    return response;
  });
}

export async function post(url, data, config = {}) {
  return await client
    .post(url, { ...data }, { ...config })
    .then((response) => response);
}

export async function chestPost(url, data, config = {}) {
  return await client
    .post(url, { ...data }, { ...config })
    .then((response) => response);
}

export async function put(url, data, config = {}) {
  debugger;
  return await client
    .put(url, { ...data }, { ...config })
    .then((response) => response);
}

export async function del(url, config = {}) {
  return await client.delete(url, { ...config }).then((response) => response);
}
