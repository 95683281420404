import React from "react";
import VideoThumbnail from "../../assets/home/hocThumbnail.jpg";
import TrailerVideoUrl from "../../assets/home/hoc.mp4";

class Gaming extends React.Component {
  render() {
    return (
      <section
        id="games"
        className="section section-home section-gaming flex flex-col justify-center"
      >
        <div className="container">
          <div className="row section-header">
            <div className="col-lg-12 col-10 offset-1 offset-lg-0 mb-5">
              <h2 className="section-title">BLOCKCHAIN GAMING</h2>
              <p className="section-paragraph">
                BoomLand has partnered with leading gaming studios to secure a
                pipeline of fun and exciting games.
              </p>
            </div>
          </div>
        </div>

        <div className="flex-row justify-center w-full self-center items-center">
          <div className="flex flex-col justify-center w-full px-1">
            <video
              width="100%"
              preload="auto"
              controls
              poster={VideoThumbnail}
              className="game-container self-center max-w-[640px]"
            >
              <source src={TrailerVideoUrl} type="video/mp4" />
            </video>
            <h3 className="gaming-subtitle mb-2 text-center my-2 text-2xlg">
              Hunters On-Chain
            </h3>
            <p className="gaming-paragraph-2 text-center text-lg  max-w-[640px] self-center">
              The hunt is on! Enter hunting grounds as one of 4 warriors and
              survive in combat against awesome and unique fantasy enemies!
            </p>
          </div>

          <div className="mt-4">
            <img
              className="gaming-img-hero"
              src="https://www.shopimia.com/assets/www/boomland.io/202207/media/gaming-hero.png"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Gaming;
