import React, { useState } from "react";
import search from "../../assets/images/search.svg";
import "rc-slider/assets/index.css";
import Level from "../../components/sidebar/Level";
import Rarity from "../../components/sidebar/Rarity";
import Stats from "../../components/sidebar/Stats";

import ProfileFilter from "../../components/sidebar/ProfileFilter";
import SearchText from "../../components/sidebar/SearchText";
import RentFilters from "../../components/sidebar/RentFilters";

const SidebarRent = (params, setparams, paramsobj) => {
  const [LevelOpen, setLevelOpen] = useState(true);
  const [RarityOpen, setRarityOpen] = useState(true);
  const [StatsOpen, setStatsOpen] = useState(true);
  const [ProfileFilterOpen, setProfileFilterOpen] = useState(true);
  const [RentFiltersOpen, setRentFiltersOpen] = useState(true);

  return (
    <div>
      <SearchText />

      <RentFilters
        RentFiltersOpen={RentFiltersOpen}
        setRentFiltersOpen={setRentFiltersOpen}
      />

      <ProfileFilter
        ProfileFilterOpen={ProfileFilterOpen}
        setProfileFilterOpen={setProfileFilterOpen}
      />

      <Level LevelOpen={LevelOpen} setLevelOpen={setLevelOpen} />

      <Rarity RarityOpen={RarityOpen} setRarityOpen={setRarityOpen} />

      <Stats StatsOpen={StatsOpen} setStatsOpen={setStatsOpen} />
    </div>
  );
};

export default SidebarRent;
