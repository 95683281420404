import React, { useEffect, useState } from "react";
import { Contract, ethers } from "ethers";
import "@rainbow-me/rainbowkit/styles.css";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
  useSwitchNetwork,
  useAccount,
} from "wagmi";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";
import { sequence } from "0xsequence";
import { Col, Row } from "reactstrap";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import BoomImg from "../../assets/img/boomKey1.png";
import $BOOM from "../../assets/images/$BOOM.svg";
const boomExchangeAddress = "0xDff4CE575c42abd5C5A051e5Dfa2811168aAEA6c";
const usdcAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
const boomExchangeABI = require("../../contracts/tokenSale.json");
const erc20ABI = require("../../contracts/erc20.json");
const projectId = "b172966957f43e118c43c1e0a82f6370";
const publicRpcUrl = "https://rpc.ankr.com/polygon";
const oneThousandUsdcBoom = 90900;
const polygonMainnetChainId = 137;
const { chains, publicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: () => ({ http: publicRpcUrl }),
    }),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: "Available",
    wallets: [
      metaMaskWallet({
        chains,
        shimDisconnect: true,
        projectId,
        defaultNetwork: "polygon",
      }),
      sequenceWallet({
        chains,
        defaultNetwork: "polygon",
        connect: {
          app: "HuntersOnChain",
        },
      }),
    ],
  },
]);
const wagmiConfig = createConfig({
  connectors,
  autoConnect: false,
  publicClient,
});
async function waitHash(txHash) {
  const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);
  let waitInterval = -1;
  await new Promise((resolve) => {
    waitInterval = setInterval(async () => {
      const receipt = await provider.getTransactionReceipt(txHash);
      if (receipt != null) {
        resolve();
      }
    }, 3000);
  });
  clearInterval(waitInterval);
}
const Sale = () => {
  const [boomAmount, setBoomAmount] = useState(0);
  const [bought, setBought] = useState(false);
  const [error, setError] = useState(false);
  const [purchasedAmount, setPurchasedAmount] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [claimTime, setClaimTime] = useState(14);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const { data: walletClient } = useWalletClient();
  const { connector, isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { switchNetworkAsync } = useSwitchNetwork();
  const getPurchasedAmount = async () => {
    if (!address) return;
    setIsLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);
    const boomExchangeContract = new Contract(
      boomExchangeAddress,
      boomExchangeABI,
      provider
    );
    const amount = await boomExchangeContract.purchasedAmount(address);
    const conversion = await boomExchangeContract.exchangeRate();
    const claimTime = await boomExchangeContract.lockupExpiration(address);
    const block = await provider.getBlock();
    const timestamp = block.timestamp;
    setPurchasedAmount(amount);
    setExchangeRate(conversion);
    setBought(amount.gt(0));
    setClaimTime(
      claimTime - timestamp < 0
        ? 0
        : ((claimTime - timestamp) / (60 * 60 * 24)).toFixed(1)
    );
    setIsLoading(false);
  };
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;
  const buyTokens = async () => {
    if (!walletClient || !exchangeRate || !boomAmount) return;
    try {
      const signer = walletClient;
      const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);
      const boomExchangeContract = new Contract(
        boomExchangeAddress,
        boomExchangeABI,
        provider
      );
      const usdcContract = new Contract(usdcAddress, erc20ABI, provider);
      const requiredUSDC = Math.ceil((boomAmount * 1e20) / exchangeRate);
      const approveData = usdcContract.interface.encodeFunctionData("approve", [
        boomExchangeAddress,
        requiredUSDC,
      ]);
      const approveTx = { to: usdcContract.address, data: approveData };
      // Buy BOOM tokens
      const buyData = boomExchangeContract.interface.encodeFunctionData(
        "buyTokens",
        [boomAmount * 100]
      );
      const buyTx = { to: boomExchangeContract.address, data: buyData };
      let txHash;
      if (connector?.id === "sequence") {
        await switchNetworkAsync(polygonMainnetChainId);
        const wallet = await sequence.getWallet();
        const sequenceSigner = wallet.getSigner(polygonMainnetChainId);
        txHash = (await sequenceSigner.sendTransaction([approveTx, buyTx]))
          .hash;
      } else {
        // Approve the smart contract to spend USDC
        const approveReceipt = await signer.sendTransaction(approveTx);
        await waitHash(approveReceipt);
        txHash = await signer.sendTransaction(buyTx);
      }
      await waitHash(txHash);
      setBought(true);
      await getPurchasedAmount();
    } catch (error) {
      console.error(error);
      setError(true);
      setBought(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (walletClient) {
      getPurchasedAmount();
    }
  }, [walletClient]);
  const claim = async () => {
    try {
      setIsClaiming(true);
      const signer = walletClient;
      if (connector?.id === "sequence")
        await switchNetworkAsync(polygonMainnetChainId);

      const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);
      const boomExchangeContract = new Contract(
        boomExchangeAddress,
        boomExchangeABI,
        provider
      );
      // Claim BOOM tokens
      const claimData = boomExchangeContract.interface.encodeFunctionData(
        "claimTokens",
        []
      );
      const claimTx = { to: boomExchangeContract.address, data: claimData };
      const txHash = await signer.sendTransaction(claimTx);
      await waitHash(txHash);
      await getPurchasedAmount();
      setIsClaiming(false);
    } catch (e) {
      console.error(e);
      setIsClaiming(false);
    }
  };
  return (
    <div className="container-x token-sale-container">
      <div className="sortbar">
        <h3> BUY BOOM - PUBLIC SALE</h3>
      </div>
      <span className="border-b" />
      {/* Reward Information Section */}
      <div className="mt-30 d-grid col-12 ">
        <Row className="mb-0 justify-content-between gap-5 container-x">
          <Col className="daily-card-bg">
            <div className="p-3">
              <h3 className="font-helper">$BOOM PUBLIC SALE</h3>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={$BOOM}
                  alt="Boom Img"
                  className="image-helper mt-40"
                />
              </div>
              <div className="d-flex justify-content-end flex-column align-items-end">
                <p className="font-helper1"> $0.012</p>
                <p className="font-helper2"> BOOM PRICE</p>
              </div>
            </div>
          </Col>
          {/* Claim Reward Section */}
          <Col className="daily-card-bg">
            <div className="p-3 col">
              {!isConnected ? (
                <>
                  {/* Connect Wallet */}
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <p className="font-helper3 box-helper my-special">
                      Connect your wallet
                    </p>
                    <button
                      id="connect-wallet-button-qa"
                      className={
                        "w-100 btn-secondary-colapse btn-connect-wallet"
                      }
                      type="button"
                      onClick={() => openConnectModal(true)}
                    >
                      Connect Wallet
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {isLoading && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <ConnectButton className="font-helper" />
                        <ClipLoader
                          className="claim-reward-header"
                          css={spinnerCss}
                          size={20}
                          color={"#fff"}
                        />
                      </div>
                    </>
                  )}
                  {!isLoading && error && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <ConnectButton className="font-helper" />
                        <div className="font-helper claim-reward-header">
                          <p>Error</p>
                        </div>
                        <p className="buy-token-error">
                          An error ocurred, please check console for details.
                        </p>
                        <button
                          id="btn-buy-boom-qa"
                          className={
                            "w-100 btn-secondary-colapse btn-connect-wallet"
                          }
                          type="button"
                          onClick={() => {
                            setBought(false);
                            setError(false);
                          }}
                        >
                          TRY AGAIN
                        </button>
                      </div>
                    </>
                  )}
                  {!isLoading && bought && !error && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <ConnectButton className="font-helper" />
                        <div className="font-helper claim-reward-header">
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Congratulations!
                          </p>
                          <p className="font-helper3">
                            Come back in {claimTime} days to claim your $BOOM
                          </p>
                        </div>
                        <p>
                          You have{" "}
                          {(
                            (Number(purchasedAmount) * exchangeRate) /
                            1e20
                          )?.toFixed(0) || 0}{" "}
                          $BOOM to claim
                        </p>
                        <button
                          id="btn-claim-boom-qa"
                          className={`w-100 ${
                            claimTime > 0
                              ? "btn-secondary-disabled"
                              : "btn-secondary-colapse"
                          }`}
                          type="button"
                          onClick={claim}
                          disabled={claimTime > 0}
                        >
                          {isClaiming ? (
                            <ClipLoader
                              css={spinnerCss}
                              size={20}
                              color={"#fff"}
                            />
                          ) : (
                            "CLAIM"
                          )}
                        </button>
                        <button
                          id="btn-buy-boom-qa"
                          className={"w-100 btn-transparent"}
                          type="button"
                          onClick={() => {
                            setBought(false);
                          }}
                        >
                          BUY MORE
                        </button>
                      </div>
                    </>
                  )}
                  {!bought && !error && !isLoading && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <ConnectButton className="font-helper" />
                      <div className="font-helper3 claim-reward-header">
                        <p>Enter the Boom amount you want to purchase</p>
                      </div>
                      <TextField
                        type="number"
                        error={Number(boomAmount) > 0 ? false : true}
                        helperText={
                          Number(boomAmount) > 0 ? "" : "Amount can't be 0."
                        }
                        className="custom-input buy-boom-input "
                        placeholder="Eg: 120"
                        value={boomAmount}
                        onChange={(e) =>
                          setBoomAmount(
                            Number(e.target.value)?.toFixed(0) >
                              oneThousandUsdcBoom
                              ? oneThousandUsdcBoom
                              : Number(e.target.value)?.toFixed(0)
                          )
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <Box component="img" src={BoomImg}></Box>&nbsp;SMX
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            fontSize: 40,
                            color: "white",
                            textAlign: "center",
                          },
                        }}
                      />
                      <button
                        id="btn-buy-boom-qa"
                        className={
                          "w-100 btn-secondary-colapse btn-connect-wallet"
                        }
                        type="button"
                        onClick={() => {
                          setIsSubmitting(true);
                          buyTokens().finally(() => setIsSubmitting(false));
                        }}
                      >
                        {isSubmitting ? (
                          <ClipLoader
                            css={spinnerCss}
                            size={20}
                            color={"#fff"}
                          />
                        ) : (
                          "Buy"
                        )}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
const TokenSale = () => (
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} theme={darkTheme()}>
      <Sale />
    </RainbowKitProvider>
  </WagmiConfig>
);
export default TokenSale;
