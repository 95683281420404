import React from 'react'
import { Card, CardBody, Collapse, Input, Label } from 'reactstrap'
import line from '../../assets/images/line.svg'
import Slider from '@mui/material/Slider';

import $BOOM from '../../assets/images/$BOOM.svg'
import plus from '../../assets/images/plus.svg'

const BoomPower = (props) => {

  const toggleBoomPower = () => props.setBoomPowerOpen(!props.BoomPowerOpen);

    function valuetext(value) {
        return `${value}`;
      }

  

    return (
      <div>
      {/*
      <div onClick={toggleBoomPower} className={`${props.BoomPowerOpen ? 'border-radiusA' : 'border-radiusB'}  btn-primary-colapse d-flex justify-content-between`}>
            <div className='colapse-heading'>
            BoomPower
           </div>
           {props.BoomPowerOpen ? <img src={line} /> : <img src={plus} /> } 
      </div>
      {props.BoomPowerOpen &&
       <Collapse isOpen={props.BoomPowerOpen}>
           <Card className='card-collapse'>
               <CardBody className=' cardbody-content'>
                   <div className='mb-3 row-1 d-flex align-items-center'>
                   <Slider
                       getAriaLabel={() => 'Temperature range'}
                      //  aria-label="Custom marks"
                       value={props.boomPower}
                       onChange={(e,newValue) => props.setboomPower(newValue)}
                       valueLabelDisplay="auto"
                       getAriaValueText={valuetext}
                       step={10}
                       marks
                       min={10}
                       max={100}
                     />
                   </div>
               </CardBody>
           </Card>
       </Collapse>
      }
      */}
   </div>
    )
}

export default BoomPower
