import React from "react";
import ReactDOM from "react-dom/client";
import "./components/home/home.css";
import "./main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import Modal from "react-modal";
import store from "./store/index";
import { Provider } from "react-redux";
import Web3Provider from "./providers/web3";
window.Buffer = window.Buffer || require("buffer").Buffer;

Modal.setAppElement("#modal");

Modal.defaultStyles = {
  overlay: {
    ...Modal.defaultStyles.overlay,
    background: `rgba(0, 0, 0, 0.4)`,
    display: "grid",
    placeContent: "center",
  },
  content: {
    ...Modal.defaultStyles.content,
    border: "none",
    position: "initial",
    width: 300,
    background: "#2F1C38",
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3Provider>
        <App />
      </Web3Provider>
    </Provider>
  </React.StrictMode>
);
