import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";
import $BOOM3 from "../../assets/images/$BOOM3.svg";
import downErrow from "../../assets/images/downErrow.svg";

import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
  useAccount,
} from "wagmi";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { coinbaseWallet, metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { BigNumber, Contract, constants, ethers } from "ethers";
import $BGEM from "../../assets/images/daily/Diamond.svg";
import { mainnetConfig } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const publicRpcUrl = "https://rpc.ankr.com/polygon";
const projectId = "b172966957f43e118c43c1e0a82f6370";
const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);

const bgemContract = new Contract(
  mainnetConfig.Contract.bgem.address,
  mainnetConfig.Contract.bgem.abi,
  provider
);

const boomContract = new Contract(
  mainnetConfig.Contract.boom.address,
  mainnetConfig.Contract.boom.abi,
  provider
);

const swapContract = new Contract(
  mainnetConfig.Contract.swap.address,
  mainnetConfig.Contract.swap.abi,
  provider
);

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const { chains, publicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: () => ({ http: publicRpcUrl }),
    }),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: "Available",
    wallets: [
      metaMaskWallet({
        chains,
        shimDisconnect: true,
        projectId,
        defaultNetwork: "polygon",
      }),
      coinbaseWallet({
        chains,
        defaultNetwork: "polygon",
        connect: {
          app: "HuntersOnChain",
        },
      }),
    ],
  },
]);
const wagmiConfig = createConfig({
  connectors,
  publicClient,
});

async function waitHash(txHash) {
  let waitInterval = -1;
  await new Promise((resolve) => {
    waitInterval = setInterval(async () => {
      const receipt = await provider.getTransactionReceipt(txHash);
      if (receipt != null) {
        resolve();
      }
    }, 3000);
  });
  clearInterval(waitInterval);
}

const BGEM_DIV = "1000000000000000000";
const BOOM_DIV = "100";

const SwapPage = () => {
  const { data: walletClient } = useWalletClient();
  const { isConnected, address } = useAccount();
  const [bgemAmount, setBgemAmount] = useState(0);
  const [bought, setBought] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rate, setRate] = useState(2000);
  const [error, setError] = useState(false);
  const [bgemBalance, setBgemBalance] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [boomAvailable, setBoomAvailable] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);

  const updateBgemBalance = async () => {
    const balance = await bgemContract.balanceOf(address);
    setBgemBalance(balance.div(BGEM_DIV).toNumber());
  };

  const getSwapData = async () => {
    setError("");
    const swap = await swapContract.swaps(0);
    const rate = swap.rate.div(BGEM_DIV).mul(BOOM_DIV);
    const boomAmount = await boomContract.balanceOf(swapContract.address);

    const cooldown = await swapContract.cooldown(address);
    const cooldownInMillis = cooldown * 1000;

    const nowInMillis = new Date().getTime();

    setHoursLeft(
      cooldownInMillis - nowInMillis < 0
        ? 0
        : (cooldownInMillis - nowInMillis) / 3600000
    );

    if (cooldownInMillis - nowInMillis > 0)
      setError(
        `You need to wait ${(
          (cooldownInMillis - nowInMillis) /
          3600000
        ).toFixed(0)} hours to swap again.`
      );
    setBoomAvailable(Number(boomAmount.div(100)));
    setRate(rate.toString());
    setLimit(swap.limit.mul(rate).div(100));
  };

  const setMax = () => {
    const bgemLimit = bgemBalance > limit ? limit : bgemBalance;
    setBgemAmount(bgemLimit);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const swap = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setBought(false);
      const bgemRaw = BigNumber.from(bgemAmount).mul(BGEM_DIV);
      const allowance = await bgemContract.allowance(
        walletClient.account.address,
        mainnetConfig.Contract.swap.address
      );

      if (allowance.lt(bgemRaw)) {
        const approveTx = {
          to: bgemContract.address,
          data: bgemContract.interface.encodeFunctionData("approve", [
            mainnetConfig.Contract.swap.address,
            constants.MaxUint256,
          ]),
        };

        const txHash = await walletClient.sendTransaction(approveTx);
        await waitHash(txHash);
      }

      const tx = {
        to: swapContract.address,
        data: swapContract.interface.encodeFunctionData("swap", [0, bgemRaw]),
      };

      const rx = await walletClient.sendTransaction(tx);
      await waitHash(rx);
      setIsLoading(false);
      setBought(true);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setError("An error happened processing your request");
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      updateBgemBalance();
    }
    getSwapData();
  }, [isConnected, address]);

  return (
    <div className="container-x h-full">
      <div className="sortbar">
        <h3>Swap</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 sm:w-[480px] sm:h-[640px] self-center">
          <ConnectButton className="font-helper" />

          <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] overflow-clip">
            <div className="defi-card-header flex justify-content-between align-items-center flex-col sm:flex-row">
              <p>SWAP</p>
              <span>You have {numberWithCommas(bgemBalance)} BGEM</span>
            </div>

            <div className="position-relative swapdefi-coin">
              <div className="uper-coin d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <input
                    className="uper-coin-input"
                    value={bgemAmount}
                    step={1}
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) setBgemAmount(e.target.value);
                    }}
                  />
                  <button onClick={setMax} className="coinmax">
                    Max
                  </button>
                </div>
                <div className="d-flex align-items-center">
                  <img className="boom-img-coin h-5" src={$BGEM} />
                  <p>BGEM</p>
                </div>
              </div>
              <div className="swaper-coin">
                <img src={downErrow} />
              </div>
            </div>
            <div className="swapdefi-coin">
              <div className="uper-coin d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <input
                    className="uper-coin-input"
                    value={(bgemAmount / +rate).toFixed(2)}
                    disabled
                  />
                </div>
                <div className="d-flex align-items-center">
                  <img className="boom-img-coin" src={$BOOM3} />
                  <p>BOOM</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <span>Total BOOM available to swap: {boomAvailable}</span>
              {error ? <span className="text-red-500">{error}</span> : null}

              {bought ? (
                <span className="text-green-500">
                  Congratulations! You succesfuly swapped BOOM
                </span>
              ) : null}
            </div>
            <button
              className="mt-4 w-full"
              onClick={swap}
              disabled={hoursLeft > 0}
            >
              <div className="buy-btn-defi">
                {isLoading ? (
                  <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                ) : (
                  "SWAP"
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Swap = () => (
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} theme={darkTheme()}>
      <SwapPage />
    </RainbowKitProvider>
  </WagmiConfig>
);
export default Swap;
