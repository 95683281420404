import React, { useContext, useState } from "react";

const MetamaskConnectContext = React.createContext(null);


export const MetamaskConnectProvider = ({ children }) => {
    const [account, setAccount] = useState(null);

    return <MetamaskConnectContext.Provider value={{
        account, setAccount
    }}>
        {children}
    </MetamaskConnectContext.Provider>
}

export const useMetamaskConnect = () => useContext(MetamaskConnectContext);