import React from "react";

import { CardBody, Card } from "reactstrap";

import energy from "../../assets/images/energy.png";

import Damage from "../../assets/images/Damage.png";
import HP from "../../assets/images/HP.png";
import Range from "../../assets/images/Range.png";
import Attack from "../../assets/images/Attack.png";
import Speed from "../../assets/images/Speed.png";
import Mvt from "../../assets/images/Mvt.png";

import { attackArray, rangeArray } from "../../config";

import $BOOM from "../../assets/images/$BOOM.svg";
import PadlockIcon from "../../assets/icons/padlock.svg";
import { getUpgradedProperty } from "../../helpers/functions.helper";

const Cardpage = ({ m }) => {
  const imgg = (perk) => {
    if (perk) {
      return require(`../../assets/asset-images/Perks_Numbered/a${perk}.png`);
    }
  };

  const imgid = m?.details?.generatedName.slice(0, 3);

  return (
    <Card className="productcard" style={{ color: "white" }}>
      <div
        className={`cardbg-video
        ${m?.details?.rarity === 0 && "bg-common"}
        ${m?.details?.rarity === 1 && "bg-uncommon"}
        ${m?.details?.rarity === 2 && "bg-rare"}
        ${m?.details?.rarity === 3 && "bg-epic"}
        ${m?.details?.rarity === 4 && "bg-legendary"}
        relative d-flex align-items-center justify-content-center`}
      >
        <img
          className={`name-logo ${
            m?.details?.lendingStatus === "lent" &&
            m?.details?.isRentedOut &&
            "opacity-30"
          }`}
          src={require("../../assets/images/Name-logo.png")}
          alt="Name_logo"
        />
        {m?.details?.lendingStatus === "lent" && m?.details?.isRentedOut && (
          <img
            className="locked-icon"
            src={PadlockIcon}
            alt="Locked Icon"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
        )}
        <img
          className={`herovideo ${
            m?.details?.lendingStatus === "lent" &&
            m?.details?.isRentedOut &&
            "opacity-20"
          }`}
          src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${m?.details?.generatedName}.gif`}
          style={{ width: "100%" }}
        />

        <div
          className={`energy-bage ${
            m?.details?.lendingStatus === "lent" &&
            m?.details?.isRentedOut &&
            "opacity-30"
          }`}
        >
          <img src={energy} alt="energy" />
          <span style={{ color: "white" }}>
            {m?.details?.remainingDailyHunterLimit}/
            {m?.details?.maxDailyHunterLimit}
          </span>
        </div>
        {m?.details?.lendingStatus === "lent" ? (
          m?.details?.isRentedOut ? (
            <div className="lent-bage opacity-100">
              <div className="d-flex flex-column">
                <div className="flex flex-col justify-start bg-[#413049] rounded-md">
                  <span className="text-white rented">RENTED</span>
                  <span className="text-red-500 bg-[#413049] rented-out">
                    OUT
                  </span>
                </div>

                <span className="text-white days-left">
                  {m?.details?.lendingDaysLeft} days left
                </span>
              </div>
            </div>
          ) : (
            <div className="lent-bage">
              <div className="d-flex flex-column">
                <div className="flex flex-col justify-start bg-[#413049] rounded-md">
                  <span className="text-green-500 rented">RENTED</span>
                </div>

                <span className="text-white days-left">
                  {m?.details?.lendingDaysLeft} days left
                </span>
              </div>
            </div>
          )
        ) : (
          m?.details?.lendingStatus === "listed" && (
            <div className="listed-bage">
              <span className="text-white">Listed</span>
            </div>
          )
        )}

        <div
          className={`powerbar ${
            m?.details?.lendingStatus === "lent" &&
            m?.details?.isRentedOut &&
            "opacity-30"
          }`}
        >
          <div className="mb-2">
            {m?.details?.permanentPerk1 ? (
              <img src={imgg(m?.details?.permanentPerk1)} alt="powerbadge" />
            ) : (
              ""
            )}
          </div>
          <div className="mb-2">
            {m?.details?.permanentPerk2 ? (
              <img src={imgg(m?.details?.permanentPerk2)} alt="powerbadge" />
            ) : (
              ""
            )}
          </div>
          <div className="mb-2">
            {m?.details?.permanentPerk3 ? (
              <img src={imgg(m?.details?.permanentPerk3)} alt="powerbadge" />
            ) : (
              ""
            )}
          </div>
          <div className="mb-2">
            {m?.details?.permanentPerk4 ? (
              <img src={imgg(m?.details?.permanentPerk4)} alt="powerbadge" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <CardBody>
        <div className="name-card d-flex align-items-center justify-content-between">
          <div>
            <p className={`mb-0 name `}>{m?.details?.name}</p>

            <p
              className={`mb-0 rarity
                ${m?.details?.rarity === 0 && "text-common"}
                ${m?.details?.rarity === 1 && "text-uncommon"}
                ${m?.details?.rarity === 2 && "text-rare"}
                ${m?.details?.rarity === 3 && "text-epic"}
                ${m?.details?.rarity === 4 && "text-legendary"}
              `}
            >
              {m?.details?.rarity === 0 && "COMMON"}
              {m?.details?.rarity === 1 && "UNCOMMON"}
              {m?.details?.rarity === 2 && "RARE"}
              {m?.details?.rarity === 3 && "EPIC"}
              {m?.details?.rarity === 4 && "LEGENDARY"}
            </p>
          </div>
          <div>
            <p className="mb-0 level">#{m?.details?.nftId}</p>
            <p className="mb-0 level">{`LEVEL ${m?.details?.level + 1}`}</p>
          </div>
        </div>
      </CardBody>
      <CardBody className="stats-card py-0">
        <div className="row name-card">
          <div className="col stat-direction" style={{ paddingLeft: 0 }}>
            <div className="col   single-stat d-flex align-items-center">
              <img src={Damage} alt="Damage" />
              <div className="stats-w-min statsbadge">
                {getUpgradedProperty(m?.details?.damage, m?.details?.level)}
              </div>
            </div>
            <div className="col   single-stat  d-flex align-items-center">
              <img src={HP} alt="HP" />
              <div className="stats-w-min statsbadge">
                {getUpgradedProperty(m?.details?.hp, m?.details?.level)}
              </div>
            </div>
          </div>
          <div className="col stat-direction" style={{ paddingLeft: 0 }}>
            <div className="col middle-state single-stat d-flex align-items-center">
              <img src={Range} alt="Range" />
              <div
                className="statsbadge"
                style={{
                  fontSize: 12,
                }}
              >
                {rangeArray[m?.details?.type]}
              </div>
            </div>
            <div className="col middle-state single-stat  d-flex align-items-center">
              <img
                src={Attack}
                alt="Attack"
                style={{ alignSelf: "flex-start" }}
              />
              <div
                className="statsbadge"
                style={{
                  fontSize: 12,
                }}
              >
                {attackArray[m?.details?.type]}
              </div>
            </div>
          </div>
          <div
            className="col stat-direction"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <div className="col   single-stat d-flex align-items-center">
              <img src={Speed} alt="Speed" />
              <div className="stats-w-min statsbadge">
                {m?.details?.attackSpeed}
              </div>
            </div>
            <div className="col   single-stat  d-flex align-items-center">
              <img src={Mvt} alt="Mvt" />
              <div className="stats-w-min statsbadge">{m?.details?.speed}</div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardBody className="py-0">
        <div className="price-card">
          {["lent", "listed"].includes(m?.details?.lendingStatus) ? (
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="bid mb-0 status">Revenue Share</p>
                <p className="text-xs my-1">
                  (sent to owner)
                </p>
                <p className="mb-0 rent-subtitle-2">
                  {m?.details?.lendingRevenueShare} %
                </p>
              </div>
              <div>
                <p className="bid mb-0 status">Cost per Day</p>

                <div className="d-flex align-items-center justify-content-end">
                  <img
                    src={$BOOM}
                    alt="Boom"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <span className="bid-price-2">
                    {m?.details?.lendingPricePerDay}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-custom"></div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Cardpage;
