// SummonImages.js

const summonHuntersImages = {};

// 36, 39, 41, 42 are missing images in the game files so we skip them here as well to avoid errors
const jump = [36, 39, 41, 42];

for (let i = 0; i <= 43; i++) {
  if (jump.includes(i)) {
    continue;
  }

  const key = i.toString().padStart(3, "0");
  summonHuntersImages[i] = require(`../../assets/summon/hunters/${key}.png`);
}

const summonEquipmentsImages = {};

for (let i = 0; i < 50; i++) {
  summonEquipmentsImages[i] = require(`../../assets/asset-images/Equipments/${
    i + 1
  }.png`);
}

export { summonHuntersImages, summonEquipmentsImages };
