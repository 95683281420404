import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import Sidebar from '../../context/Layout/Sidebar'
import BoomlandGame from '../../assets/images/BoomlandGame.png'
import game1 from '../../assets/images/game1.svg'
import game2 from '../../assets/images/comingsoon2.png'
import game3 from '../../assets/images/game3.svg'
import apple_store from '../../assets/images/ic_apple_store.png'
import play_store from '../../assets/images/ic_play_store.png'

import { Col, ModalTitle, Row } from 'react-bootstrap';
import Header from '../../components/Header';

const Game = () => {

    return (
        <>
        <div className='container-x'>
            <div className='mb-5'>
            <div className='hero-banner'>
               <img src={BoomlandGame} alt='HunterOnChain-Banner1' />
            </div>
            <div className='sortbar'>
                <h3>New Games</h3>
            </div>
            <span  className='border-b'/>
            <div className='mt-30'>
                <Row className='game-card-bg m-0'>
                   <Col sm={12} md={12} lg={7} xl={7} className='px-0'>
                      <img className='gamelefsideimg' src={game1} alt='game1' />
                   </Col>
                   <Col sm={12} md={12} lg={5} xl={5} className='p-0'>
                      <div className='game-card-detail'>
                          <h5>Hunters-On Chain</h5>
                          <div className='game-desc d-flex align-items-center justify-content-between'>
                                <div className='game-detail'>
                                    <span>Genre</span>
                                    <p>PvP Brawler</p>
                                </div>
                                <div className='game-detail'>
                                    <span>Developer</span>
                                    <p>Boombit</p>
                                </div>
                          </div>
                          <div className='game-desc d-flex align-items-center justify-content-between'>
                                <div className='game-detail'>
                                    <span>Development Status</span>
                                    <p>Beta</p>
                                </div>
                                <div className='game-detail'>
                                    <span>Platform</span>
                                    <p>Mobile</p>
                                </div>
                          </div>
                          <div className='gamebtn-content flex-wrap d-flex align-items-center justify-content-between'>
                                <div className='m-2 game-play-btn'>
                                    Play On Mobile
                               </div>
                               <div className='m-2 game-play-btn'>
                                    Play On Web
                               </div>
                          </div>
                      </div>
                   </Col>
                </Row>
                
            </div>
            <div className='sortbar mt-30'>
                <h3>Up Coming Games</h3>
            </div>
            <span  className='border-b'/>
            <div className='mt-30'>
                <Row className='game-card-bg m-0'>
                   <Col sm={12} md={12} lg={7} xl={7} className='px-0'>
                      <img className='gamelefsideimg' src={game2} alt='game1' />
                   </Col>
                   <Col sm={12} md={12} lg={5} xl={5} className='p-0'>
                      <div className='game-card-detail'>
                          <h5>Coming Soon</h5>
                          <div className='game-desc d-flex align-items-center justify-content-between'>
                                <div className='game-detail'>
                                    <span>Genre</span>
                                    <p>TBA</p>
                                </div>
                                <div className='game-detail'>
                                    <span>Developer</span>
                                    <p>TBA</p>
                                </div>
                          </div>
                          <div className='game-desc d-flex align-items-center justify-content-between'>
                                <div className='game-detail'>
                                    <span>Development Status</span>
                                    <p>In Progress</p>
                                </div>
                                <div className='game-detail'>
                                    <span>Platform</span>
                                    <p>TBA</p>
                                </div>
                          </div>
                      </div>
                   </Col>
                </Row>
                
            </div>            
            </div>
        </div>
        
        </>
    )
}

export default Game
