/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { Fragment, useEffect, useState, useRef } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "./Storedetail.css";
import {
  Button,
  Progress,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
  Col,
  Row,
} from "reactstrap";
import { Box } from "@mui/material";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";
import Header from "../../components/Header";

import { chestData } from "../../data";

import { SocialMediaContainer } from "../../components/SocialMediaContainer";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import Tier from "../../assets/store/tier.png";
import TierSm from "../../assets/store/tier-sm.png";
import ShoppingBasket from "../../assets/store/shopping-basket.png";
import BuyBox from "../../assets/chest/buy-box.png";
import storeleft from "../../assets/store/storeleft.png";
import storeright from "../../assets/store/storeright.png";
import storedetailimg from "../../assets/store/storedetailimg.png";
import Arrowleft from "../../assets/store/Arrowleft.svg";

import { Route, Link, Routes, useParams } from "react-router-dom";
import Storecard from "../../components/cards/Storecard";
import altar from "../../assets/store/altarchest.jpg";
import { getChestHunter } from "../../store/Store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Address from "../../hooks/Getaddress";
import greenButton from "../../assets/store/greenButton.png";

import backgroundchest from "../../assets/store/background-chest.png";
import buybutton from "../../assets/store/buynow.png";
import tier1 from "../../assets/store/tier1.png";
import tier2 from "../../assets/store/tier2.png";
import config from "../../config";

import { ethers } from "ethers";
import { openChestAndGetHunter } from "../../chain/chest";
import {
  IncrementPendingHunters,
  SetPendingHunters,
} from "../../store/Items/actions";
import { LOCAL_COUNT_KEY } from "../../chain/chainConfig";
import { useSequenceWalletConnect } from "../../context";
import getWallet from "./../../chain/sequenceWallet";
import counter from "../../assets/store/chestCounter.png";
import { decreaseChestCount, getChestCount } from "../../store/Store/actions";
import Config from "../../config";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);

const Storedetail = ({ chestCount, onChestTypeChanged }) => {
  const defaultVideoUrl = require(`../../assets/videos/T1-Chest-1.mp4`);

  // const fakeChestItem = {
  //   amount: 4,
  //   rarity: 2,
  //   tokenId: 5,
  //   type: 1,
  //   imgid: "005",
  //   generatedName: "005204000102",
  //   level: 1,
  //   name: "Voodoo",
  // };

  const [openState, setOpenState] = useState(0);
  const [chestType, setChestType] = useState(1); // Tier = 1 or 2
  const [hunterData, setHunterData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoShow, setVideoShow] = useState(true);
  const [altarButtonShow, setAltarButtonShow] = useState(true);
  const [videoUrl, setVideoUrl] = useState(defaultVideoUrl);
  const { isWalletConnected, walletAddress } = useSequenceWalletConnect();
  const videoRef = useRef(null);
  const dispatch = useDispatch();

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const getChestThumb = (rarity, chestType) => {
    if (![1, 2, 3, 4].includes(rarity)) {
      throw new Error(`Invalid rarity: ${rarity}`);
    }
    if (![1, 2].includes(chestType)) {
      throw new Error(`Invalid chest type: ${chestType}`);
    }
    return require(`../../assets/videos/T${chestType}-Chest-${rarity}.png`);
  };

  const getChestVideo = (rarity, chestType) => {
    if (![1, 2, 3, 4].includes(rarity)) {
      throw new Error(`Invalid rarity: ${rarity}`);
    }
    if (![1, 2].includes(chestType)) {
      throw new Error(`Invalid chest type: ${chestType}`);
    }
    return require(`../../assets/videos/T${chestType}-Chest-${rarity}.mp4`);
  };

  const getButtonLabel = (state) => {
    if (state == 0) return "OPEN";
    if (state == 1) return "PREPARING";
    if (state == 2) return "APPROVING";
    if (state == 3) return "OPENING...";
    if (state == 4) return "PROCESSING";
    if (state == 5) return "TRY AGAIN";
  };

  const loadAndPlayVideo = async () => {
    await videoRef.current?.load();
    togglePlay();
  };

  useEffect(() => {
    if (!hunterData) return;
    console.log({ hunterData });
    setAltarButtonShow(false);
    setVideoUrl(getChestVideo(hunterData?.rarity ?? 1, chestType));
    loadAndPlayVideo();
  }, [hunterData]);
  // useEffect(() => {
  //   // console.log(hunterData);
  //   if (hunterData == null && openState !== 4) return;
  //   setVideoUrl(getChestVideo(hunterData?.rarity ?? 1, chestType));
  //   videoRef.current?.load();
  //   togglePlay();
  // }, [hunterData, togglePlay, openState]);

  useEffect(() => {
    console.log("Store Detail chestCount useEffect called");
  }, [chestCount]);

  useEffect(() => {
    console.log(
      "Store Detail chestType useEffect called, calling to onChestTypeChanged"
    );
    onChestTypeChanged(chestType);
  }, [chestType]);

  const altarHandler = async () => {
    //for testing and demo remove it if done
    // setHunterData(fakeChestItem);
    // setAltarButtonShow(!altarButtonShow);
    // return;
    //for testing and demo remove it if done

    if (openState !== 0 && openState !== 5) return;

    if (!isWalletConnected) return console.log("No wallet connected");

    const wallet = getWallet();
    const signer = wallet.getSigner(config.NetId);

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // if (provider === undefined) return console.log("No provider");

    // const signer = provider.getSigner(Config.NetId);
    if (signer === undefined) return console.log("No signer");

    try {
      const openChestResult = await openChestAndGetHunter(
        chestType,
        signer,
        setOpenState,
        () => {
          const prevCount = Number(
            localStorage.getItem(LOCAL_COUNT_KEY) ?? "0"
          );
          localStorage.setItem(LOCAL_COUNT_KEY, String(prevCount + 1));
          dispatch(IncrementPendingHunters());
        }
      );

      if (openChestResult.success) {
        console.log("HunterData", openChestResult.data);
        setHunterData(openChestResult.data);
      } else {
        console.error(openChestResult.message);
        setOpenState(5);
      }
    } catch (e) {
      console.error(e);
      setOpenState(5);
    }
  };

  const reset = async () => {
    window.location.reload();
    dispatch(decreaseChestCount(chestType));
    setHunterData(null);
    setVideoUrl(defaultVideoUrl);
    videoRef.current?.load();
    setAltarButtonShow(true);
    setVideoShow(true);
    setOpenState(0);
  };

  return (
    <Fragment>
      <div className="video-store-container">
        {altarButtonShow && (
          <div
            className={`btn-chest-rectangle mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show`}
          >
            <ChestCount chestCount={chestCount} chestType={chestType} />
          </div>
        )}
        <div
          style={{
            width: "100%",
            maxWidth: "1300px",
          }}
        >
          <video
            onLoad={() => {
              "Video loaded";
            }}
            ref={videoRef}
            poster={require(`../../assets/store/${
              chestType === 1 ? "background-tier1.png" : "background-tier2.png"
            }`)}
            className={`video-store ${
              videoShow ? "video-store-show" : "video-store-hide"
            }`}
            // Stop video when it ends
            onEnded={() => {
              setVideoShow(false);
              togglePlay();
            }}
            muted
          >
            <source src={videoUrl} />
          </video>

          {openState !== 4 && (
            <Box
              sx={{
                width: "30%",
                height: "72%",
                position: "absolute",
                top: "calc(50% - 33%)",
                left: "calc(50% - 16%)",
                "@media screen and (max-width: 650px)": {
                  width: "40%",
                  height: "66%",
                  top: "calc(50% - 43%)",
                  left: "calc(50% - 22%)",
                },
                "@media screen and (min-width: 550px)": {
                  top: "calc(50% - 36%) !important",
                },
              }}
            >
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                onSlideChange={(swiper) => {
                  console.log(
                    "Store Detail => Swiper onSlideChange called, chestType set to",
                    swiper.activeIndex + 1
                  );
                  setChestType(swiper.activeIndex + 1);
                }}
              >
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={tier1}
                    alt="Tier 1"
                    style={{
                      maxWidth: "79%",
                    }}
                    className="tier1"
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={tier2}
                    alt="Tier 2"
                    style={{
                      maxWidth: "88%",
                    }}
                    className="tier2"
                  />
                </SwiperSlide>
              </Swiper>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
              <div
                style={{
                  position: "absolute",
                  top: "31px",
                  right: "39px",
                  width: "47px",
                  height: "58px",
                  // borderRadius: "50%",
                  backgroundImage: `url(${counter})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 5,
                  fontWeight: "bold",
                  // boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.75)",
                }}
                className="chest-counter"
              >
                <span
                  className="chest-counter-font"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  {chestCount}x
                </span>
              </div>
            </Box>
          )}
        </div>

        <div className="btn-buy-openchest btn-buy-now mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show">
          <img
            src={greenButton}
            className={` ${
              altarButtonShow ? "v-store-img-show" : "v-store-img-hide"
            }`}
            alt="storedetailimg"
            style={{
              filter: chestCount === 0 ? "grayscale(100%)" : "none",
              cursor: chestCount === 0 ? "not-allowed" : "pointer",
            }}
          />
        </div>
        <div
          onClick={altarHandler}
          className={`btn-open-chest mb-4 mb-lg-0 btn-openchest-bad d-flex align-items-center text-center justify-content-center font-face-bad-blockhead ${
            altarButtonShow ? "v-store-img-show" : "v-store-img-hide"
          } ${openState === 0 ? "btn-open-v-hunter" : ""}`}
          style={{
            pointerEvents: chestCount === 0 ? "none" : "auto",
          }}
          disabled={chestCount === 0}
        >
          {getButtonLabel(openState)}
        </div>
        {hunterData && (
          <img
            src={getChestThumb(hunterData ? hunterData.rarity : 1, chestType)}
            className={`video-store-img ${
              videoShow ? "v-store-img-hide" : "v-store-img-show"
            }`}
            alt="storedetailimg"
            style={{
              width: "100%",
              maxWidth: "1300px",
            }}
          />
        )}

        <div
          className={`store-card cursor-pointer ${
            videoShow ? "v-store-img-hide" : "v-store-img-show"
          }`}
          onClick={reset}
        >
          <Storecard
            chestItem={hunterData}
            itemType={"HUNTER"}
            chestType={chestType}
          />
        </div>
      </div>
    </Fragment>
  );
};

function ChestCount({ chestCount, chestType }) {
  const chestOrChests = parseInt(chestCount) > 1 ? "chests" : "chest";

  return (
    <div className="btn-chest-rectangle-text mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show font-face-bad-blockhead">
      <p>
        {/* Your wallet contains {chestCount} {chestOrChests} */}
        {chestType === 1 ? "Tier 1 Chest" : "Tier 2 Chest"}
      </p>
    </div>
  );
}

export default Storedetail;
