import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import line from "../../assets/images/line.svg";
import Slider from "@mui/material/Slider";
import plus from "../../assets/images/plus.svg";
import { useDispatch } from "react-redux";
import { setLevel as storeSetLevel } from "../../store/Items/actions";
import { useSelector } from "react-redux";

const Level = (props) => {
  const [level, setLevel] = useState([0, 11]);

  const dispatch = useDispatch();

  const handleLevel = (e, newValue) => {
    setLevel(newValue);
  };

  const handlelevelend = (e, newValue) => {
    dispatch(storeSetLevel({ min: newValue[0] - 1, max: newValue[1] - 1 }));
  };

  const toggleLevel = () => props.setLevelOpen(!props.LevelOpen);

  function valuetext(value) {
    return `${value}`;
  }

  const filterItems = useSelector((state) => state.Items);

  useEffect(() => {
    setLevel([filterItems?.level?.min + 1, filterItems?.level?.max + 1]);
  }, []);

  return (
    <div>
      <div
        onClick={toggleLevel}
        className={`${
          props.LevelOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Level</div>
        {props.LevelOpen ? <img src={line} /> : <img src={plus} />}
      </div>
      {props.LevelOpen && (
        <Collapse isOpen={props.LevelOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <div className="mb-3 row-1 d-flex align-items-center">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  // aria-label="Custom marks"
                  value={level}
                  onChange={(e, newValue) => handleLevel(e, newValue)}
                  onChangeCommitted={handlelevelend}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={1}
                  marks
                  min={1}
                  max={10}
                />
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default Level;
