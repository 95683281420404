import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Element,
Filler
} from "chart.js";
import { Line } from "react-chartjs-2";
 

export  const tooltipLine = {
  id: "tooltipLine",
  beforeDraw : chart => {
      if(chart._active && chart._active.length)
      {const ctx = chart.ctx;
      // console.log(ctx)
      ctx.save();
      const activePoint = chart._active[0];
      // console.log(activePoint);
      ctx.beginPath();
      ctx.moveTo(activePoint.element.x ,activePoint.element.y);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
      ctx.lineWidth = 2;
      ctx.strokeStyle = "linear-gradient(81.72deg, #FAAC3F -0.72%, #9334CF 113.02%)";
      ctx.stroke();
      ctx.restore();
    }
   }
}

export  const CanvasGradient = {
  id: "tooltipLine",
  beforeDraw : chart => {
      if(chart._active && chart._active.length)
      {const ctx = chart.ctx;
      // console.log(ctx)
      ctx.save();
      const activePoint = chart._active[0];
      // console.log(activePoint);
      ctx.beginPath();
      ctx.moveTo(activePoint.element.x ,activePoint.element.y);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
      ctx.lineWidth = 2;
      ctx.strokeStyle = "linear-gradient(81.72deg, #FAAC3F -0.72%, #9334CF 113.02%)";
      ctx.stroke();
      ctx.restore();
    }
   }
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  tooltipLine,
  Filler,
  CanvasGradient,

);


export function StakingDashboard() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState("today");


  // /-----------------graph data -------------------------------------/
  const labels = [
    '0','8/6','8/6','8/7','8/8'
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "date",
        displayLabel: false,
        data: [
          200,220,240,260,300
        ],
        borderColor: "linear-gradient(81.72deg, #FAAC3F -0.72%, #9334CF 113.02%)",
        // backgroundColor: "linear-gradient(180deg, rgba(217, 217, 217, 0.09) 0%, rgba(217, 217, 217, 0) 100%)",
        fill: true,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
    
  };
  // /-----------------graph options -------------------------------------/


  const options = {
    responsive: true,
    annotation: {
      drawTime: "afterDatasetsDraw", // (default)
      events: ["click"],
      dblClickSpeed: 350, // ms (default)
      annotations: [
        {
          drawTime: "afterDraw",
          id: "a-line-1",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: "50",
        //   borderColor: "red",
          borderWidth: 5
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      },
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      lineHeightAnnotation: {
        always: true,
        hover: false,
        color: "red",
        nodash: true
      },
      tooltip : {
          yAlign :"bottom",  
          callbacks : {
            title : context => {
              // console.log(context)
              if(context.length > 0){
                const labels= context[0].label
                return labels
            }
            }
          }, 
          displayColors: false,
          backgroundColor : "white",
          titleColor : "black",
          bodyColor : "black",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
  };
  
  return (
    <div className="mt-3 mt-lg-0 defi-cards-bg">
      <div className="d-block d-sm-flex justify-content-between align-items-center">
        <div>
            <p className="">BOOM/BGEM POOL</p>
            <h4>$150,599,233.70</h4>
        </div>
        <div className="tab-content-defi d-flex justify-content-between align-items-center">
            <div className='px-4 tabs-defi-active'>All</div>
        </div>
      </div>
      <div className="mt-3" >
        <Line options={options} data={data} height={100}   />
      </div>
      
    </div>
  );
}