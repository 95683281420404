import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";

import $BGEM from "../../assets/images/daily/Diamond.svg";
import { PassportProvider, usePassport } from "../../context/PassportProvider";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import discord from "../../assets/social-media/discord.svg";
import twitter from "../../assets/social-media/twitter.png";
import { useSearchParams } from "react-router-dom";
import { getCookie, setCookie } from "typescript-cookie";

const twitterUrl =
  "https://twitter.com/i/oauth2/authorize?client_id=QmlNMVlwcEdOazJUVmNUaU9ROGo6MTpjaQ&response_type=code&redirect_uri=https://boomland.io/socials&scope=users.read+tweet.read&state=state&code_challenge=challenge&code_challenge_method=plain";

const discordUrl =
  "https://discord.com/oauth2/authorize?client_id=1272903948998545418&response_type=code&redirect_uri=https%3A%2F%2Fboomland.io%2Fsocials&scope=guilds+identify+guilds.members.read";

const SocialsPage = () => {
  const [discordUsername, setDiscord] = useState("");
  const [twitterUsername, setTwitter] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [clickedTwitter, setClickedTwitter] = useState(false);
  const [clickedDiscord, setClickedDiscord] = useState(false);

  const currentUrl = window.location.href;
  const playerId = currentUrl.split("id=")[1];

  const code = searchParams.get("code");

  const getPlayer = async () => {
    const id = playerId || getCookie("playerId");

    const response = await axios.get(
      `https://api-testnet.boomland.io/api/user/`,
      { headers: { Authorization: id } }
    );

    const { discord: apiDiscord, x } = response.data;
    setDiscord(apiDiscord);
    setTwitter(x);
  };

  useEffect(() => {
    if (!playerId) return;

    getPlayer();
  }, []);

  const updateTwitter = async () => {
    try {
      const id = playerId || getCookie("playerId");

      await axios.patch(
        `https://api-testnet.boomland.io/api/user/x`,
        { code },
        { headers: { Authorization: id } }
      );
      toast.success("Success! The BGEM was added to your balance");
      await getPlayer();
    } catch (e) {
      if (e?.response?.status === 412)
        toast.error("User does not follow Boomland!");
      else toast.error("Internal Server Error. Please try again later");
    }
  };

  const updateDiscord = async () => {
    const id = playerId || getCookie("playerId");
    try {
      await axios.patch(
        `https://api-testnet.boomland.io/api/user/discord`,
        { code },
        { headers: { Authorization: id } }
      );
      toast.success("Success! The BGEM was added to your balance");
      await getPlayer();
    } catch (e) {
      if (e?.response?.data?.error === "User is not on the discord server")
        toast.error("User is not on Boomland's Discord Server");
      else toast.error("Internal Server Error. Please try again later");
    }
  };

  useEffect(() => {
    if (!code) return;

    const isTwitter = searchParams.get("state");

    if (isTwitter) updateTwitter();
    else updateDiscord();
  }, [code, playerId]);

  return (
    <div className="container-x h-full">
      <Toaster />
      <div className="sortbar">
        <h3>Social Missions</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 sm:w-[480px] sm:h-[640px] self-center">
          <div className="defi-cards-bg mt-2 sm:w-[480px] sm:min-h-[320px] overflow-clip">
            <span>Connect</span>
            <div className="flex flex-col justify-center mt-4">
              <div className="flex flex-row justify-between bg-black rounded-md p-2">
                <img
                  src={discord}
                  alt="discord"
                  className="h-[36px] w-[36px]"
                />
                <span className="self-center text-xs md:text-md">
                  Join our Discord
                </span>
                <div className="flex flex-row">
                  <img
                    src={$BGEM}
                    alt="bgem"
                    className="w-[20px] self-center"
                  />
                  <span className="self-center ml-1 text-sm">500</span>
                </div>
                {discordUsername ? (
                  <div className="flex flex-col justify-center p-2 rounded-md bg-green-800 text-xs  min-w-[80px]">
                    <div className="truncate text-center">
                      {discordUsername}
                    </div>
                  </div>
                ) : clickedDiscord ? (
                  <button
                    className="rounded-md bg-green-400 p-2 text-xs hover:bg-green-300 shadow-lg shadow-black"
                    onClick={() => {
                      if (!getCookie(playerId)) setCookie("playerId", playerId);
                      window.location.href = discordUrl;
                    }}
                  >
                    VERIFY
                  </button>
                ) : (
                  <button
                    className="rounded-md bg-green-400 p-2 px-3 text-xs hover:bg-green-300 shadow-lg shadow-black"
                    onClick={() => {
                      setClickedDiscord(true);
                      if (!getCookie(playerId)) setCookie("playerId", playerId);
                      window.open("https://discord.gg/qgpam6wn", "_blank");
                    }}
                  >
                    JOIN
                  </button>
                )}
              </div>

              <div className="mt-2 flex flex-row justify-between bg-black rounded-md p-2">
                <img
                  src={twitter}
                  alt="twitter"
                  className="h-[36px] w-[36px]"
                />
                <span className="self-center text-xs md:text-md">
                  Follow us on X
                </span>
                <div className="flex flex-row">
                  <img
                    src={$BGEM}
                    alt="bgem"
                    className="w-[20px] self-center ml-3"
                  />
                  <span className="self-center ml-1 text-sm">500</span>
                </div>
                {twitterUsername ? (
                  <div className="flex flex-col justify-center p-2 rounded-md bg-green-800 text-xs  min-w-[80px]">
                    <div className="truncate text-center">
                      {twitterUsername}
                    </div>
                  </div>
                ) : clickedTwitter ? (
                  <button
                    className="rounded-md bg-green-400 p-2 text-xs hover:bg-green-300 shadow-lg shadow-black"
                    onClick={() => {
                      if (!getCookie(playerId)) setCookie("playerId", playerId);
                      window.location.href = twitterUrl;
                    }}
                  >
                    VERIFY
                  </button>
                ) : (
                  <button
                    className="rounded-md bg-green-400 p-2 text-xs hover:bg-green-300 shadow-lg shadow-black"
                    onClick={() => {
                      setClickedTwitter(true);
                      if (!getCookie(playerId)) setCookie("playerId", playerId);
                      window.open("https://x.com/BoomLandGames", "_blank");
                    }}
                  >
                    FOLLOW
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Socials = () => (
  <PassportProvider>
    <SocialsPage />
  </PassportProvider>
);
export default Socials;
