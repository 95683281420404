import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";
import $BOOM3 from "../../assets/images/$BOOM3.svg";
import downErrow from "../../assets/images/downErrow.svg";

import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
  useAccountModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { BigNumber, Contract, constants, ethers } from "ethers";
import $BGEM from "../../assets/images/daily/Diamond.svg";
import config, { mainnetConfig } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import imxLogo from "../../assets/images/passport_logo.svg";
import sequenceLogo from "../../assets/images/sequence_logo.png";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";
import { PassportProvider, usePassport } from "../../context/PassportProvider";
import { useDispatch } from "react-redux";
import { getHunters, loaderonoff } from "../../store/Items/actions";
import { useSelector } from "react-redux";
import { sequence } from "0xsequence";
import axios from "axios";
const publicRpcUrl = "https://rpc.ankr.com/polygon";
const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const { chains, publicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: () => ({ http: publicRpcUrl }),
    }),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: "Available",
    wallets: [
      sequenceWallet({
        chains,
        defaultNetwork: "polygon",
        connect: {
          app: "HuntersOnChain",
        },
      }),
    ],
  },
]);
const wagmiConfig = createConfig({
  connectors,
  publicClient,
});

const SwapPage = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { data: walletClient } = useWalletClient();
  const { openAccountModal } = useAccountModal();
  const { connect, disconnect, address } = usePassport();
  const dispatch = useDispatch();
  const allhunters = useSelector((state) => state.Items.allhunters);
  const { address: sequenceWallet } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const [txHash, setTxHash] = useState("");

  useEffect(() => {
    if (!sequenceWallet) return;

    dispatch(loaderonoff(true));
    let params = {
      common: 0,
      uncommon: 1,
      rare: 1,
      epic: 1,
      legendary: 1,
      isLent: 0,
      isBorrowed: 0,
    };
    dispatch(getHunters(params, sequenceWallet));
    dispatch(loaderonoff(false));
  }, [sequenceWallet]);

  const verifyBetaTester = async () => {
    const response = await axios.get(
      `https://api.boomland.io/api/v1/bridge/beta-tester/${sequenceWallet}`
    );

    return response?.data?.response?.allowed;
  };

  const transferHunters = async () => {
    try {
      setIsLoading(true);
      await switchNetworkAsync(137);
      if (!txHash) return;

      const isBetaTester = await verifyBetaTester();
      if (!isBetaTester) {
        setError("This tool is not available yet, stay tunned to our discord!");
        return;
      }

      const wallet = await sequence.getWallet();
      const sequenceSigner = wallet.getSigner(137);

      const message = `Boomland - Bridge Genesis Hunters from Polygon ${sequenceWallet} to Immutable ${address}`;

      const signature = await sequenceSigner.signMessage(message);

      await axios.post("https://api.boomland.io/api/v1/bridge/start", {
        txHash: txHash,
        signature,
        account: sequenceWallet,
        immutableAddress: address,
      });

      setSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      setError(e?.message || "Internal Server Error");
    }
  };

  return (
    <div className="container-x h-full">
      <div className="sortbar">
        <h3>Bridge from Polygon to Immutable</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 self-center">
          <div className="flex flex-row justify-center w-full">
            <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] flex flex-row flex-wrap overflow-auto mr-10">
              {sequenceWallet && allhunters?.length
                ? allhunters
                    ?.filter((hunter) => hunter.details.permanentPerk4)
                    ?.map((hunter, index) => {
                      const imgid = hunter?.details?.generatedName.slice(0, 3);

                      return (
                        <div className="w-[120px] h-[120px] p-1" key={index}>
                          <img
                            className={`rounded-md w-full h-full`}
                            src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${hunter?.details?.generatedName}.gif`}
                          />
                        </div>
                      );
                    })
                : ""}
              {!allhunters?.length && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Genesis Hunters found.
                </span>
              )}
            </div>
            <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] overflow-clip">
              <div className="defi-card-header flex justify-center align-items-center flex-col sm:flex-row">
                <button
                  className="px-4 py-2 rounded-lg flex flex-row justify-center hover:bg-gradient-to-r hover:from-[#9734d9] hover:to-[#f5bc0c]"
                  type="button"
                  onClick={walletClient ? openAccountModal : openConnectModal}
                >
                  <img
                    className="w-[32px] h-[32px] self-center mr-2"
                    src={sequenceLogo}
                    alt="Logo"
                  />
                  <span className="text-center self-center text-xl">
                    {walletClient
                      ? `${walletClient.account.address.slice(
                          0,
                          10
                        )}... ${walletClient.account.address.slice(
                          walletClient.account.address.length - 10,
                          walletClient.account.address
                        )}`
                      : "Connect your Sequence Wallet"}
                  </span>
                </button>
              </div>
              <div className="defi-card-header flex justify-center align-items-center flex-col sm:flex-row mt-4">
                <button
                  className="mb-2 px-4 py-2 rounded-lg flex flex-row justify-center hover:bg-gradient-to-r hover:from-[#9734d9] hover:to-[#f5bc0c]"
                  type="button"
                  onClick={address ? disconnect : connect}
                >
                  <img
                    className="w-[32px] h-[32px] self-center mr-2"
                    src={imxLogo}
                    alt="Logo"
                  />
                  <span className="text-center self-center  text-xl">
                    {address
                      ? `${address.slice(0, 10)}... ${address.slice(
                          address.length - 10,
                          address
                        )}`
                      : "Connect your Immutable Wallet"}
                  </span>
                </button>
              </div>

              <span>Please type your tx hash:</span>
              <input
                type="text"
                className="bg-gray-700 h-10 rounded-md text-white w-full"
                onChange={(e) => {
                  setTxHash(e.target?.value);
                }}
                value={txHash}
              ></input>

              <div className="flex flex-col justify-center mt-12">
                {error ? <span className="text-red-500">{error}</span> : null}

                {success ? (
                  <span className="text-green-500">
                    Congratulations! You succesfuly bridged your genesis
                    hunters!{" "}
                    <b>
                      They will be on your immutable wallet in up to 10 minutes,
                      please wait.
                    </b>
                  </span>
                ) : null}
              </div>

              <button
                className="mt-2 w-full self-end"
                onClick={transferHunters}
                disabled={
                  success ||
                  !walletClient?.account?.address ||
                  !address ||
                  !txHash
                }
              >
                <div
                  className={`${
                    success || !walletClient?.account?.address || !address
                      ? "bg-gray-500 rounded-lg p-2"
                      : "buy-btn-defi"
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                  ) : (
                    "BRIDGE"
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Swap = () => (
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} theme={darkTheme()}>
      <PassportProvider>
        <SwapPage />
      </PassportProvider>
    </RainbowKitProvider>
  </WagmiConfig>
);
export default Swap;
