import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Collapse, Input, Label } from "reactstrap";
import line from "../../assets/images/line.svg";
import plus from "../../assets/images/plus.svg";
import { HideMyAssetsTrue } from "../../store/Items/actions";

const ProfileFilter = (props) => {
  const toggleProfileFilterOpen = () =>
    props.setProfileFilterOpen(!props.ProfileFilterOpen);

  const filterItems = useSelector((state) => state.Items);

  const [hideMyAssetsTrue, setHideMyAssetsTrue] = useState(true);

  useEffect(() => {
    setHideMyAssetsTrue(filterItems?.hideMyAssetsTrue === 1 ? true : false);
  }, [filterItems]);

  const dispatch = useDispatch();

  const filterHandler = (e) => {
    const val = e.target.checked ? 1 : 0;
    dispatch(HideMyAssetsTrue(val));
  };

  return (
    <div>
      <div
        onClick={toggleProfileFilterOpen}
        className={`${
          props.ProfileFilterOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Profile</div>
        {props.ProfileFilterOpen ? <img src={line} /> : <img src={plus} />}
      </div>
      {props.ProfileFilterOpen && (
        <Collapse isOpen={props.ProfileFilterOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Common"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => filterHandler(e)}
                  checked={hideMyAssetsTrue}
                />
                <Label for="Hide my assets" className="mb-0">
                  Hide my assets
                </Label>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default ProfileFilter;
