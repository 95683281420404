import React, { useLayoutEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
//img
import logo from "../../assets/images/logo.svg";
import menu from "../../assets/images/menu.svg";
import Group from "../../assets/images/Group.svg";
//context
import { useWalletConnect } from "../WalletConnectContext";
import { LinkWalletModal } from "../../components/Modal/LinkWalletModal";
import { shortenAddress } from "../../helpers/functions.helper";

const Header = () => {
  const pathname = useLocation().pathname;

  const { walletAddress, isWalletConnected } = useWalletConnect();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const [linkWalletModal, setLinkWalletModal] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const linkWalletToggle = () => {
    setLinkWalletModal(!linkWalletModal);
    if (mobileNavOpen) {
      toggleMobileNav();
    }
  };

  const stickyHeader = useRef();

  useLayoutEffect(() => {
    const marketHeader = document.getElementById("marketHeader");
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        marketHeader.classList.add("bg-header");
      } else {
        marketHeader.classList.remove("bg-header");
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  return (
    <>
      <div
        ref={stickyHeader}
        id="marketHeader"
        className={`fixedheader marketHeader ${
          mobileNavOpen ? "bg-header" : ""
        }`}
      >
        <div className={`container-x mx-auto`}>
          <div className="headerM position-relative">
            <div className="left-header">
              <Link to="/">
                <div className="d-flex align-items-center">
                  <img className="logo-img" width={196} src={logo} alt="logo" />
                </div>
              </Link>
              {(pathname === "/defi/dashboard" ||
                pathname === "/defi/swap" ||
                pathname === "/defi/staking" ||
                pathname === "/defi/vesting") && (
                <div className="d-none d-lg-flex market d-flex align-items-center">
                  <img src={Group} width={24} alt="Group" />
                  <p className="d-none d-lg-flex">Defi</p>
                </div>
              )}
            </div>

            <div className="right-header">
              <Link className="d-none d-md-flex" to="/defi/dashboard">
                <p
                  className={`${
                    pathname === "/defi/dashboard" ? "selectedp" : ""
                  }`}
                >
                  Dashboard
                </p>
              </Link>
              <Link className="d-none d-md-flex" to="/defi/swap">
                <p
                  className={`${pathname === "/defi/swap" ? "selectedp" : ""}`}
                >
                  Swap
                </p>{" "}
              </Link>
              <Link className="d-none d-md-flex" to="/defi/staking">
                <p
                  className={`${
                    pathname === "/defi/staking" ? "selectedp" : ""
                  }`}
                >
                  Staking
                </p>{" "}
              </Link>
              <Link className="d-none d-md-flex" to="/defi/vesting">
                <p
                  className={`${
                    pathname === "/defi/vesting" ? "selectedp" : ""
                  }`}
                >
                  Vesting
                </p>{" "}
              </Link>
              {isWalletConnected && (
                <div
                  onClick={linkWalletToggle}
                  className="d-none d-md-flex idnumber"
                >
                  <span>{shortenAddress(walletAddress)}</span>
                </div>
              )}
              {!isWalletConnected && (
                <div
                  onClick={linkWalletToggle}
                  className="d-none d-md-flex idnumber text-lg"
                >
                  <span>Connect Wallet</span>
                </div>
              )}
              <div
                onClick={toggleMobileNav}
                className="cursor-pointer menu d-flex d-md-none"
              >
                <img src={menu} alt="cart" />
              </div>
            </div>
          </div>
          <div
            className={`pb-3 ${
              mobileNavOpen ? "d-block" : "d-none"
            } d-md-none mobile-nav-content`}
          >
            <Link
              onClick={toggleMobileNav}
              className="mobile-nav-links"
              to="/defi/dashboard"
            >
              <p
                className={`${
                  pathname === "/defi/dashboard" ? "selectedp" : ""
                }`}
              >
                Dashboard
              </p>
            </Link>
            <Link
              onClick={toggleMobileNav}
              className="mobile-nav-links"
              to="/defi/swap"
            >
              <p className={`${pathname === "/defi/swap" ? "selectedp" : ""}`}>
                Swap
              </p>{" "}
            </Link>
            <Link
              onClick={toggleMobileNav}
              className="mobile-nav-links"
              to="/defi/staking"
            >
              <p
                className={`${pathname === "/defi/staking" ? "selectedp" : ""}`}
              >
                Staking
              </p>{" "}
            </Link>
            <Link
              onClick={toggleMobileNav}
              className="mobile-nav-links"
              to="/defi/vesting"
            >
              <p
                className={`${pathname === "/defi/vesting" ? "selectedp" : ""}`}
              >
                Vesting
              </p>{" "}
            </Link>
            {isWalletConnected && (
              <div onClick={linkWalletToggle} className="my-2 idnumber">
                <span>{shortenAddress(walletAddress)}</span>
              </div>
            )}
            {!isWalletConnected && (
              <div onClick={linkWalletToggle} className="my-2 idnumber">
                <span className=" text-lg">Connect Wallet</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <LinkWalletModal
        isOpened={linkWalletModal}
        toggleModal={linkWalletToggle}
      ></LinkWalletModal>
    </>
  );
};

export default Header;
