/* eslint-disable eqeqeq */

/**
 * @file chainConfig.js
 * @description Holds the chain configuration
 */

import config from "../config";

export const CHAIN_ID = config.NetId;
export const CHAIN_NAME = CHAIN_ID == 137 ? "polygon" : "mumbai";
export const LOCAL_COUNT_KEY = "chest_open_count";

export const CONTRACT_ADDRESSES = {
  /* Testnet */
  80001: {
    MARKETPLACE: "0x912c9C15149989DaeA984E747fb5A63d8f3fCbA9",
    LENDING: "0xe7D9721008F916114826b6c3Cf06BDc8B0E634f3", //for testnet
    // LENDING: "0xCCfECE0f4E719A0FebA4b48EC1597d15f6D23EA8", // for devnet
    MAGIC_EDEN: "0x6067E4c76ABF90E0315503AB90F16150b2d7E0a0",
    //MAGIC_EDEN_TIER2: "0xB5D4C0D2160aB4E919E45904E358B026De4062D2",
    //MAGIC_EDEN_OPENING: "0x2c70B10E657FB6DBc802c7bFC78e6AA029365139",
    MAGIC_EDEN_TIER2: "0xe13CDbe1B619837773De29f316428D7f65E7df9a",
    MAGIC_EDEN_OPENING: "0xBE7e1b81F64b90A9d632E5f11C9cA32927Ec7e30",
    RECIPE: "0x27ec55B7d3e2aD9940E4D54C5603C706118FD070", // testnet
    FAUCET: "0xb912b67815ea3302EE85dd22F98Bcf75D8Bd3132",
    BOOM: "0xe28a5eEccEa87c34F4aE2D90197d66EE2A87f13A",
    EQUIPMENT: "0xb360978Be0Da50Ce2B31c6E904251636b81F0369",
  },
  /* Mainnet */
  137: {
    MARKETPLACE: "0x912c9C15149989DaeA984E747fb5A63d8f3fCbA9",
    LENDING: "0xBc51dEaD4297515feBf210c216883cB84986D750", //for testnet this is going change for mainnet
    MAGIC_EDEN: "0x5fdf45a0ff4883b9f681b1800d6670d1d155811d",
    MAGIC_EDEN_TIER2: "0xe13CDbe1B619837773De29f316428D7f65E7df9a",
    MAGIC_EDEN_OPENING: "0x11CEF46a0FD5A9C36ed052F304fB2fe25EBFd7Bc",
    RECIPE: "0x2B7144c1C325f699eF347C48385b60D3F3f20Fe4",
    FAUCET: "0x3a1F862D8323138F14494f9Fb50c537906b12B81",
  },
};

export const BOOM_DECIMALS = 2;
export const BGEM_DECIMALS = 18;
