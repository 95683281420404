
const Address = () => {
        
        const adress = JSON.parse(localStorage.getItem('@sequence.session')) 
        // console.log(walletAddress())
        return adress?.accountAddress 
}

export default Address;

