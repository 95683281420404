import React from "react";
import Beth from "../../assets/images/beth.png";
import Alex from "../../assets/images/alex.png";
import Gabriel from "../../assets/images/gabriel.jpg";
import Tomasz from "../../assets/images/tomasz.jpg";
import BethC from "../../assets/images/beth_c.png";

class Team extends React.Component {
  render() {
    return (
      <section id="team" className="section section-home section-team">
        <div className="container">
          <div className="row section-header">
            <div className="col-lg-12 col-10 offset-1 offset-lg-0">
              <h2 className="section-title">CORE TEAM</h2>
              <p className="section-paragraph">
                BoomLand core team consists of industry veterans and true gamers
                at heart that care for building a thriving community.
              </p>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-1-5 col-4 mb-5">
              <img
                className="team-pp"
                src="https://www.shopimia.com/assets/www/boomland.io/202207/media/team-1.png"
              />
              <h3 className="team-full-name">Hannibal Soares</h3>
              <p className="team-position">CEO & Founder</p>
            </div>
            <div className="col-lg-1-5 col-4 mb-5">
              <img
                className="team-pp"
                src="https://www.shopimia.com/assets/www/boomland.io/202207/media/team-5.png"
              />
              <h3 className="team-full-name">Serhat Marasligil</h3>
              <p className="team-position">Chief Operating Officer</p>
              <p className="team-background d-none d-lg-block"></p>
            </div>
            <div className="col-lg-1-5 col-4 mb-5">
              <img
                className="team-pp"
                src="https://www.shopimia.com/assets/www/boomland.io/202207/media/team-10.png"
              />
              <h3 className="team-full-name">Bartek Kania</h3>
              <p className="team-position">Product Owner</p>
              <p className="team-background d-none d-lg-block"></p>
            </div>
            <div className="col-lg-1-5 col-4 mb-5">
              <img
                className="team-pp"
                src="https://www.shopimia.com/assets/www/boomland.io/202207/media/team-4.png"
              />
              <h3 className="team-full-name">Piotr Murawski</h3>
              <p className="team-position">Head of Game Design</p>
              <p className="team-background d-none d-lg-block"></p>
            </div>
            <div className="col-lg-1-5 col-4 mb-5">
              <img className="team-pp" src={Beth} />
              <h3 className="team-full-name">Bethany Trundle</h3>
              <p className="team-position">Head of Growth</p>
              <p className="team-background d-none d-lg-block"></p>
            </div>
            <div className="col-lg-2 col-4 mb-5">
              <img
                className="team-pp"
                src="https://www.shopimia.com/assets/www/boomland.io/202207/media/team-9.png"
              />
              <h3 className="team-full-name">Fabian Italiani</h3>
              <p className="team-position">Technical Artist</p>
            </div>
            <div className="col-lg-2 col-4">
              <div className="team-pp team-picture-container">
                <img className="team-picture" src={BethC} />
              </div>
              <h3 className="team-full-name">Beth Carriuolo</h3>
              <p className="team-position">Marketing Manager</p>
            </div>
            <div className="col-lg-2 col-4">
              <img className="team-pp" src={Alex} />
              <h3 className="team-full-name">Aleks Sokołowski</h3>
              <p className="team-position">Technical Lead</p>
            </div>
            <div className="col-lg-2 col-4">
              <div className="team-pp team-picture-container">
                <img className="team-picture" src={Tomasz} />
              </div>
              <h3 className="team-full-name">Tomasz Kula</h3>
              <p className="team-position">Game Developer</p>
            </div>
            <div className="col-lg-2 col-4">
              <div className="team-pp team-picture-container">
                <img className="team-picture" src={Gabriel} />
              </div>
              <h3 className="team-full-name">Gabriel Fialho</h3>
              <p className="team-position">Sr. Developer</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
