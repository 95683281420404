import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Lazy} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css/bundle';

import Header from "../../components/Header";
import { useMetamaskConnect } from "../../context";
import { chestData } from "../../data";

import "./store.css";
import { SocialMediaContainer } from "../../components/SocialMediaContainer";
import { Modal } from "../GoldenTicket/Modal";

import Tier from "../../assets/store/tier.png";
import TierSm from "../../assets/store/tier-sm.png";
import ShoppingBasket from "../../assets/store/shopping-basket.png";
import BuyBox from "../../assets/chest/buy-box.png";


import {Route, Link, Routes, useParams} from 'react-router-dom';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);



export function Store() {
	const params = useParams();


    return (<Fragment>
            <Header />
            <div className="section section-home section-welcome2">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<img src={Tier} className="img-fluid d-none d-md-block"/>
										<img src={TierSm} className="img-fluid d-block d-md-none"/>
									</div>
								</div>
							</div>
							<div id="chests" className="container">
								<div className="row">
									<div className="col-12">
										<div className="d-flex align-items-center justify-content-between card card-black store-header">
											<h1>
												Buy Chest
											</h1>
										</div>
									</div>
								</div>
								<div className="row d-flex d-md-none justify-content-center">
									<div className="col-6">
									<Swiper
										spaceBetween={30}
										slidesPerView={1}
										centeredSlides={true}
										autoHeight={true}
										pagination={{ clickable: true }}
									>
										<SwiperSlide>
											<div className="mb-5">
												<a className="btn-ghost" href="/chest/0">
													<img src={chestData[0].cover} className="img-fluid w-90" />
												</a>
												<a className="btn btn-chest btn-normal-mint" href="/chest/0">
													{chestData[0].price}
												</a>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className="mb-5">
												<a className="btn-ghost" href="/chest/1">
													<img src={chestData[1].cover} className="img-fluid w-90" />
												</a>
												<a className="btn btn-chest btn-traveler-lockbox" href="/chest/1">
													{chestData[1].price}
												</a>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className="mb-5">
												<a className="btn-ghost" href="/chest/1">
													<img src={chestData[2].cover} className="img-fluid w-90" />
												</a>
												<a className="btn btn-chest btn-warriors-chest" href="/chest/2">
													{chestData[2].price}
												</a>
											</div>
										</SwiperSlide>
									</Swiper>
									</div>
								</div>
								<div className="row d-none d-md-flex">
									<div className="col-6 col-md-4 text-center">
										<a className="btn-ghost w-90 d-block mx-auto" href="/chest/0">
											<img src={chestData[0].cover} className="img-fluid w-90" />
										</a>
										<a className="btn btn-chest btn-normal-mint" href="/chest/0">
											{chestData[0].price}
										</a>
									</div>
									<div className="col-6 col-md-4 text-center">
										<a className="btn-ghost w-90 d-block mx-auto" href="/chest/1">
											<img src={chestData[1].cover} className="img-fluid w-90" />
										</a>
										<a className="btn btn-chest btn-traveler-lockbox" href="/chest/1">
											{chestData[1].price}
										</a>
									</div>
									<div className="col-6 col-md-4 text-center">
										<a className="btn-ghost w-90 d-block mx-auto" href="/chest/2">
											<img src={chestData[2].cover} className="img-fluid w-90" />
										</a>
										<a className="btn btn-chest btn-warriors-chest" href="/chest/2">
											{chestData[2].price}
										</a>
									</div>
								</div>
							</div>
            </div>
            <SocialMediaContainer />
            </Fragment>
    );
}
