/* eslint-disable no-unused-vars */

import React from "react";
import { CardBody, Card } from "reactstrap";

const Artifactscard = ({ m, name }) => {
  const imgg = () => {
    if (name === "ARTIFACT")
      return require(`../../assets/asset-images/Perks_Numbered/a${m?.tokenId}.png`);
    if (name === "EQUIPMENT")
      return require(`../../assets/asset-images/Equipments/${m?.type}.png`);
    if (name === "SHARDS")
      return require(`../../assets/asset-images/Shards/${m?.tokenId}.png`);
  };

  const formatDate = (val) => {
    const expireDate = new Date(val).getTime();
    const currentDate = new Date().getTime();
    const leftDuration = expireDate - currentDate;
    return leftDuration > 0 ? `${leftDuration} s` : "Expired";
  };

  return (
    <Card className="productcard ">
      <div
        className={`cardbg-UNCOMMON
        ${m?.rarity === 0 && "bg-common"}
        ${m?.rarity === 1 && "bg-uncommon"}
        ${m?.rarity === 2 && "bg-rare"}
        ${m?.rarity === 3 && "bg-epic"}
        ${m?.rarity === 4 && "bg-legendary"}
        relative d-flex align-items-center justify-content-center`}
      >
        <img
          className="name-logo"
          src={require("../../assets/images/Name-logo.png")}
          alt="Name_logo"
        />
        <img className="equipmentimg" alt="heroes_3" src={imgg()} />
        {/* <img className='heroimg' alt="heroes_3" src={heroes1}/> */}
        {/* <div className='energy-bage'>
             <img src={energy} alt='energy' />
            <span>{m?.remainingDailyHunterLimit}/{m?.maxDailyHunterLimit}</span>
          </div>  */}
      </div>

      <CardBody>
        <div className="name-card d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0 name">{m?.typeName ? m?.typeName : m.name}</p>
            <p
              className={`mb-0 rarity
                ${m?.rarity === 0 && "text-common"}
                ${m?.rarity === 1 && "text-uncommon"}
                ${m?.rarity === 2 && "text-rare"}
                ${m?.rarity === 3 && "text-epic"}
                ${m?.rarity === 4 && "text-legendary"}
              `}
            >
              {m?.rarity === 0 && "COMMON"}
              {m?.rarity === 1 && "UNCOMMON"}
              {m?.rarity === 2 && "RARE"}
              {m?.rarity === 3 && "EPIC"}
              {m?.rarity === 4 && "LEGENDARY"}
            </p>
          </div>
          {/* <span className='mb-0 level'>LEVEL{m?.level}</span> */}
        </div>
      </CardBody>
      <CardBody className="stats-card">
        <div className="artifacts-name-box">{name}</div>
        {/* <div className='name-card'>
            <div className='stat-card-detail'>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Damage} alt='Damage' />
                 <div className='statsbadge'>{m?.damage}</div>
              </div>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Range} alt='Damage' />
                 <div className='stat-w-min statsbadge'>0</div>
              </div>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Speed} alt='Damage' />
                 <div className='statsbadge'>{m?.speed}</div>
              </div>
            </div>
            <div className='stat-card-detail'>
              <div className='single-stat  d-flex align-items-center'>
                 <img src={HP} alt='HP' />
                 <div className='statsbadge'>{m?.hp}</div>
              </div>
              <div className=' single-stat  d-flex align-items-center'>
                 <img src={Attack} alt='HP' />
                 <div className='stat-w-min statsbadge'>{m?.attackSpeed}</div>
              </div>
              <div className='single-stat  d-flex align-items-center'>
                 <img src={Mvt} alt='HP' />
                 <div className='statsbadge'>0</div>
              </div>
            </div> */}
        {/* <div>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Damage} alt='Damage' />
                 <div className='statsbadge'>{m.Damage}</div>
              </div>
              <div className='single-stat mt-3 d-flex align-items-center'>
                 <img src={HP} alt='HP' />
                 <div className='statsbadge'>{m.Hp}</div>
              </div>
            </div> */}
        {/* <div>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Range} alt='Damage' />
                 <div className='statsbadge'>{m.Range}</div>
              </div>
              <div className='single-stat mt-3 d-flex align-items-center'>
                 <img src={Attack} alt='HP' />
                 <div className='statsbadge'>{m.Attack}</div>
              </div>
            </div> */}
        {/* <div>
              <div className='single-stat d-flex align-items-center'>
                 <img src={Speed} alt='Damage' />
                 <div className='statsbadge'>{m.Speed}</div>
              </div>
              <div className='single-stat mt-3 d-flex align-items-center'>
                 <img src={Mvt} alt='HP' />
                 <div className='statsbadge'>{m.Mvt}</div>
              </div>
            </div> */}
        {/* </div> */}
      </CardBody>
      <CardBody>
        <div className="price-card d-flex align-items-center justify-content-end">
          {/* <div>
            <p className="offer mb-0">Offer Expires</p>
            <div className="d-flex align-items-center">
              <img className="" src={clock} alt="clock" />
              <p className="time">{formatDate(m.expiresAt)}</p>
            </div>
          </div> */}
          {/* <div>
            <p className="bid mb-0">Buy Now</p>
            <div className="d-flex align-items-center">
              <img className="" src={$BOOM} alt="clock" />
              <p className="time">{m.price}</p>
            </div>
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default Artifactscard;
