import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import line from "../../assets/images/line.svg";
import Slider from "@mui/material/Slider";

import Damage from "../../assets/images/Damage.svg";
import HP from "../../assets/images/HP.png";
import Speed from "../../assets/images/Speed.png";
import Mvt from "../../assets/images/Mvt.svg";
import plus from "../../assets/images/plus.svg";
import { useDispatch } from "react-redux";
import { setDamage, setHp, setMVT, setSpeed } from "../../store/Items/actions";
import { useSelector } from "react-redux";

const Stats = (props) => {
  const dispatch = useDispatch();

  const [attack, setattack] = useState([0, 50]);
  const [speed, setspeed] = useState([0, 50]);
  const [mvt, setmvt] = useState([0, 50]);
  const [hp, sethp] = useState([60, 200]);

  const toggleStats = () => props.setStatsOpen(!props.StatsOpen);
  function valuetext(value) {
    return `${value}`;
  }

  const filterItems = useSelector((state) => state.Items);

  useEffect(() => {
    setattack([filterItems?.damage?.min, filterItems?.damage?.max]);
    setspeed([filterItems?.speed?.min, filterItems?.speed?.max]);
    setmvt([filterItems?.mvt?.min, filterItems?.mvt?.max]);
    sethp([filterItems?.hp?.min, filterItems?.hp?.max]);
  }, [filterItems]);

  return (
    <div>
      <div
        onClick={toggleStats}
        className={`${
          props.StatsOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Stats</div>
        {props.StatsOpen ? <img src={line} /> : <img src={plus} />}
      </div>
      {props.StatsOpen && (
        <Collapse isOpen={props.StatsOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <div className="mb-3">
                <div className="mb-3 row-6 d-flex align-items-center">
                  <img src={Damage} />
                  <p className="mb-0 stat-font">Damage</p>
                </div>
                <Slider
                  value={attack}
                  onChange={(e, newValue) => {
                    setattack(newValue);
                  }}
                  onChangeCommitted={(e, newValue) =>
                    dispatch(setDamage({ min: newValue[0], max: newValue[1] }))
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={1}
                  marks
                  min={1}
                  max={35}
                />
              </div>
              <div className="mb-3">
                <div className="mb-3 row-6 d-flex align-items-center">
                  <img src={HP} />
                  <p className="mb-0 stat-font">HP</p>
                </div>
                <Slider
                  value={hp}
                  onChange={(e, newValue) => {
                    sethp(newValue);
                  }}
                  onChangeCommitted={(e, newValue) =>
                    dispatch(setHp({ min: newValue[0], max: newValue[1] }))
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={20}
                  marks
                  min={60}
                  max={450}
                />
              </div>
              <div className="mb-3">
                <div className="mb-3 row-6 d-flex align-items-center">
                  <img src={Speed} />
                  <p className="mb-0 stat-font">Attack Speed</p>
                </div>
                <Slider
                  value={speed}
                  onChange={(e, newValue) => {
                    setspeed(newValue);
                  }}
                  onChangeCommitted={(e, newValue) =>
                    dispatch(setSpeed({ min: newValue[0], max: newValue[1] }))
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={0.2}
                  marks
                  min={0.8}
                  max={1.8}
                />
              </div>
              <div className="mb-3">
                <div className="mb-3 row-6 d-flex align-items-center">
                  <img src={Mvt} />
                  <p className="mb-0 stat-font">Movement</p>
                </div>
                <Slider
                  value={mvt}
                  onChange={(e, newValue) => {
                    setmvt(newValue);
                  }}
                  onChangeCommitted={(e, newValue) =>
                    dispatch(setMVT({ min: newValue[0], max: newValue[1] }))
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={0.1}
                  marks
                  min={0.5}
                  max={1.5}
                />
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default Stats;
