import "./MainnetBanner.css";
import React from "react";
import testnetBannerMobile from "../../assets/home/testnet-banner-mobile-3.png"; // dont touch this line because it is same the desktop banner but only this one is empty picture without android button on it
import testnetBannerDesktop from "../../assets/home/testnet-banner-desktop.png";
import { isMobile } from "react-device-detect";
import testnetTag from "../../assets/home/testnet-tag.png";
import hocTag from "../../assets/images/huntersonchainlogo_large.png";
import appStoreButton from "../../assets/home/app-store-button.png";
import browserButton from "../../assets/home/web-browser-button.png";
import googlePlayButton from "../../assets/home/google-play-button.png";

export function TestnetBanner() {
  let storebanner;

  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.hunters.on.chain";
  const webBrowserLink = "https://hoc-testnet.boomland.io/";
  const appStoreLink = "https://testflight.apple.com/join/yHGzRwKR";

  let playNowLink = webBrowserLink;

  if (isMobile) {
    storebanner = testnetBannerMobile;
    playNowLink = playStoreLink;
  } else {
    storebanner = testnetBannerDesktop;
  }

  return (
    <div className="banner">
      <img src={storebanner} alt="storebanner" />

      <div className="banner-action-container">
        <img
          alt="Testnet tag"
          src={testnetTag}
          style={{ width: "40%", height: "auto", alignSelf: "center" }}
        />
        <img
          alt="play now button"
          src={hocTag}
          style={{ width: "70%", height: "auto" }}
        />
        <button className="play-now-button">
          <a href={playNowLink}>
            <p className="play-now-text">PLAY NOW</p>
          </a>
        </button>
        <div className="play-buttons-container">
          <a
            href={webBrowserLink}
            style={{ width: "45%", height: "auto", marginRight: "5%" }}
          >
            <img
              alt="web browser button"
              src={browserButton}
              href={webBrowserLink}
            />
          </a>
          <a href={playStoreLink} style={{ width: "45%", height: "auto" }}>
            <img alt="google play button" src={googlePlayButton} />
          </a>
          <a
            href={appStoreLink}
            style={{ width: "45%", height: "auto", marginLeft: "5%" }}
          >
            <img alt="app store button" src={appStoreButton} />
          </a>
        </div>
      </div>
    </div>
  );
}
