/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import axios from "axios";
//img
import x from "../../assets/images/x.svg";
import wallet from "../../assets/images/wallet.svg";
import metamask from "../../assets/images/metamask.svg";
import copy from "../../assets/images/copy.svg";
//context
import config, { BASE_URL } from "../../config";
import { useSequenceWalletConnect, useWalletConnect } from "../../context";
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from "../../helpers/functions.helper";
import { utils } from "ethers";
import { fulfillUrl, getUrl } from "../../helpers/url.helper";
import { setCookie, getCookie } from "typescript-cookie";
import { useNavigate } from "react-router-dom";
/**
 *
 * @param {string} address
 * @param {0 | 1 | 2} walletType
 * @param {import("0xsequence").Wallet} sequenceWallet
 * @returns
 */

const NetworkSwitch = styled(Switch)(({ theme }) => ({
  padding: 6,
  "& .Mui-checked": {
    transform: "translateX(20px)",
    color: "#b54cf8 !important",
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#b54cf8 !important",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.secondary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.secondary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const getChallenge = async (mainAddress, walletType, sequenceWallet) => {
  const address = await sequenceWallet.getAddress();
  const getUrl = "https://api.boomland.io/api/v1/auth/walletLinkChallenge";
  const fulfillUrl = "https://api.boomland.io/api/v1/auth/walletLinkFulfill";

  const { challengeId, target } = await axios
    .post(getUrl, { address: mainAddress })
    .then((res) => res.data.response);

  const message = `Boomland wallet link, challenge: ${target}`;

  const signer = sequenceWallet.getSigner(config.NetId);
  const signature = await signer.signMessage(message);

  try {
    await axios.post(fulfillUrl, {
      challengeId,
      mainAddress,
      address,
      signature,
      walletType,
    });

    // console.log(`[LinkWallet] Successfully completed challenge`);
    return { success: true };
  } catch {
    return { success: false, error: "Failed to sign message" };
  }
};

/**
 * Given a metamask address, returns the linked sequence wallets
 * @param {string} metamaskAddress
 * @returns {Promise<{ sequenceMain: string, sequenceTest: string }>}
 */
const getLinkedWallets = async (metamaskAddress) => {
  const { sequenceMain, sequenceTest } = await axios
    .get(`${BASE_URL}/auth/linkedWallets?address=${metamaskAddress}`)
    .then((res) => res.data.response);

  return { sequenceMain, sequenceTest };
};

async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export function LinkWalletModal({ isOpened, toggleModal }) {
  const {
    connectWallet,
    connectWalletError,
    disconnectWallet,
    walletAddress,
    sequenceWallet,
  } = useWalletConnect();
  const { deactivate } = useWeb3React();

  const [linkedAccounts, setLinkedAccounts] = useState({
    metamask: "",
    sequenceMainnet: "",
    sequenceTestnet: "",
  });
  // Update linked accounts when wallet address changes
  useEffect(() => {
    if (!utils.isAddress(walletAddress)) {
      setLinkedAccounts((prevState) => ({
        ...prevState,
        metamask: "",
      }));
      return;
    }

    const updateSequenceWallets = async (metamaskAddress) => {
      try {
        const { sequenceMain, sequenceTest } = await getLinkedWallets(
          metamaskAddress
        );

        setLinkedAccounts({
          metamask: metamaskAddress,
          sequenceMainnet: sequenceMain ?? "",
          sequenceTestnet: sequenceTest ?? "",
        });
      } catch {
        setLinkedAccounts((prevState) => ({
          ...prevState,
          metamask: metamaskAddress,
        }));
      }
    };

    updateSequenceWallets(walletAddress);
  }, [walletAddress]);

  /**
   * Connects to Metamask
   */
  const connectMetamask = async () => {
    sessionStorage.wallet = 2;
    await connectWallet(config.Wallet.METAMASK);
  };

  /**
   * Disconnects from Metamask
   */
  const disconnectMetamask = async () => {
    await disconnectWallet(config.Wallet.METAMASK);
    deactivate();
  };

  /**
   * Retries to connect to Metamask.
   * If network already exists, it will switch to it.
   * If network doesn't exist, it will add it.
   */
  const retryMetamask = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${config.NetId.toString(16)}` }],
        })
        .catch(() => {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [config.NetConfig],
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  /**
   * Helper function to set linked Sequence account.
   * @param {"sequence" | "sequence_testnet"} walletType
   * @param {string} address
   */
  const setSequenceAccount = (walletType, address) => {
    if (walletType === "sequence") {
      setLinkedAccounts({
        ...linkedAccounts,
        sequenceMainnet: address,
      });
    } else if (walletType === "sequence_testnet") {
      console.log("Setting sequence testnet account to: ", address);
      setLinkedAccounts({
        ...linkedAccounts,
        sequenceTestnet: address,
      });
    }
  };

  /**
   * Callback for when user clicks on "Connect" button for Sequence wallet.
   * @param {"sequence" | "sequence_testnet"} walletType
   */
  const onConnectSequence = (walletType) => async () => {
    sessionStorage.wallet = 0;

    const walletTypeNumber = walletType === "sequence" ? 1 : 2;

    await connectWallet(walletType, {
      keepWalletOpened: true,
    });

    await getChallenge(
      linkedAccounts.metamask,
      walletTypeNumber,
      sequenceWallet
    );
    setSequenceAccount(walletType, await sequenceWallet.getAddress());
    sequenceWallet.closeWallet();
  };

  /**
   * Callback for when user clicks on "Disconnect" button for Sequence wallet.
   * @param {"sequence" | "sequence_testnet"} walletType
   */
  const onDisconnectSequence = (walletType) => async () => {
    await disconnectWallet(walletType);
    setSequenceAccount(walletType, "");
  };

  return (
    <Modal
      className="modal-connect-wallet"
      isOpen={isOpened}
      toggle={toggleModal}
    >
      <div className="center-modal">
        <div className="connect-modal-link-wallet">
          <div
            onClick={() => toggleModal()}
            className="close-btn d-flex justify-content-end"
          >
            <img src={x} alt="close modal" />
          </div>
          <div className="modal-connect d-flex align-items-center justify-content-start connect-wallet-title">
            Connected wallet
          </div>
          <CopyAddress
            address={linkedAccounts.metamask}
            fontClass="wallet-address-font"
          />
          <div className="connect-btn-grad">
            {/* <Stack direction="row" pb={1} alignItems="center">
              <div className="network">Mainnet</div>
              <NetworkSwitch
                checked={Boolean(isMainnet)}
                onChange={(e) => changeNetwork()}
              />
            </Stack> */}

            <MetamaskButton
              connected={linkedAccounts.metamask !== ""}
              onConnect={connectMetamask}
              onDisconnect={disconnectMetamask}
              onRetry={retryMetamask}
              connectionError={connectWalletError}
            />
          </div>
          <Typography style={{ textAlign: "center" }}>
            {connectWalletError ? connectWalletError : ""}
          </Typography>
          <div className="modal-divider"></div>
          {/* Other Wallets */}
          {/* <div className="d-flex align-items-center justify-content-start other-wallets-title">
            Your Wallets
          </div> */}
          {/* <div className="other-wallet-description">
            Bring your assets together
          </div> */}
          {/* Main Net */}
          {/* <div className="connect-btn-grad">
            <div className="d-flex align-items-center">
              <img
                src={wallet}
                alt="sequence mainnet"
                className="mr-11 d-flex align-items-center"
              />
              <span className="d-flex align-items-center other-wallet-name">
                Sequence Wallet
                <span className="other-wallet-network">Mainnet</span>
              </span>
            </div>

            <CopyAddress
              address={linkedAccounts.sequenceMainnet}
              fontClass="other-address-font"
            />

            <SequenceButton
              connected={linkedAccounts.sequenceMainnet !== ""}
              onConnect={onConnectSequence(config.Wallet.SEQUENCE)}
              onDisconnect={onDisconnectSequence(config.Wallet.SEQUENCE)}
            />
          </div> */}
          {/* Test Net */}
          {/* <div className="connect-btn-grad">
            <div className="d-flex align-items-center">
              <img
                src={wallet}
                alt="sequence testnet"
                className="mr-11 d-flex align-items-center"
              />
              <span className="d-flex align-items-center other-wallet-name">
                Sequence Wallet
                <span className="other-wallet-network">Testnet</span>
              </span>
            </div>

            <CopyAddress
              address={linkedAccounts.sequenceTestnet}
              fontClass="other-address-font"
            />

            <SequenceButton
              connected={linkedAccounts.sequenceTestnet !== ""}
              onConnect={onConnectSequence(config.Wallet.SEQUENCE_TESTNET)}
              onDisconnect={onDisconnectSequence(
                config.Wallet.SEQUENCE_TESTNET
              )}
            />
          </div> */}
        </div>
      </div>
    </Modal>
  );
}

export function SequenceLinkWalletModal({ isOpened, toggleModal }) {
  const {
    connectWallet,
    connectWalletError,
    disconnectWallet,
    walletAddress,
    sequenceWallet,
    isWalletConnected,
  } = useSequenceWalletConnect();
  const { deactivate } = useWeb3React();

  const [linkedAccounts, setLinkedAccounts] = useState({
    metamask: "",
    sequenceMainnet: "",
    sequenceTestnet: "",
  });

  // Update linked accounts when wallet address changes
  useEffect(() => {
    if (!utils.isAddress(walletAddress)) {
      setLinkedAccounts((prevState) => ({
        ...prevState,
        metamask: "",
      }));
      return;
    }

    const updateSequenceWallets = async (metamaskAddress) => {
      try {
        const { sequenceMain, sequenceTest } = await getLinkedWallets(
          metamaskAddress
        );

        setLinkedAccounts({
          metamask: metamaskAddress,
          sequenceMainnet: sequenceMain ?? "",
          sequenceTestnet: sequenceTest ?? "",
        });
      } catch {
        setLinkedAccounts((prevState) => ({
          ...prevState,
          metamask: metamaskAddress,
        }));
      }
    };

    updateSequenceWallets(walletAddress);
  }, [walletAddress]);

  /**
   * Connects to Metamask
   */
  const connectMetamask = async () => {
    sessionStorage.wallet = 2;
    await connectWallet(config.Wallet.METAMASK);
  };

  /**
   * Disconnects from Metamask
   */
  const disconnectMetamask = async () => {
    await disconnectWallet(config.Wallet.METAMASK);
    deactivate();
  };

  /**
   * Retries to connect to Metamask.
   * If network already exists, it will switch to it.
   * If network doesn't exist, it will add it.
   */
  const retryMetamask = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${config.NetId.toString(16)}` }],
        })
        .catch(() => {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [config.NetConfig],
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  /**
   * Helper function to set linked Sequence account.
   * @param {"sequence" | "sequence_testnet"} walletType
   * @param {string} address
   */
  const setSequenceAccount = (walletType, address) => {
    if (walletType === "sequence") {
      setLinkedAccounts({
        ...linkedAccounts,
        sequenceMainnet: address,
      });
    } else if (walletType === "sequence_testnet") {
      console.log("Setting sequence testnet account to: ", address);
      setLinkedAccounts({
        ...linkedAccounts,
        sequenceTestnet: address,
      });
    }
  };

  /**
   * Callback for when user clicks on "Connect" button for Sequence wallet.
   * @param {"sequence" | "sequence_testnet"} walletType
   */
  const onConnectSequence = (walletType) => async () => {
    sessionStorage.wallet = 0;

    const walletTypeNumber = walletType === "sequence" ? 1 : 2;

    await connectWallet(walletType, {
      keepWalletOpened: true,
    });

    // await getChallenge(
    //   linkedAccounts.metamask,
    //   walletTypeNumber,
    //   sequenceWallet
    // );
    // setSequenceAccount(walletType, await sequenceWallet.getAddress());
    // sequenceWallet.closeWallet();
  };

  /**
   * Callback for when user clicks on "Disconnect" button for Sequence wallet.
   * @param {"sequence" | "sequence_testnet"} walletType
   */
  const onDisconnectSequence = (walletType) => async () => {
    await disconnectWallet(walletType);
    setSequenceAccount(walletType, "");
  };

  return (
    <Modal
      className="modal-connect-wallet"
      isOpen={isOpened}
      toggle={toggleModal}
    >
      <div className="center-modal">
        <div className="connect-modal-link-wallet">
          <div
            onClick={() => toggleModal()}
            className="close-btn d-flex justify-content-end"
          >
            <img src={x} alt="close modal" />
          </div>
          <div className="modal-connect d-flex align-items-center justify-content-start connect-wallet-title">
            Connected wallet
          </div>

          <div className="connect-btn-grad">
            <CopyAddress
              address={walletAddress}
              fontClass="other-address-font"
            />

            <SequenceOnlyButton
              connected={isWalletConnected}
              onConnect={onConnectSequence(
                config.NetId === 80001
                  ? config.Wallet.SEQUENCE_TESTNET
                  : config.Wallet.SEQUENCE
              )}
              onDisconnect={onDisconnectSequence(
                config.NetId === 80001
                  ? config.Wallet.SEQUENCE_TESTNET
                  : config.Wallet.SEQUENCE
              )}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function CopyAddress(props) {
  const [copied, setCopied] = useState(false);

  return props.address ? (
    <div className="d-flex align-items-center align-content-center text-align-center">
      <h3 className={`mb-0 ${props.fontClass}`}>
        {shortenAddress(props.address)}
      </h3>
      <img
        src={copy}
        alt="copy address"
        className="cursor-pointer"
        onClick={async () => {
          setCopied(true);
          await copyTextToClipboard(props.address);
        }}
      />
      {copied && <div style={{ marginLeft: "1em" }}>Copied</div>}
    </div>
  ) : (
    <div className="d-none" />
  );
}

function MetamaskButton(props) {
  if (!props.connected) {
    if (props.connectionError) {
      return (
        <ButtonBase onClick={props.onRetry}>
          <span className="d-flex align-items-center">
            <img
              src={metamask}
              alt="metamask button"
              style={{ marginRight: "10px" }}
            />
            <h3 className="disconnect-btn-font">Retry Connect</h3>
          </span>
        </ButtonBase>
      );
    } else {
      return (
        <ButtonBase onClick={props.onConnect}>
          <span className="d-flex align-items-center">
            <img
              src={metamask}
              alt="metamask button"
              style={{ marginRight: "10px" }}
            />
            <h3 className="disconnect-btn-font">Connect</h3>
          </span>
        </ButtonBase>
      );
    }
  }

  return (
    <ButtonBase onClick={props.onDisconnect}>
      <span className="d-flex align-items-center">
        <img
          src={metamask}
          alt="metamask button"
          style={{ marginRight: "10px" }}
        />
        <h3 className="disconnect-btn-font">Disconnect</h3>
      </span>
    </ButtonBase>
  );
}

function SequenceButton(props) {
  if (!props.connected) {
    return (
      <ButtonBase style={{ marginTop: "20px" }} onClick={props.onConnect}>
        Add Wallet
      </ButtonBase>
    );
  }

  return (
    <ButtonBase style={{ marginTop: "20px" }} onClick={props.onDisconnect}>
      Remove
    </ButtonBase>
  );
}

function SequenceOnlyButton(props) {
  if (!props.connected) {
    return (
      <ButtonBase style={{ marginTop: "20px" }} onClick={props.onConnect}>
        <div className="d-flex align-items-center">
          <img
            src={wallet}
            alt="sequence testnet"
            className="mr-11 d-flex align-items-center"
          />
          <span className="d-flex align-items-center other-wallet-name">
            Connect
          </span>
        </div>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase style={{ marginTop: "20px" }} onClick={props.onDisconnect}>
      <div className="d-flex align-items-center">
        <img
          src={wallet}
          alt="sequence testnet"
          className="mr-11 d-flex align-items-center"
        />
        <span className="d-flex align-items-center other-wallet-name">
          Disconnect
        </span>
      </div>
    </ButtonBase>
  );
}

function ButtonBase(props) {
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #952af4 0%, #f3a157 100%)",
        borderRadius: "9px",
        padding: "1px",
        ...props.style,
      }}
    >
      <Button
        onClick={props.onClick}
        className="connect-btn"
        style={{
          borderRadius: "9px",
          padding: "4px 8px",
        }}
      >
        <h3 className="add-wallet-font">{props.children}</h3>
      </Button>
    </div>
  );
}
