import { get, post, put, del } from "../../helpers/api.helper"



export const AllItems = (url,params,data) => get(url,params,data);

export const AllHunters = (url,params,data) => get(url,params,data);

export const getAllListings = (url,params,data) => get(url,params,data);

export const AllSaleHunters = (url,params,data) => get(url,params,data);

export const Hunteritem = (url,params,data) => get(url,params,data);

export const Artifactitem = (url,params,data) => get(url,params,data);

export const Equpmentitem = (url,params,data) => get(url,params,data);

// export const getUserDetail = (url) => get(url);

// export const createUser = (url, data) => post(url, data);

// export const updateUser = (url, data) => put(url, data);

// export const deleteUser = (url) => del(url);