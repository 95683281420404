import React, { useState } from "react";
import "rc-slider/assets/index.css";
import Level from "../../components/sidebar/Level";
import Rarity from "../../components/sidebar/Rarity";
import Stats from "../../components/sidebar/Stats";
import RentType from "../../components/sidebar/RentType";
import { useSelector } from "react-redux";
import SearchText from "../../components/sidebar/SearchText";

const SidebarAssets = (params, setparams, paramsobj) => {
  const [rent, setRent] = useState([0, 50]);

  const [RentTypeOpen, setRentTypeOpen] = useState(true);
  const [LevelOpen, setLevelOpen] = useState(true);
  const [RarityOpen, setRarityOpen] = useState(true);
  const [StatsOpen, setStatsOpen] = useState(true);

  const playable = useSelector((state) => state.Items.playable);

  return (
    <div>
      <SearchText />

      <RentType
        RentTypeOpen={RentTypeOpen}
        setRentTypeOpen={setRentTypeOpen}
        params={params}
        setparams={setparams}
        paramsobj={paramsobj}
        rent={rent}
        setrent={setRent}
      />

      {playable && <Level LevelOpen={LevelOpen} setLevelOpen={setLevelOpen} />}

      <Rarity RarityOpen={RarityOpen} setRarityOpen={setRarityOpen} />

      <Stats StatsOpen={StatsOpen} setStatsOpen={setStatsOpen} />
    </div>
  );
};

export default SidebarAssets;
