/* eslint-disable no-unused-vars */

import React, { useState } from "react";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "rc-slider/assets/index.css";

import Level from "../../components/sidebar/Level";
import Rarity from "../../components/sidebar/Rarity";
import Stats from "../../components/sidebar/Stats";
import x from "../../assets/images/x.svg";
import { ResetAllFilters } from "../../store/Items/actions";
import { useDispatch } from "react-redux";
import ProfileFilter from "../../components/sidebar/ProfileFilter";
import RentFilters from "../../components/sidebar/RentFilters";

const SidebarMobileRent = (params, setparams, paramsobj) => {
  const dispatch = useDispatch();

  const [LevelOpen, setLevelOpen] = useState(false);
  const [RarityOpen, setRarityOpen] = useState(false);
  const [StatsOpen, setStatsOpen] = useState(false);
  const [ProfileFilterOpen, setProfileFilterOpen] = useState(false);
  const [RentFiltersOpen, setRentFiltersOpen] = useState(false);

  const resetallhandler = () => {
    const data = {
      level: { min: 0, max: 9 },
      hp: { min: 60, max: 450 },
      range: { min: 0, max: 5 },
      attack: { min: 0, max: 2 },
      speed: { min: 0, max: 2 },
      mvt: { min: 0, max: 2 },
      damage: { min: 0, max: 35 },
      commontrue: 1,
      uncommontrue: 1,
      raretrue: 1,
      epictrue: 1,
      legendarytrue: 1,
      hideMyAssetsTrue: 1,
      lentTrue: 1,
      borrowedTrue: 1,
      pricePerDay: null,
      revenueShare: null,
    };
    dispatch(ResetAllFilters(data));
  };

  return (
    <Modal
      isOpen={params.FilterOpen}
      toggle={params.toggleFilter}
      className="Sidebar-Mobile"
    >
      <div className="filterheader d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Filters</h4>
        <img
          className="btn-close-modal"
          src={x}
          alt="close"
          onClick={params.toggleFilter}
        />
      </div>
      <ModalBody className="filterbody">
        <div>
          <div>
            <RentFilters
              RentFiltersOpen={RentFiltersOpen}
              setRentFiltersOpen={setRentFiltersOpen}
            />
            <ProfileFilter
              ProfileFilterOpen={ProfileFilterOpen}
              setProfileFilterOpen={setProfileFilterOpen}
            />
            <Level LevelOpen={LevelOpen} setLevelOpen={setLevelOpen} />
            <div className="grad-border-bottom"></div>
            <Rarity RarityOpen={RarityOpen} setRarityOpen={setRarityOpen} />

            <div className="grad-border-bottom"></div>
            <Stats StatsOpen={StatsOpen} setStatsOpen={setStatsOpen} />

            <div className="grad-border-bottom"></div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="filterfooter">
        <div className="resetfilter-btn-grad">
          <Button onClick={resetallhandler} className="reset-btn">
            <span>Reset all</span>
          </Button>
        </div>
        <div className="Applyfilter-btn-grad">
          <Button onClick={params.toggleFilter} className="Apply-btn">
            <span>Apply</span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SidebarMobileRent;
