/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import Cardpage from "./Cardpage";
import Artifactscard from "./Artifactscard";
import { loaderonoff } from "../../store/Items/actions";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const ProductCard = ({ Seemorenumbuers, pageType }) => {
  pageType = pageType ?? "my-assets";

  const allhunters = useSelector((state) => state.Items.allhunters);

  const dispatch = useDispatch();


  const [assetsItems, setAssetsItems] = useState([]);

  useEffect(() => {
    if (allhunters?.length >= 0) {
      let hunter = allhunters?.map((item) => {
        return { ...item, itemType: "hunter" };
      });
    
      setAssetsItems(hunter);
      dispatch(loaderonoff(false));
    }
  }, [allhunters, dispatch]); // if this line gonna blow up, delete the dispatch

  return (
    <>
      {assetsItems &&
        // eslint-disable-next-line array-callback-return
        assetsItems?.slice(0, Seemorenumbuers).map((m, key) => {
          switch (m?.itemType) {
            case "hunter": {
              let pageUrl = "/my-assets/hunter/";
              if (pageType === "my-assets") {
                pageUrl = "/my-assets/hunter/";
              } else if (pageType === "rent") {
                pageUrl = "/rent/hunter/";
              }

              return (
                <Col key={key} sm={12} md={6} lg={6} xl={4} xxl={4}>
                  <Link to={`${pageUrl}${m?.tokenId}`}>
                    <Cardpage m={m} />
                  </Link>
                </Col>
              );
            }
            case "artifact":
              return (
                <Col key={key} sm={12} md={6} lg={6} xl={4} xxl={4}>
                  <Link to={`/assetmanagement/artifact/${m?.tokenId}`}>
                    <Artifactscard m={m} name={"ARTIFACT"} />
                  </Link>
                </Col>
              );
            case "equipment":
              return (
                <Col key={key} sm={12} md={6} lg={6} xl={4} xxl={4}>
                  <Link to={`/assetmanagement/equipment/${m?.tokenId}`}>
                    <Artifactscard m={m} name={"EQUIPMENT"} />
                  </Link>
                </Col>
              );
            case "shard":
              return (
                <Col key={key} sm={12} md={6} lg={6} xl={4} xxl={4}>
                  <Link to={`/assetmanagement/shard/${m?.tokenId}`}>
                    <Artifactscard m={m} name={"SHARDS"} />
                  </Link>
                </Col>
              );
          }
        })}
      {/* {data && data?.slice(0, Seemorenumbuers).map((m, i) =>
        <Col key={i} sm={12} md={6} lg={6} xl={4} xxl={4} >
          <Link to={`/assetmanagement/hunter/${m?.details?.nftId}`} >
            <Cardpage m={m} />
          </Link>
        </Col>
      )}
      {artifact && artifact?.slice(0, Seemorenumbuers).map((m, i) =>
        <Col key={i} sm={12} md={6} lg={6} xl={4} xxl={4} >
          <Link to={`/assetmanagement/artifact/${m?.tokenId}`} >
            <Artifactscard m={m} name={'ARTIFACT'} />
          </Link>
        </Col>
      )}
      {equipment && equipment?.slice(0, Seemorenumbuers).map((m, i) =>
        <Col key={i} sm={12} md={6} lg={6} xl={4} xxl={4} >
          <Artifactscard m={m} name={'EQUIPMENT'} />
        </Col>
      )}
      {shard && shard?.slice(0, Seemorenumbuers).map((m, i) =>
        <Col key={i} sm={12} md={6} lg={6} xl={4} xxl={4} >
          <Artifactscard m={m} name={'SHARDS'} />
        </Col>
      )} */}
    </>
  );
};

export default ProductCard;
