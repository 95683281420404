/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

/**
 * @file rental.js
 * @description Holds rental contract functions
 * @author SenatorMaul
 */

import { utils, ethers } from "ethers";
import { CHAIN_ID, CONTRACT_ADDRESSES } from "./chainConfig";
import Config, { BASE_URL } from "../config";
import {
  GET_NONCE,
  POST_HUNTER_LENDING_OFFER,
  POST_CANCEL_HUNTER_LENDING_OFFER,
  POST_HUNTER_LENDING_FULFILL,
  GET_LENDING_SIGNATURE,
} from "../helpers/url.helper";

// import getWallet from "./sequenceWallet";

// const listingType = Object.freeze({
//   Hunter: 0,
//   Artifact: 1,
//   Equipment: 2,
//   Shard: 3,
// });

/**
 * @description Signs a marketplace listing
//  * @param {import("0xsequence").Wallet} wallet
 * @param {number} chainId For Mumbai testnet, use CHAIN_ID, for Polygon mainnet, use 137
 * @param {string} lenderAddress The address of the marketplace contract
 * @param {number} hunterId The ID of the hunter
 * @param {number} pricePerDay The price of the listing per day
 * @param {number} revenueSharePercentage The revenue share percentage
 * @param {number} minDays The minimum number of days for the listing
 * @param {number} maxDays The maximum number of days for the listing
 */

async function signHunterLendingOffer(
  lenderAddress,
  chainId,
  contractAddress,
  hunterId,
  pricePerDay,
  revenueSharePercentage,
  minLendingDay,
  maxLendingDay,
  expiresAt,
  signer
) {
  const eip712Domain = {
    name: "Boomland Lending",
    version: "1",
    chainId: chainId,
    verifyingContract: contractAddress,
  };

  const eip712Types = {
    EIP712Lending: [
      { name: "owner", type: "address" },
      { name: "hunterId", type: "uint256" },
      { name: "dailyPrice", type: "uint256" },
      { name: "revenueShare", type: "uint16" },
      { name: "minLendingDay", type: "uint16" },
      { name: "maxLendingDay", type: "uint16" },
      { name: "expiryTimestamp", type: "uint48" },
      { name: "nonce", type: "uint256" },
    ],
  };

  const BOOM_DECIMALS = 2;
  const boomPrice = utils.parseUnits(String(pricePerDay), BOOM_DECIMALS);
  const hunterLendingContract = new ethers.Contract(
    Config.Contract.hunterLending.address,
    Config.Contract.hunterLending.abi,
    signer
  );

  const nonce = await hunterLendingContract.nonces(hunterId);

  const eip712Values = {
    owner: lenderAddress,
    hunterId,
    dailyPrice: boomPrice,
    revenueShare: revenueSharePercentage,
    minLendingDay,
    maxLendingDay,
    expiryTimestamp: expiresAt,
    nonce,
  };
  const signature = await signer.signTypedData(
    eip712Domain,
    eip712Types,
    eip712Values,
    { chainId, eip6492: true }
  );

  return {
    signature,
    values: eip712Values,
  };
}

/**
 * @description Rent a hunter on marketplace
 * @param {string} lenderAddress
 * @param {number} hunterId
 * @param {number} pricePerDay
 * @param {number} revenueSharePercentage
 * @param {number} minDays
 * @param {number} maxDays
 */

export async function createHunterLendingOffer(
  lenderAddress,
  hunterId,
  pricePerDay,
  revenueSharePercentage,
  minDays,
  maxDays,
  expiresAt,
  signer
) {
  try {
    const { signature, values } = await signHunterLendingOffer(
      lenderAddress,
      CHAIN_ID,
      CONTRACT_ADDRESSES[CHAIN_ID].LENDING,
      hunterId,
      pricePerDay,
      revenueSharePercentage,
      minDays,
      maxDays,
      expiresAt,
      signer
    );

    const { resultCode, message, response } = await fetch(
      BASE_URL + POST_HUNTER_LENDING_OFFER,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lenderAddress,
          hunterId,
          pricePerDay,
          revenueSharePercentage,
          minDays,
          maxDays,
          expiresAt: values.expiryTimestamp,
          nonce: +values.nonce,
          signature,
        }),
      }
    ).then((response) => response.json());

    if (resultCode == 0)
      return {
        success: false,
        error: message,
      };

    return {
      success: true,
      data: response,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
      error: e.message,
    };
  }
}

/// ================== Cancel Hunter Lending Offer ================== ///

/**
 * @description Cancel a hunter lending offer
 * @param {number} lendingId
 **/

export async function cancelOffer(
  lendingId,
  lenderAddress,
  hunterId,
  pricePerDay,
  revenueSharePercentage,
  minDays,
  maxDays,
  expiresAt,
  nonce,
  signer
) {
  try {
    const BOOM_DECIMALS = 2;
    const boomPrice = utils.parseUnits(String(pricePerDay), BOOM_DECIMALS);

    const LendingContract = new ethers.Contract(
      Config.Contract.hunterLending.address,
      Config.Contract.hunterLending.abi,
      signer
    );

    const currentNonce = await LendingContract.nonces(hunterId);
    const nonceIsRevoked = +currentNonce !== nonce;

    if (!nonceIsRevoked) {
      const {
        response: { signature: studioSignature },
      } = await fetch(BASE_URL + GET_LENDING_SIGNATURE + `/${lendingId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());

      const revokeNonceData = LendingContract.interface.encodeFunctionData(
        "revokeNonce",
        [
          [
            lenderAddress,
            hunterId,
            boomPrice,
            revenueSharePercentage,
            minDays,
            maxDays,
            expiresAt,
            nonce,
          ],
          studioSignature,
        ]
      );

      const revokeTx = {
        to: LendingContract.address,
        data: revokeNonceData,
      };

      await signer.sendTransaction(revokeTx);
    }

    const { resultCode, message, response } = await fetch(
      BASE_URL + POST_CANCEL_HUNTER_LENDING_OFFER,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lendingId,
        }),
      }
    ).then((response) => response.json());

    if (resultCode == 0)
      return {
        success: false,
        error: message,
      };

    return {
      success: true,
      data: response,
    };
  } catch (e) {
    return {
      success: false,
      error: e.message,
    };
  }
}

/// ================== Borrow Hunter Lending Offer ================== ///

export async function borrowHunterLendingOffer(
  lendingId,
  lenderAddress,
  hunterId,
  pricePerDay,
  revenueSharePercentage,
  minDays,
  maxDays,
  borrowDays,
  expiresAt,
  nonce,
  signer,
  approveTx
) {
  try {
    if (borrowDays <= 0) {
      console.error("Borrowed days can't be less than or equal 0");
      return;
    }

    const {
      response: { signature: studioSignature },
    } = await fetch(BASE_URL + GET_LENDING_SIGNATURE + `/${lendingId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());

    const LendingContract = new ethers.Contract(
      Config.Contract.hunterLending.address,
      Config.Contract.hunterLending.abi,
      signer
    );

    const BOOM_DECIMALS = 2;
    const boomPrice = utils.parseUnits(String(pricePerDay), BOOM_DECIMALS);

    const lendingData = LendingContract.interface.encodeFunctionData(
      "borrowWithSignature",
      [
        [
          lenderAddress,
          hunterId,
          boomPrice,
          revenueSharePercentage,
          minDays,
          maxDays,
          expiresAt,
          nonce,
        ],
        studioSignature,
        borrowDays,
      ]
    );

    const lendingTx = {
      to: LendingContract.address,
      data: lendingData,
    };

    const txs = approveTx ? [approveTx, lendingTx] : [lendingTx];
    const transactionResult = await signer.sendTransaction(txs);
    const transactionReceipt = await transactionResult.wait(2);

    const transactionHash = transactionReceipt.transactionHash;

    const { resultCode, message, response } = await fetch(
      BASE_URL + POST_HUNTER_LENDING_FULFILL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ transactionHash }),
      }
    ).then((response) => response.json());

    if (resultCode == 0) {
      console.log("Call failed", message);
    } else {
      console.log("Listing added", response);
    }
  } catch (e) {
    console.error(e);
    return {
      success: false,
      error: e.message,
    };
  }
}
