/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import getWallet from "../chain/sequenceWallet";
import Address from "./Getaddress";
import config from "../config";
import { flushSync } from "react-dom";
import { getCookie } from "typescript-cookie";

const LOCAL_ADDRESS_KEY = "adress";

/** @type { import("0xsequence").sequence.provider.ConnectOptions } */
const SEQUENCE_CONNECT_OPTS = {
  keepWalletOpened: false,
  app: "Boomland",
  networkId: 137,
  authorize: true,
  settings: {
    theme: "goldDark",
    bannerUrl:
      "https://www.boomland.io/static/media/homeThumbnail.37e22084c408bc384b17.jpg",
    includedPaymentProviders: ["moonpay", "ramp"],
    defaultFundingCurrency: "matic",
    lockFundingCurrencyToDefault: false,
  },
  refresh: true,
};

/** @type { import("0xsequence").sequence.provider.ConnectOptions } */
const SEQUENCE_TESTNET_CONNECT_OPTS = {
  keepWalletOpened: false,
  app: "Boomland testnet",
  networkId: 80001,
  authorize: true,
  settings: {
    theme: "goldDark",
    bannerUrl:
      "https://www.boomland.io/static/media/homeThumbnail.37e22084c408bc384b17.jpg",
    includedPaymentProviders: ["moonpay", "ramp"],
    defaultFundingCurrency: "matic",
    lockFundingCurrencyToDefault: false,
  },
  refresh: true,
};

/**
 * @description Handles the connection to Sequence Wallet
 * @returns {Object} connect, disconnect, sequenceWallet, address, isConnected
 */
export function useSequence(testnet = false) {
  const [sequenceWallet, setSequenceWallet] = useState(null);
  const [address, setAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [userAddresses, setUserAddresses] = useState({
    mainnet: "",
    testnet: "",
    metamask: "",
  });

  useEffect(() => {
    const wallet = getWallet();
    setSequenceWallet(wallet);

    console.log("[SEQUENCE] Wallet initialized in hook", wallet);
  }, []);

  useEffect(() => {
    const storedAddress = Address();
    if (storedAddress != null) {
      setAddress(storedAddress);
      setIsConnected(true);
      setSequenceWallet(getWallet());
    } else {
      setAddress(null);
      setIsConnected(false);
    }

    return () => {
      // console.log("This will reconnect");
    };
  }, []);

  /**
   * Connects Sequence wallet
   * @param { import("0xsequence").sequence.provider.ConnectOptions } options
   */
  async function connect(testnet = false, options = {}) {
    const verbose = true;
    const isMainNetwork = true;

    if (verbose) console.log("[SEQUENCE] Connection attempt");

    if (!isMainNetwork) {
      console.log("[SEQUENCE] Connecting to testnet");
      await sequenceWallet.connect({
        ...SEQUENCE_TESTNET_CONNECT_OPTS,
        ...options,
      });
    } else {
      console.log("[SEQUENCE] Connecting to mainnet");
      await sequenceWallet.connect({ ...SEQUENCE_CONNECT_OPTS, ...options });
    }

    const address = await sequenceWallet.getAddress();

    if (testnet === true) {
      setUserAddresses({ ...userAddresses, testnet: address });
    } else {
      setUserAddresses({ ...userAddresses, mainnet: address });
    }
    localStorage.setItem(LOCAL_ADDRESS_KEY, address);

    if (verbose) console.log("[SEQUENCE] Wallet connected", address);

    setAddress(address);
    setIsConnected(true);
  }

  /**
   * Disconnects Sequence wallet
   */
  async function disconnectSequence(type) {
    // console.log("[SEQUENCE] Disconnection attempt");
    if (sequenceWallet == null) return;

    sequenceWallet.disconnect();
    setAddress(null);
    setIsConnected(false);
    localStorage.removeItem(LOCAL_ADDRESS_KEY);
    if (type === config.Wallet.SEQUENCE) {
      setUserAddresses({ ...userAddresses, mainnet: "" });
    } else if (type === config.Wallet.SEQUENCE_TESTNET) {
      setUserAddresses({ ...userAddresses, testnet: "" });
    }
  }

  /**
   * Refreshes a connected Sequence wallet
   */
  async function refresh() {
    if (sequenceWallet == null) return;
    // console.log("[SEQUENCE] Wallet isn't initialized, returning");

    await sequenceWallet.connect(SEQUENCE_CONNECT_OPTS);
    const address = await sequenceWallet.getAddress();
    setAddress(address);
    setIsConnected(true);
    localStorage.setItem(LOCAL_ADDRESS_KEY, address);
  }

  async function close() {
    if (sequenceWallet == null) return;

    sequenceWallet.close();
  }

  return {
    connect,
    disconnectSequence,
    refresh,
    sequenceWallet,
    address,
    isConnected,
    userAddresses,
    setUserAddresses,
  };
}
