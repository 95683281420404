import { useEffect } from "react";
import { PassportProvider, usePassport } from "../../context/PassportProvider";

export function CallbackComp() {
  const { passport } = usePassport();

  useEffect(() => {
    if (passport) passport.loginCallback();
  }, [passport]);

  return <div>Loading...</div>;
}

const Callback = () => (
  <PassportProvider>
    <CallbackComp />
  </PassportProvider>
);
export default Callback;
