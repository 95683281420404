//USER
// export const GET_USERS = "/api/v1/user?Offset=1&Limit=10"
// export const GET_USER_DETAIL = "/api/v1/user/"
// export const CREATE_USER = "/api/v1/user"
// export const UPDATE_USER = "/api/v1/user/"
// export const DELETE_USER = "/api/v1/user/"

import { BASE_URL } from "../config";

export const GET_ALL_GAMES = "/game";
export const GET_ITEMS = (address) => `/player/${address}/filteredItems`;
export const GET_HUNTERS = (address) => `/player/${address}/filteredHunters`;
export const GET_BALANCES = (address) => `/player/${address}/getBalances`;
export const GET_DETAILS = (address) => `/player/${address}/getDetails`;

export const GET_HUNTER_BY_ID = (id) => `/hunter/${id}/details`;
export const GET_ARTIFACT_BY_ID = (id) => `/artifact/${id}`;
export const GET_EQUPMENT_BY_ID = (id) => `/equipment/${id}`;
export const GET_SHARD_BY_ID = (id) => `/shard/${id}`;

export const GET_CHEST_HUNTER = `/chest/hunter`;
export const GET_CHEST_COUNT = (address) => `chest/count?account=${address}`;

// Marketplace API
export const GET_SALE_HUNTERS = "/marketplace/sales";
export const GET_LISTING_BY_ID = "/marketplace/listing";
export const GET_ALL_LISTINGS = "/marketplace/listings";
export const POST_HUNTER_LISTINGS = "/marketplace/addHunterListing";
export const POST_HUNTER_LENDING_OFFER =
  "/marketplace/hunterLending/createOffer";
export const GET_LENDING_SIGNATURE =
  "/marketplace/hunterLending/lendingSignature";
export const POST_HUNTER_LENDING_FULFILL =
  "/marketplace/hunterLending/fulfillOffer";
export const POST_CANCEL_HUNTER_LENDING_OFFER =
  "/marketplace/hunterLending/cancelOffer";
export const GET_HUNTER_LENDING_OFFERS =
  "/marketplace/hunterLending/filteredHunters";
export const GET_NONCE = "/marketplace/nonce";
export const POST_ARTIFACT_LISTINGS = "/marketplace/addArtifactListing";
export const POST_EQUIPMENT_LISTINGS = "/marketplace/addEquipmentListing";
export const POST_SHARD_LISTINGS = "/marketplace/addShardListing";

// Summon API
export const GET_SUMMON_ALL_RECIPES = "/hunter/summon/recipes";
export const GET_SUMMON_OWNED_HUNTERS = (address) =>
  `/hunter/summon/ownedHunters?account=${address}`;
export const GET_PLAYER_EQUIPMENTS = (address) =>
  `/player/${address}/getEquipments`;
export const GET_SUMMON_RARITY_CHANCES = "/hunter/summon/rarityChances";
export const POST_SUMMON = "/hunter/summon";
export const POST_CLAIM_SUMMON = "/hunter/summon/claim";
export const GET_ACTIVE_SUMMONS = (address) =>
  `/hunter/summon/activeSummoning?account=${address}`;
export const POST_SIGN_SUMMON = "/recipe/signSummon";
export const GET_CAN_SUMMON = (address) =>
  `/recipe/canSummon?account=${address}`;

// Chest API
export const GET_CHEST_IDS = (address) => `/chest/items?account=${address}`;
export const POST_HUNTER = `/chest/hunter`;

export const getUrl = `${BASE_URL}/auth/walletLinkChallenge`;
export const fulfillUrl = `${BASE_URL}/auth/walletLinkFulfill`;
