//GET
export const GET_ITEMS = "GET_ITEMS";
export const GET_HUNTER_BY_ID = "GET_HUNTER_BY_ID";
export const GET_HUNTER_BY_ID_FAIL = "GET_HUNTER_BY_ID_FAIL";
export const GET_ARTIFACT_BY_ID = "GET_ARTIFACT_BY_ID";
export const GET_ARTIFACT_BY_ID_FAIL = "GET_ARTIFACT_BY_ID_FAIL";
export const GET_EQUIPMENT_BY_ID = "GET_EQUIPMENT_BY_ID";
export const GET_EQUIPMENT_BY_ID_FAIL = "GET_EQUIPMENT_BY_ID_FAIL";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_HUNTERS = "GET_HUNTERS";
export const GET_HUNTERS_FAIL = "GET_HUNTERS_FAIL";
export const GET_HUNTER_LENDING_OFFERS = "GET_HUNTER_LENDING_OFFERS";
export const GET_HUNTER_LENDING_OFFERS_FAIL = "GET_HUNTER_LENDING_OFFERS_FAIL";
export const PLAYABLE = "PLAYABLE";
export const ITEMS = "ITEMS";
export const COMMON_TRUE = "COMMON_TRUE";
export const UNCOMMON_TRUE = "UNCOMMON_TRUE";
export const RARE_TRUE = "RARE_TRUE";
export const EPIC_TRUE = "EPIC_TRUE";
export const LEGENDARY_TRUE = "LEGENDARY_TRUE";
export const HIDE_MY_ASSETS = "HIDE_MY_ASSETS";
export const LOADER = "LOADER";
export const LENT_TRUE = "LENT_TRUE";
export const BORROWED_TRUE = "BORROWED_TRUE";
export const GET_SALE_HUNTERS_FAIL = "GET_SALE_HUNTERS_FAIL";
export const GET_SALE_HUNTERS = "GET_SALE_HUNTERS";
export const GET_ALL_LISTINGS = "GET_ALL_LISTINGS";
export const GET_ALL_LISTINGS_FAIL = "GET_ALL_LISTINGS_FAIL";
export const GET_SHARD_ITEM = "GET_SHARD_ITEM";
export const GET_SHARD_ITEM_FAIL = "GET_SHARD_ITEM_FAIL";
export const LIST_HUNTER = "LIST_HUNTER";
export const LIST_HUNTER_FAIL = "LIST_HUNTER_FAIL";
export const SET_SALE = "SET_SALE";
export const SET_LEVEL = "SET_LEVEL";
export const SET_REVENUE_SHARE = "SET_REVENUE_SHARE";
export const SET_PRICE_PER_DAY = "SET_PRICE_PER_DAY";
export const SET_DAMAGE = "SET_DAMAGE";
export const SET_HP = "SET_HP";
export const SET_SPEED = "SET_SPEED";
export const SET_MVT = "SET_MVT";
export const SET_RANGE = "SET_RANGE";
export const SET_ATTACK = "SET_ATTACK";
export const GET_LISTING_BY_ID_FAIL = "GET_LISTING_BY_ID_FAIL";
export const GET_LISTING_BY_ID = "GET_LISTING_BY_ID";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_ORDER = "SET_ORDER";
export const INCREMENT_PENDING_HUNTERS = "INCREMENT_PENDING_HUNTERS";
export const SET_PENDING_HUNTERS = "SET_PENDING_HUNTERS";
export const RESET_PENDING_HUNTERS = "RESET_PENDING_HUNTERS";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
