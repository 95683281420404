/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "rc-slider/assets/index.css";

import Level from "../../components/sidebar/Level";
import Rarity from "../../components/sidebar/Rarity";
import Stats from "../../components/sidebar/Stats";
import BoomPower from "../../components/sidebar/BoomPower";
import x from "../../assets/images/x.svg";
import { ResetAllFilters } from "../../store/Items/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const SidebarMobile = (params, setparams, paramsobj) => {
  const FilterItems = useSelector((state) => state.Items);

  const dispatch = useDispatch();

  const [level, setlevel] = useState([1, 10]);
  const [rarity, setrarity] = useState([0, 5]);
  const [stats, setstats] = useState([0, 15]);
  const [boomPower, setboomPower] = useState([0, 50]);
  const [range, setrange] = useState([0, 5]);
  const [attack, setattack] = useState([0, 50]);
  const [speed, setspeed] = useState([0, 50]);
  const [mvt, setmvt] = useState([0, 50]);
  const [hp, sethp] = useState([60, 200]);

  useEffect(() => {
    setlevel([
      Number(FilterItems?.level?.min) + 1,
      Number(FilterItems?.level?.max) + 1,
    ]);
    sethp([FilterItems?.hp?.min, FilterItems?.hp?.max]);
    setmvt([FilterItems?.mvt?.min, FilterItems?.mvt?.max]);
    setspeed([FilterItems?.speed?.min, FilterItems?.speed?.max]);
    setattack([FilterItems?.attack?.min, FilterItems?.attack?.max]);
    setrange([FilterItems?.range?.min, FilterItems?.range?.max]);
    setstats([FilterItems?.damage?.min, FilterItems?.damage?.max]);
  }, [FilterItems]);

  const [LevelOpen, setLevelOpen] = useState(false);
  const [RarityOpen, setRarityOpen] = useState(false);
  const [StatsOpen, setStatsOpen] = useState(false);
  const [BoomPowerOpen, setBoomPowerOpen] = useState(false);

  const resetallhandler = () => {
    const data = {
      level: { min: 0, max: 9 },
      hp: { min: 60, max: 450 },
      range: { min: 0, max: 5 },
      attack: { min: 0, max: 2 },
      speed: { min: 0, max: 2 },
      mvt: { min: 0, max: 2 },
      damage: { min: 0, max: 35 },
      commontrue: 1,
      uncommontrue: 1,
      raretrue: 1,
      epictrue: 1,
      legendarytrue: 1,
      hideMyAssetsTrue: 1,
      lentTrue: 1,
      borrowedTrue: 1,
    };
    dispatch(ResetAllFilters(data));
  };

  return (
    <Modal
      isOpen={params.FilterOpen}
      toggle={params.toggleFilter}
      className="Sidebar-Mobile"
    >
      <div className="filterheader d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Filters</h4>
        <img
          className="btn-close-modal"
          src={x}
          alt="close"
          onClick={params.toggleFilter}
        />
      </div>
      <ModalBody className="filterbody">
        <div>
          <div>
            <Level
              level={level}
              setlevel={setlevel}
              LevelOpen={LevelOpen}
              setLevelOpen={setLevelOpen}
            />
            <div className="grad-border-bottom"></div>
            <Rarity
              rarity={rarity}
              setrarity={setrarity}
              RarityOpen={RarityOpen}
              setRarityOpen={setRarityOpen}
              params={params}
              setparams={setparams}
              paramsobj={paramsobj}
            />
            <div className="grad-border-bottom"></div>
            <Stats
              StatsOpen={StatsOpen}
              setStatsOpen={setStatsOpen}
              hp={hp}
              sethp={sethp}
              mvt={mvt}
              setmvt={setmvt}
              speed={speed}
              setspeed={setspeed}
              attack={attack}
              setattack={setattack}
              stats={stats}
              setstats={setstats}
              range={range}
              setrange={setrange}
            />
            <div className="grad-border-bottom"></div>
            <BoomPower
              boomPower={boomPower}
              setboomPower={setboomPower}
              BoomPowerOpen={BoomPowerOpen}
              setBoomPowerOpen={setBoomPowerOpen}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="filterfooter">
        <div className="resetfilter-btn-grad">
          <Button onClick={resetallhandler} className="reset-btn">
            <span>Reset all</span>
          </Button>
        </div>
        <div className="Applyfilter-btn-grad">
          <Button onClick={params.toggleFilter} className="Apply-btn">
            <span>Apply</span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SidebarMobile;
