import {
  GET_CHEST_HUNTER,
  GET_CHEST_HUNTER_FAIL,
  GET_CHEST_COUNT,
  GET_CHEST_COUNT_FAIL,
  RESET_CHEST_COUNT,
  DECREASE_CHEST_COUNT,
} from "./actionTypes";

const INIT_STATE = {
  ChestHunter: {},
  ChestHunterFail: {},
  Chestcount: 0,
  ChestcountFail: {},
};

const storeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHEST_HUNTER:
      return {
        ...state,
        ChestHunter: action.payload,
      };

    case GET_CHEST_HUNTER_FAIL:
      return {
        ...state,
        ChestHunterFail: action.payload,
      };
    case GET_CHEST_COUNT:
      return {
        ...state,
        Chestcount: action.payload,
      };

    case GET_CHEST_COUNT_FAIL:
      return {
        ...state,
        ChestcountFail: action.payload,
      };

    case RESET_CHEST_COUNT:
      return {
        ...state,
        Chestcount: 0,
      };

    case DECREASE_CHEST_COUNT:
      const { chestType } = action.payload;
      const updatedChestCount = state.Chestcount;
      updatedChestCount['tier' + (chestType ?? 1)]--;

      return {
        ...state,
        Chestcount: updatedChestCount,
      };
    default:
      return state;
  }
};

export default storeReducer;
