import React from "react";
import { Col, Row } from "reactstrap";
import $BOOM2 from "../../assets/images/$BOOM2.svg";
import $BOOM3 from "../../assets/images/$BOOM3.svg";
import Rectangle from "../../assets/images/Rectangle.svg";
import greenstat from "../../assets/images/greenstat.svg";
import play_to_earn4 from "../../assets/images/play-to-earn4.svg";
import info from "../../assets/images/info.svg";
import downErrow from "../../assets/images/downErrow.svg";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import Changecoinupper from "../../components/Dropdown/Changecoinupper";
import { DefiDashboard } from "../../components/Graphs/DefiDashboard";

const Defi = () => {
  return (
    <>
      <div className="container-x">
        <div className="sortbar">
          <h3>Dashboard</h3>
        </div>
        <span className="border-b" />
        <div className="mt-30">
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-dash-bg-grad">
                <div className="defi-dash-bg">
                  <div className="defi-icons-margin defi-price-card">
                    <div>
                      <p>BOOM PRICE</p>
                      <div className="defi-sale-private-flex">
                        <h5 className="mb-0">$0.12</h5>
                        <div className="private-sale-btn">PRIVATE SALE</div>
                      </div>
                    </div>
                    <div>
                      <img src={$BOOM2} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-dash-bg-grad">
                <div className="defi-dash-bg">
                  <div className="defi-icons-margin defi-price-card">
                    <div>
                      <p>BGEM PRICE</p>
                      <div className="BGEM-price defi-sale-private-flex">
                        <h5 className="mb-0">$0.10</h5>
                        <img className="ml-5 greenstat-img" src={greenstat} />
                      </div>
                    </div>
                    <div>
                      <img className="Rectangle-defi-img" src={Rectangle} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-dash-bg-grad">
                <div className="defi-dash-bg">
                  <div className="defi-icons-margin  defi-price-card ">
                    <div>
                      <p>TOTAL MARKET CAP</p>
                      <div className="defi-sale-private-flex">
                        <h5 className="mb-0">$90,250,00.12</h5>
                      </div>
                    </div>
                    <div>
                      <img className="play_to_earn4" src={play_to_earn4} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-30">
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="defi-cards-bg">
                <div className="defi-card-header d-flex justify-content-between align-items-center">
                  <p>BUY $BOOM</p>
                  <img src={info} />
                </div>
                <div className="">
                  <div className="position-relative swapdefi-coin">
                    <div className="uper-coin d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <input
                          className="uper-coin-input"
                          value="1000.00"
                          disabled
                        />
                        <span className="coinmax">Max</span>
                      </div>
                      <div>
                        <Changecoinupper />
                      </div>
                    </div>
                    <div className="swaper-coin">
                      <img src={downErrow} />
                    </div>
                  </div>

                  <div className="swapdefi-coin">
                    <div className="uper-coin d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <input
                          className="uper-coin-input"
                          value="120.00"
                          disabled
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <img className="boom-img-coin" src={$BOOM3} />
                        <p>BOOM</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="buy-btn-defi">BUY</div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={8} xl={8}>
              <DefiDashboard />
            </Col>
          </Row>
        </div>
        <div className="defi-footer">
          <div className="defi-footer-content">
            <a href="https://discord.com/invite/boomland" target="_blank">
              <img src={discord} />
            </a>
            <a href="https://t.me/BoomlandAnnouncements" target="_blank">
              <img src={teligram} />
            </a>
            <a href="https://twitter.com/BoomLandGames" target="_blank">
              <img src={twitter} />
            </a>
            <a href="https://boomlandgames.medium.com/" target="_blank">
              <img src={medium} />
            </a>
          </div>
        </div>
        <div className="defi-copright">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Defi;
