import React from "react";
import RoadmapSwiperTimeline from "./RoadmapSwiperTimeline";
import roadmapIcon from "../../assets/images/roadmap-25.png";

class Roadmap extends React.Component {
  render() {
    return (
      <section className="section section-home section-roadmap">
        <div className="container">
          <div className="row section-header">
            <div className="col-lg-12 col-10 offset-1 offset-lg-0">
              <h2 className="section-title">Boomland 2024 Roadmap</h2>
            </div>
          </div>
        </div>
        <div className="container d-block d-lg-none">
          <div className="row">
            <div className="col-12">
              <div className="d-block">
                <div
                  id="roadmap-timeline"
                  className="roadmap-timeline"
                  data-fill={50}
                >
                  <div
                    id="roadmap-timeline-fill"
                    className="roadmap-timeline-fill"
                    style={{
                      width: "25%",
                    }}
                  />
                  <div className="roadmap-timeline-sq roadmap-timeline-sq-1" />
                  <div className="roadmap-timeline-sq roadmap-timeline-sq-2" />
                  <div className="roadmap-timeline-sq roadmap-timeline-sq-3" />
                  <div className="roadmap-timeline-sq roadmap-timeline-sq-4" />
                  <div
                    id="roadmap-timeline-cursor"
                    className="roadmap-timeline-cursor"
                    style={{
                      left: "25%",
                    }}
                  />
                  <div
                    id="roadmap-timeline-cursor-dot"
                    className="roadmap-timeline-cursor-dot"
                    style={{
                      left: "25%",
                    }}
                  />
                </div>
                <RoadmapSwiperTimeline></RoadmapSwiperTimeline>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-none d-lg-block">
          <div className="flex flex-col justify-center">
            <div className="col-12">
              <img className="mb-5 pb-3" src={roadmapIcon} />
            </div>
            <div className="flex flex-row justify-between px-2">
              <div className="self-center justify-center">
                <h3 className="mb-3 text-3xl font-bold ml-6">Q1 2024</h3>

                <ul className="mb-5 text-lg">
                  <li className="mb-1">New Chain Announcement</li>
                  <li className="mb-1">Marketing Collaboration Campaign</li>
                  <li className="mb-1">Revised Rewards for Hardcore Hunt</li>
                  <li className="mb-1">Clan War Event</li>
                  <li className="mb-1">Artifact Upcycling</li>
                  <li className="mb-1">Implementing Community Suggestions</li>
                  <li className="mb-1">Co-Op Mode</li>
                  <li>$BOOM Listing</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mb-3 text-3xl font-bold ml-6">Q2 2024</h3>

                <ul className="text-lg mb-5">
                  <li className="mb-1">Move to New Chain</li>
                  <li className="mb-1">Burn Mechanics</li>
                  <li className="mb-1">Summoning</li>
                  <li className="mb-1">Rental Whitelisting</li>
                  <li className="mb-1">
                    Implementing Community Suggestions (cont.)
                  </li>
                  <li className="mb-1">New and Additional Leaderboards</li>
                  <li className="mb-1">Seasonal In-Game Events</li>
                  <li>T3 Chests</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mb-3 text-3xl font-bold ml-6">H2 2024</h3>

                <ul className="text-lg mb-5">
                  <li className="mb-1">Season Pass</li>
                  <li className="mb-1">New Hunters</li>
                  <li className="mb-1">New Missions</li>
                  <li className="mb-1">Duel Mode</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Roadmap;
