/* eslint-disable no-dupe-keys */

import {
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_HUNTERS,
  GET_HUNTERS_FAIL,
  GET_HUNTER_LENDING_OFFERS,
  GET_HUNTER_LENDING_OFFERS_FAIL,
  PLAYABLE,
  ITEMS,
  COMMON_TRUE,
  UNCOMMON_TRUE,
  RARE_TRUE,
  EPIC_TRUE,
  LEGENDARY_TRUE,
  LOADER,
  LENT_TRUE,
  BORROWED_TRUE,
  GET_HUNTER_BY_ID,
  GET_HUNTER_BY_ID_FAIL,
  GET_ARTIFACT_BY_ID,
  GET_ARTIFACT_BY_ID_FAIL,
  GET_EQUIPMENT_BY_ID,
  GET_EQUIPMENT_BY_ID_FAIL,
  GET_SALE_HUNTERS,
  GET_SALE_HUNTERS_FAIL,
  GET_ALL_LISTINGS,
  GET_ALL_LISTINGS_FAIL,
  GET_SHARD_ITEM,
  GET_SHARD_ITEM_FAIL,
  LIST_HUNTER_FAIL,
  LIST_HUNTER,
  SET_SALE,
  SET_LEVEL,
  SET_DAMAGE,
  SET_SPEED,
  SET_MVT,
  SET_RANGE,
  SET_HP,
  SET_ATTACK,
  GET_LISTING_BY_ID,
  GET_LISTING_BY_ID_FAIL,
  SET_SEARCH_STRING,
  SET_ORDER,
  SET_ORDER_BY,
  INCREMENT_PENDING_HUNTERS,
  RESET_PENDING_HUNTERS,
  SET_PENDING_HUNTERS,
  RESET_ALL_FILTERS,
  HIDE_MY_ASSETS,
  SET_REVENUE_SHARE,
  SET_PRICE_PER_DAY,
} from "./actionTypes";

const INIT_STATE = {
  allitems: [],
  allListings: [],
  listHunter: {},
  listHunterFail: {},
  hunteritem: [],
  hunteritemfail: [],
  onSale: false,
  shardItem: [],
  shardItemfail: [],
  artifactitem: [],
  artifactitemfail: [],
  equpmentitem: [],
  equpmentitemfail: [],
  allhunters: [],
  listingDataById: {},
  listingDataByIdFail: {},
  allitemserror: {},
  allhunterserror: {},
  allListingserror: {},
  playable: true,
  onSale: "",
  level: {
    min: 0,
    max: 9,
  },
  hp: {
    min: 1,
    max: 450,
  },
  range: {
    min: 0,
    max: 5,
  },
  attack: {
    min: 0,
    max: 2,
  },
  speed: {
    min: 0,
    max: 2,
  },
  mvt: {
    min: 0,
    max: 2,
  },
  damage: {
    min: 0,
    max: 35,
  },
  searchString: "",
  orderBy: "",
  order: "",
  items: true,
  commontrue: 1,
  uncommontrue: 1,
  raretrue: 1,
  epictrue: 1,
  legendarytrue: 1,
  loaderon: false,
  pendingHunters: 0,
  lentTrue: 1,
  borrowedTrue: 1,
  hideMyAssetsTrue: 1,
};

const itemReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        allitems: action.payload,
      };
    case GET_ITEMS_FAIL:
      return {
        ...state,
        allitemserror: action.payload,
      };
    case GET_HUNTERS:
      return {
        ...state,
        allhunters: action.payload,
      };
    case GET_HUNTERS_FAIL:
      return {
        ...state,
        allhunterserror: action.payload,
      };
    case GET_HUNTER_LENDING_OFFERS:
      return {
        ...state,
        allhunters: action.payload,
      };
    case GET_HUNTER_LENDING_OFFERS_FAIL:
      return {
        ...state,
        allhunterserror: action.payload,
      };

    case GET_SALE_HUNTERS:
      return {
        ...state,
        allhunters: action.payload,
      };
    case GET_SALE_HUNTERS_FAIL:
      return {
        ...state,
        allhunterserror: action.payload,
      };

    case GET_SHARD_ITEM:
      return {
        ...state,
        shardItem: action.payload,
      };
    case GET_SHARD_ITEM_FAIL:
      return {
        ...state,
        shardItemfail: action.payload,
      };

    case GET_LISTING_BY_ID:
      return {
        ...state,
        listingDataById: action.payload,
      };

    case GET_LISTING_BY_ID_FAIL:
      return {
        ...state,
        listingDataByIdFail: action.payload,
      };

    case LIST_HUNTER:
      return {
        ...state,
        listHunter: action.payload,
      };
    case LIST_HUNTER_FAIL:
      return {
        ...state,
        listHunterFail: action.payload,
      };

    case GET_ALL_LISTINGS:
      return {
        ...state,
        allListings: action.payload,
      };
    case GET_ALL_LISTINGS_FAIL:
      return {
        ...state,
        allListingserror: action.payload,
      };

    case PLAYABLE:
      return {
        ...state,
        playable: action.payload,
      };
    case ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case COMMON_TRUE:
      return {
        ...state,
        commontrue: action.payload,
      };
    case UNCOMMON_TRUE:
      return {
        ...state,
        uncommontrue: action.payload,
      };
    case RARE_TRUE:
      return {
        ...state,
        raretrue: action.payload,
      };
    case EPIC_TRUE:
      return {
        ...state,
        epictrue: action.payload,
      };
    case LEGENDARY_TRUE:
      return {
        ...state,
        legendarytrue: action.payload,
      };
    case HIDE_MY_ASSETS:
      return {
        ...state,
        hideMyAssetsTrue: action.payload,
      };
    case LOADER:
      return {
        ...state,
        loaderon: action.payload,
      };
    case LENT_TRUE:
      return {
        ...state,
        lentTrue: action.payload,
      };
    case BORROWED_TRUE:
      return {
        ...state,
        borrowedTrue: action.payload,
      };
    case GET_HUNTER_BY_ID:
      return {
        ...state,
        hunteritem: action.payload,
      };
    case GET_HUNTER_BY_ID_FAIL:
      return {
        ...state,
        hunteritemfail: action.payload,
      };
    case GET_ARTIFACT_BY_ID:
      return {
        ...state,
        artifactitem: action.payload,
      };
    case GET_ARTIFACT_BY_ID_FAIL:
      return {
        ...state,
        artifactitemfail: action.payload,
      };
    case GET_EQUIPMENT_BY_ID:
      return {
        ...state,
        equpmentitem: action.payload,
      };
    case GET_EQUIPMENT_BY_ID_FAIL:
      return {
        ...state,
        equpmentitemfail: action.payload,
      };

    case SET_SALE:
      return {
        ...state,
        onSale: action.payload,
      };

    case SET_LEVEL:
      return {
        ...state,
        level: action.payload,
      };

    case SET_REVENUE_SHARE:
      return {
        ...state,
        revenueShare: action.payload,
      };

    case SET_PRICE_PER_DAY:
      return {
        ...state,
        pricePerDay: action.payload,
      };

    case SET_DAMAGE:
      return {
        ...state,
        damage: action.payload,
      };
    case SET_HP:
      return {
        ...state,
        hp: action.payload,
      };

    case SET_SPEED:
      return {
        ...state,
        speed: action.payload,
      };
    case SET_MVT:
      return {
        ...state,
        mvt: action.payload,
      };

    case SET_RANGE:
      return {
        ...state,
        range: action.payload,
      };

    case SET_ATTACK:
      return {
        ...state,
        attack: action.payload,
      };

    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };

    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      };

    case INCREMENT_PENDING_HUNTERS:
      return {
        ...state,
        pendingHunters: state.pendingHunters + 1,
      };

    case SET_PENDING_HUNTERS:
      return {
        ...state,
        pendingHunters: action.payload,
      };

    case RESET_PENDING_HUNTERS:
      return {
        ...state,
        pendingHunters: 0,
      };

    case RESET_ALL_FILTERS:
      return {
        ...state,
        level: action.payload?.level,
        hp: action.payload?.hp,
        range: action.payload?.range,
        attack: action.payload?.attack,
        speed: action.payload?.speed,
        mvt: action.payload?.mvt,
        damage: action.payload?.damage,
        commontrue: action.payload?.commontrue,
        uncommontrue: action.payload?.uncommontrue,
        raretrue: action.payload?.raretrue,
        epictrue: action.payload?.epictrue,
        legendarytrue: action.payload?.legendarytrue,
        lentTrue: action.payload?.lentTrue,
        borrowedTrue: action.payload?.borrowedTrue,
        hideMyAssetsTrue: action.payload?.hideMyAssetsTrue,
        pricePerDay: action.payload?.pricePerDay,
        revenueShare: action.payload?.revenueShare,
      };

    default:
      return state;
  }
};

export default itemReducer;
