import React from "react";
import { Col, Row } from "reactstrap";
import $BOOM2 from "../../assets/images/$BOOM2.svg";
import $BOOM3 from "../../assets/images/$BOOM3.svg";
import Rectangle from "../../assets/images/Rectangle.svg";
import greenstat from "../../assets/images/greenstat.svg";
import Ether from "../../assets/images/Ether.svg";
import info from "../../assets/images/info.svg";
import downErrow from "../../assets/images/downErrow.svg";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import Changecoinupper from "../../components/Dropdown/Changecoinupper";
import { StakingDashboard } from "../../components/Graphs/StakingDashboard";

const Staking = () => {
  return (
    <>
      <div className="container-x">
        <div className="sortbar">
          <h3>Your Stakings</h3>
        </div>
        <span className="border-b" />
        <div className="mt-30">
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="defi-cards-bg">
                <div className="swaper-headding d-flex justify-content-between align-items-center">
                  <p>BUY $BOOM</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <img width={33} src={$BOOM2} />
                    <img width={33} src={Rectangle} />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex justify-content-between justify-content-center">
                    <p>Your poll tokens</p>
                    <p>594.65</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>Pooled BOOM</p>
                    <p>120.00 BOOM</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>Pooled BGEM</p>
                    <p>1,000.00 BGEM</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>Your pool share:</p>
                    <p dangerouslySetInnerHTML={{ __html: "<0.15%" }}></p>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="buy-btn-defi">Add</div>
                </div>
                <div className="mt-4">
                  <div className="remove-btn-defi">REMOVE</div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={8} xl={8}>
              <StakingDashboard />
            </Col>
          </Row>
        </div>
        <div className="display-bar">
          <h3>Active Pools</h3>
        </div>
        <div className="mt-30">
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-cards-bg">
                <div className="mx-4 swaper-headding d-flex justify-content-between align-items-center">
                  <p>BUY $BOOM</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <img width={33} src={$BOOM2} />
                  </div>
                </div>
                <div className="mt-4 mx-4">
                  <div className="d-flex justify-content-between justify-content-center">
                    <p>TVL</p>
                    <p>$120,153,874.64</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APR</p>
                    <p>(10% + 20%) = 30%</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APY</p>
                    <p>25%</p>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="buy-btn-defi">Add</div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-cards-bg">
                <div className="mx-4 swaper-headding d-flex justify-content-between align-items-center">
                  <p>BUY $BOOM</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <img width={33} src={$BOOM2} />
                    <img width={33} src={Rectangle} />
                  </div>
                </div>
                <div className="mt-4 mx-4">
                  <div className="d-flex justify-content-between justify-content-center">
                    <p>TVL</p>
                    <p>$120,153,874.64</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APR</p>
                    <p>(10% + 20%) = 30%</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APY</p>
                    <p>30%</p>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="buy-btn-defi">Add</div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="mt-3 mt-lg-0 defi-cards-bg">
                <div className="mx-4 swaper-headding d-flex justify-content-between align-items-center">
                  <p>BUY $BOOM</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <img width={33} src={$BOOM2} />
                    <img width={33} src={Ether} />
                  </div>
                </div>
                <div className="mt-4 mx-4">
                  <div className="d-flex justify-content-between justify-content-center">
                    <p>TVL</p>
                    <p>$120,153,874.64</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APR</p>
                    <p>(10% + 20%) = 30%</p>
                  </div>
                  <div className="mt-2 d-flex justify-content-between justify-content-center">
                    <p>APY</p>
                    <p>25%</p>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="buy-btn-defi">Add</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="defi-footer">
          <div className="defi-footer-content">
            <a href="https://discord.com/invite/boomland" target="_blank">
              <img src={discord} />
            </a>
            <a href="https://t.me/BoomlandAnnouncements" target="_blank">
              <img src={teligram} />
            </a>
            <a href="https://twitter.com/BoomLandGames" target="_blank">
              <img src={twitter} />
            </a>
            <a href="https://boomlandgames.medium.com/" target="_blank">
              <img src={medium} />
            </a>
          </div>
        </div>
        <div className="defi-copright">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Staking;
