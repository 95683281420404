/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

// imgs
import energy from "../../assets/images/energy.png";
import Damage from "../../assets/images/Damage.png";
import HP from "../../assets/images/HP.png";
import Range from "../../assets/images/Range.png";
import Attack from "../../assets/images/Attack.png";
import Speed from "../../assets/images/Speed.png";
import Mvt from "../../assets/images/Mvt.png";
import $BOOM from "../../assets/images/$BOOM.svg";

// shard imgs
import shard0 from "../../assets/images/shard-0.svg";
import shard1 from "../../assets/images/shard-1.svg";
import shard2 from "../../assets/images/shard-2.svg";
import shard3 from "../../assets/images/shard-3.svg";
import shard4 from "../../assets/images/shard-4.svg";
import logo from "../../assets/home/icon.svg";

// mui icon
import CloseIcon from "@mui/icons-material/Close";
import { Add, Remove } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//mui
import {
  Dialog,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
// redux
import {
  getHunterbyid,
  getListingDataById,
  loaderonoff,
} from "../../store/Items/actions";

import { GET_ARTIFACT_BY_ID } from "../../helpers/url.helper";
// config
import Config, { BASE_URL, attackArray, rangeArray } from "../../config";
import {
  createHunterLendingOffer,
  borrowHunterLendingOffer,
} from "../../chain/rental";
import { cancelOffer } from "../../chain/rental";
import { useSequenceWalletConnect } from "../../context";
import { useWeb3React } from "@web3-react/core";
import { SequenceLinkWalletModal } from "../../components/Modal/LinkWalletModal";
import config from "../../config";
import getWallet from "./../../chain/sequenceWallet";
import { getUpgradedProperty } from "../../helpers/functions.helper";

const AssetHunter = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Get Hunter by id
  const [m, setM] = useState();

  // Get Artifacts by id
  const [artifacts, setArtifacts] = useState([]);

  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openRentModal, setOpenRentModal] = useState(false);
  const [connectWalletmodal, setconnectWalletmodal] = useState(false);
  const [open, setOpen] = useState(false);

  const [isApproved, setApproved] = useState(false);
  const [lendingPrice, setLendingPrice] = useState(100);
  const [lendingId, setLendingId] = useState(0);
  const [itemOwner, setItemOwner] = useState("");
  const hunteritem = useSelector((state) => state.Items.hunteritem);
  const [connectedSigner, setSigner] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const { library } = useWeb3React();
  const {
    isWalletConnected,
    connectedWallet,
    boomBalance,
    getBoomBalance,
    walletAddress,
  } = useSequenceWalletConnect();

  const [genesisArtifactId, setGenesisArtifactId] = useState(false);

  // Get Artifacts useEffect

  const getArtifact = async (id) => {
    const response = await fetch(BASE_URL + GET_ARTIFACT_BY_ID(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());

    return response?.response?.typeName;
  };

  const getArtifacts = async (
    permanentPerk1,
    permanentPerk2,
    permanentPerk3,
    permanentPerk4
  ) => {
    console.log("getArtifacts called");
    const artifact1 = permanentPerk1 ? await getArtifact(permanentPerk1) : null;
    const artifact2 = permanentPerk2 ? await getArtifact(permanentPerk2) : null;
    const artifact3 = permanentPerk3 ? await getArtifact(permanentPerk3) : null;
    const artifact4 = permanentPerk4 ? await getArtifact(permanentPerk4) : null;

    return [
      { id: permanentPerk1, name: artifact1 },
      { id: permanentPerk2, name: artifact2 },
      { id: permanentPerk3, name: artifact3 },
      { id: permanentPerk4, name: artifact4 },
    ].filter((e) => e.id);
  };

  const imgid = m?.generatedName?.slice(0, 3);
  const imgg = (perk) => {
    return require(`../../assets/asset-images/Perks_Numbered/a${perk}.png`);
  };

  // for Spinner

  const [isSubmitting, setIsSubmitting] = useState(false);
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;

  const updateHunterDetails = async () => {
    if (hunteritem == null) return;
    if (hunteritem.nftId != id) return;

    const { permanentPerk1, permanentPerk2, permanentPerk3, permanentPerk4 } =
      hunteritem;

    const artifactIds = [
      permanentPerk1,
      permanentPerk2,
      permanentPerk3,
      permanentPerk4,
    ];
    const artifacts = await getArtifacts(...artifactIds);
    setArtifacts(artifacts);
    const genesisArtifactItemId =
      artifacts.find((artifact) => [45024, 45023].includes(artifact.id))?.id ??
      null;
    setGenesisArtifactId(genesisArtifactItemId);

    if (hunteritem?.lendingId) {
      const pricePerDay =
        typeof hunteritem?.lendingPricePerDay === "number"
          ? hunteritem?.lendingPricePerDay
          : lendingPrice;
      const revenueShare =
        typeof hunteritem?.lendingRevenueShare === "number"
          ? hunteritem?.lendingRevenueShare
          : share;
      const lendingMinDays =
        typeof hunteritem?.lendingMinDays === "number"
          ? hunteritem?.lendingMinDays
          : minRentalPeriod;
      const lendingMaxDays =
        typeof hunteritem?.lendingMaxDays === "number"
          ? hunteritem?.lendingMaxDays
          : maxRentalPeriod;

      setLendingId(hunteritem?.lendingId);
      setLendingPrice(pricePerDay);
      setShare(revenueShare);

      if (lendingMinDays <= lendingMaxDays) {
        setMinRentalPeriod(lendingMinDays);
        setMaxRentalPeriod(lendingMaxDays);
      } else {
        setMinRentalPeriod(lendingMaxDays);
        setMaxRentalPeriod(lendingMaxDays);
      }
    }
  };

  useEffect(() => {
    updateHunterDetails();
  }, [hunteritem]);

  // Revenue Share
  const [share, setShare] = useState(25);

  const handleIncreaseClick = () => {
    setShare((prevShare) => {
      if (prevShare < 100) {
        return Math.min(prevShare + 1, 100);
      }
      return prevShare;
    });
  };

  const handleDecreaseClick = () => {
    setShare((prevShare) => Math.max(prevShare - 1, 0));
  };

  const handleShareChange = (event) => {
    const newValue = event.target.value;
    setShare((prevShare) => {
      if (
        newValue === "" ||
        (Number(newValue) >= 0 && Number(newValue) <= 100)
      ) {
        return newValue === "" ? "" : Number(newValue);
      }
      return prevShare;
    });
  };

  // For multipliers total

  const calculateTotalMultiplier = () => {
    let totalMultiplier = 0;

    // Values are in basis points (1/100 of a percent)
    totalMultiplier += m?.level_multiplier;
    totalMultiplier += m?.rarity_multiplier / 10000;
    totalMultiplier +=
      genesisArtifactId == 45024 ? 100 : genesisArtifactId == 45023 ? 50 : 0;
    return totalMultiplier.toFixed(2);
  };

  // For days validation

  const [validationError, setValidationError] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  // Rental Period

  const [minRentalPeriod, setMinRentalPeriod] = useState(1);
  const [maxRentalPeriod, setMaxRentalPeriod] = useState(30);

  useEffect(() => {
    if (minRentalPeriod > maxRentalPeriod) {
      setMinRentalPeriod(maxRentalPeriod);
    }
  }, [minRentalPeriod, maxRentalPeriod]);

  // Borrow Days

  const [borrowDays, setBorrowDays] = useState(0);

  const listHunterLendingOffer = async () => {
    dispatch(loaderonoff(true));
    try {
      if (!isApproved) await approve();

      const NINETY_DAYS = 90 * 24 * 60 * 60;
      const expiresAt = Math.floor(Date.now() / 1000) + NINETY_DAYS;
      await createHunterLendingOffer(
        walletAddress,
        Number(id),
        Number(lendingPrice),
        Number(share),
        Number(minRentalPeriod),
        Number(maxRentalPeriod),
        expiresAt,
        connectedSigner
      );
      // console.log(res);
      // if list success then reload the page
      dispatch(getHunterbyid(id));
      setOpen(false);
      dispatch(loaderonoff(false));
      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      dispatch(loaderonoff(false));
      setOpen(false);
    }
  };

  const cancelHunterLendingOffer = async () => {
    dispatch(loaderonoff(true));
    try {
      await cancelOffer(
        lendingId,
        walletAddress,
        Number(id),
        Number(lendingPrice),
        Number(share),
        Number(minRentalPeriod),
        Number(maxRentalPeriod),
        hunteritem.lendingExpiresAt,
        hunteritem.lendingNonce,
        connectedSigner
      );
      // if cancel offer success then reload the page
      dispatch(getHunterbyid(id));
      dispatch(loaderonoff(false));
    } catch (e) {
      dispatch(loaderonoff(false));
      setOpen(false);
    }
  };

  // const listingItem = async () => {
  //   dispatch(loaderonoff(true));
  //   try {
  //     if (isApproved) {
  //       const TEN_DAYS = 10 * 24 * 60 * 60;
  //       const expiresAt = Math.floor(Date.now() / 1000) + TEN_DAYS;

  //       const res = await listHunter(
  //         id,
  //         listingPrice,
  //         expiresAt,
  //         connectedSinger,
  //         walletAddress
  //       );
  //       console.log(res);
  //     }
  //   } catch (e) {
  //     dispatch(loaderonoff(false));
  //     setOpen(false);
  //   }
  // };

  const removeItem = async () => {
    setOpenRemoveModal(true);
    // alert("Remove Item!!!");
  };

  // const buyItem = async () => {
  //   alert("Buy Item!!!");
  // };

  const approve = async () => {
    dispatch(loaderonoff(true));
    try {
      const HunterContract = new ethers.Contract(
        Config.Contract.hunter.address,
        Config.Contract.hunter.abi,
        connectedSigner
      );
      const approved = await HunterContract.setApprovalForAll(
        Config.Contract.maketplace.address,
        true
      );
      if (approved) {
        setApproved(true);
        dispatch(loaderonoff(false));
      }
      setOpen(true);
    } catch (error) {
      console.error(error);
      setApproved(false);
      throw error;
    }
  };

  const tokenApprove = async () => {
    console.log("tokenApprove called");
    dispatch(loaderonoff(true));
    try {
      const BoomContract = new ethers.Contract(
        Config.Contract.boom.address,
        Config.Contract.boom.abi,
        connectedSigner
      );

      const approvalAmount = await BoomContract.allowance(
        walletAddress,
        Config.Contract.hunterLending.address
      );

      const approvalThreshold = BigNumber.from(10).pow(2).mul(1_000_000);

      if (approvalAmount.gt(approvalThreshold)) {
        dispatch(loaderonoff(false));
        return;
      }

      const approveData = BoomContract.interface.encodeFunctionData("approve", [
        Config.Contract.hunterLending.address,
        BigNumber.from(2).pow(128),
      ]);

      const approveTx = { to: BoomContract.address, data: approveData };

      dispatch(loaderonoff(false));
      return approveTx;
    } catch (error) {
      console.log(error);
      dispatch(loaderonoff(false));
    }
  };

  // Borrow Item

  const acceptHunterLendingOffer = async (approveTx) => {
    dispatch(loaderonoff(true));

    try {
      console.log("hunteritem", hunteritem);
      await borrowHunterLendingOffer(
        lendingId,
        itemOwner?.toLocaleLowerCase(),
        Number(id),
        Number(lendingPrice),
        Number(share),
        Number(minRentalPeriod),
        Number(maxRentalPeriod),
        Number(borrowDays),
        hunteritem.lendingExpiresAt,
        hunteritem.lendingNonce,
        connectedSigner,
        approveTx
      );
      dispatch(getHunterbyid(id));
      dispatch(loaderonoff(false));
    } catch (error) {
      dispatch(loaderonoff(false));
    }
  };

  const load = async (singer) => {
    try {
      const HunterContract = new ethers.Contract(
        Config.Contract.hunter.address,
        Config.Contract.hunter.abi,
        singer
      );

      const _isApproved = await HunterContract.isApprovedForAll(
        walletAddress,
        Config.Contract.maketplace.address
      );
      const _owner = await HunterContract.ownerOf(id);
      setItemOwner(_owner);
      setApproved(_isApproved);
    } catch (e) {
      console.error(e);
      setApproved(false);
    }
  };

  useEffect(() => {
    // console.log(hunteritem);
    if (connectedWallet === config.Wallet.METAMASK) {
      if (hunteritem && library) {
        load(library?.getSigner(Config.NetId));
        setSigner(library?.getSigner(Config.NetId));
        setM(hunteritem);
        if (hunteritem?.onSale) {
          dispatch(getListingDataById({ id: hunteritem?.listingId }));
        }
      }
    } else {
      const wallet = getWallet();
      const signer = wallet.getSigner(Config.NetId);
      if (hunteritem && signer) {
        setSigner(signer);
        load(signer);
        setM(hunteritem);
        if (hunteritem?.onSale) {
          dispatch(getListingDataById({ id: hunteritem?.listingId }));
        }
      }
    }
  }, [hunteritem, library]);

  useEffect(() => {
    console.log("Dispatching getHunterbyid", id);
    dispatch(getHunterbyid(id));
  }, [id]);

  // Helper Functions & CTAs

  const showConnectWalletModal = () => {
    setconnectWalletmodal(!connectWalletmodal);
  };

  return (
    <div className="container-x">
      <Row className="mx-0 column-direction">
        <Col>
          <Card className="singleproductcard">
            <div
              className={`cardbg-video
                                    ${m?.rarity === 0 && "bg-common"}
                                    ${m?.rarity === 1 && "bg-uncommon"}
                                    ${m?.rarity === 2 && "bg-rare"}
                                    ${m?.rarity === 3 && "bg-epic"}
                                    ${m?.rarity === 4 && "bg-legendary"}
                                    relative d-flex align-items-center justify-content-center`}
            >
              <img
                className="name-logo"
                src={require("../../assets/images/Name-logo.png")}
                alt="Name_logo"
              />
              <img
                className="herovideo"
                src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${m?.generatedName}.gif`}
                style={{ width: "100%" }}
              />

              <div className="energy-bage">
                <img src={energy} alt="energy" />
                <span style={{ color: "white" }}>
                  {m?.remainingDailyHunterLimit}/{m?.maxDailyHunterLimit}
                </span>
              </div>
            </div>
          </Card>

          {/* PROPERTIES SKILLS */}

          <Card className="infocard singleproductcard-short">
            <CardHeader className="singlecard-header">PROPERTIES</CardHeader>
            <CardBody className="single-stats-card">
              <div className="row name-card ">
                <div className="col-4 col-xl-4 stat-direction">
                  <div className="col single-stat d-flex align-items-center">
                    <img src={Damage} alt="Damage" />
                    <div className="statsbadge-info-b">
                      <div className="b-name">Damage</div>
                      <div className="statsbadge-num">
                        {getUpgradedProperty(m?.damage, m?.level)}
                      </div>
                    </div>
                  </div>
                  <div className="col single-stat  d-flex align-items-center">
                    <img src={HP} alt="HP" />
                    <div className="statsbadge-info-b">
                      <div className="b-name">Hp</div>
                      <div className="statsbadge-num">
                        {getUpgradedProperty(m?.hp, m?.level)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-xl-4 stat-direction">
                  <div className="col single-stat d-flex align-items-center">
                    <img src={Range} alt="Range" />
                    <div className="statsbadge-info-b">
                      <div className="b-name">Range</div>
                      <div className="statsbadge-num">
                        {rangeArray[m?.type]}
                      </div>
                    </div>
                  </div>
                  <div className="col single-stat d-flex align-items-center">
                    <img src={Attack} alt="Attack" />
                    <div className="statsbadge-info-b">
                      <div className="b-name">Attack</div>
                      <div className="statsbadge-num">
                        {attackArray[m?.type]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-xl-4 stat-direction">
                  <div className="col single-stat d-flex align-items-center">
                    <img src={Speed} alt="Attack Speed" />
                    <div className="statsbadge-info-b">
                      <div className="b-name">Atk. Speed</div>
                      <div className="statsbadge-num">{m?.attackSpeed}</div>
                    </div>
                  </div>
                  <div className="col single-stat  d-flex align-items-center">
                    <img src={Mvt} alt="Speed" />
                    <div className="statsbadge-info-b">
                      <div className=" b-name">Speed</div>
                      <div className="statsbadge-num">{m?.speed}</div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          {/* ARTIFACTS */}

          {artifacts?.length > 0 && (
            <Card className="infocard singleproductcard-short">
              <CardHeader className="singlecard-header">ARTIFACTS</CardHeader>
              <CardBody className="single-stats-card">
                <div className="Artifacts-content">
                  {artifacts.map((permanentPerk) => {
                    const hasPerk = permanentPerk?.id !== undefined;

                    return (
                      hasPerk && (
                        <div key={permanentPerk.id} className="Artifacts-info">
                          <img src={imgg(permanentPerk.id)} alt="powerbadge" />
                          <p className="mb-0">{permanentPerk.name}</p>
                        </div>
                      )
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col>
          {/* GENERAL INFORMATION */}

          <Card className="infocard namecard">
            <CardBody className="p-0">
              <div className="name-card d-flex align-items-center justify-content-between">
                <div>
                  <p className="mb-0 name">{m?.name}</p>
                  <p
                    className={`mb-0 rarity
                                    ${m?.rarity === 0 && "text-common"}
                                    ${m?.rarity === 1 && "text-uncommon"}
                                    ${m?.rarity === 2 && "text-rare"}
                                    ${m?.rarity === 3 && "text-epic"}
                                    ${m?.rarity === 4 && "text-legendary"}
                                `}
                  >
                    {m?.rarity === 0 && "COMMON"}
                    {m?.rarity === 1 && "UNCOMMON"}
                    {m?.rarity === 2 && "RARE"}
                    {m?.rarity === 3 && "EPIC"}
                    {m?.rarity === 4 && "LEGENDARY"}
                  </p>
                </div>
                <div>
                  <div>
                    <span className="mb-0 nftId text-center d-flex justify-content-center align-items-center">
                      #{m?.nftId}
                    </span>
                  </div>
                  <span className="mb-0 level text-center d-flex justify-content-center align-items-center">
                    LEVEL {m?.level + 1}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>

          {/* Reward Bonuses (WIP FOR DB REAL TIME DATAS) */}

          <Card className="infocard">
            <CardHeader className="singlecard-header d-flex justify-content-between align-items-center">
              <div>REWARD BONUS</div>
              <div>+{calculateTotalMultiplier()}%</div>
            </CardHeader>
            <CardBody className="single-stats-card">
              <div className="">
                {/* <div className="Asset-info d-flex align-items-center justify-content-between">
                  <p className="mb-0">Base Multiplier</p>
                  <p className="mb-0">100%</p>
                </div> */}
                <div className="Asset-info d-flex align-items-center justify-content-between">
                  <p className="mb-0">Level Multiplier</p>
                  <p className="mb-0">+{m?.level_multiplier}%</p>
                </div>
                <div className="Asset-info d-flex align-items-center justify-content-between">
                  <p className="mb-0">Rarity Multiplier</p>
                  <p className="mb-0">
                    +{(m?.rarity_multiplier / 10000).toFixed(2)}%
                  </p>
                </div>

                <div className="Asset-info d-flex align-items-center justify-content-between">
                  <p className="mb-0">Artifact Multiplier</p>
                  <p className="mb-0">
                    {genesisArtifactId == 45024
                      ? "+100%"
                      : genesisArtifactId == 45023
                      ? "+50%"
                      : "+0%"}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>

          <div className="min-h-x"></div>

          {/* Status : LENT (If the item is rented by the user) */}

          {m?.lendingStatus === "lent" && (
            <div>
              <Card className="infocard">
                <CardBody className="second-price-card">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="bid mb-0 status">Revenue Share</p>
                      <p className="mb-0 rent-subtitle">
                        {m?.lendingRevenueShare} %
                      </p>
                    </div>
                    <div>
                      <p className="bid mb-0 status">Cost per Day</p>

                      <div className="d-flex align-items-center justify-content-end">
                        <img
                          src={$BOOM}
                          alt="Boom"
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        <span className="bid-price">
                          {m?.lendingPricePerDay}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="infocard">
                <CardBody className="single-price-card">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="bid mb-0 status">Status</p>
                      <p className="mb-0 rent-subtitle">
                        {walletAddress?.toLocaleLowerCase() ==
                        itemOwner?.toLocaleLowerCase()
                          ? "Rented out"
                          : "Rented"}
                      </p>
                    </div>
                    <div>
                      <p className="bid mb-0 status d-flex justify-content-end">
                        Remaining time
                      </p>

                      <div className="d-flex align-items-center justify-content-end">
                        <span className="bid-price">
                          {m?.lendingDaysLeft} days left
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}

          {m?.lendingStatus === "listed" && (
            <Card className="infocard">
              <CardBody className="single-price-card">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="bid mb-0 status">Revenue Share</p>
                    <p className="text-xs my-1">
                      (sent to the owner of the hunter)
                    </p>

                    <p className="mb-0 rent-subtitle">
                      {m?.lendingRevenueShare} %
                    </p>
                  </div>
                  <div>
                    <p className="bid mb-0 status">Cost per Day</p>

                    <div className="d-flex align-items-center justify-content-end">
                      <img
                        src={$BOOM}
                        alt="Boom"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                      <span className="bid-price">{m?.lendingPricePerDay}</span>
                    </div>
                  </div>
                </div>
                <div>
                  {walletAddress &&
                    itemOwner &&
                    walletAddress.toLocaleLowerCase() !==
                      itemOwner.toLocaleLowerCase() && (
                      <div className="buy-btn-grad w-100">
                        {!isWalletConnected ? (
                          <Button
                            id="btn-connect-wallet-qa"
                            className="default-btn"
                            onClick={showConnectWalletModal}
                          >
                            CONNECT WALLET
                          </Button>
                        ) : (
                          <Button
                            className="buy-btn"
                            onClick={() => setOpenRentModal(true)}
                          >
                            RENT NOW
                          </Button>
                        )}
                      </div>
                    )}
                  {walletAddress &&
                    itemOwner &&
                    walletAddress.toLocaleLowerCase() ===
                      itemOwner.toLocaleLowerCase() && (
                      <div>
                        <div className="mt-4 w-100">
                          <Button
                            className="remove-btn"
                            onClick={() => removeItem()}
                          >
                            REMOVE FROM LISTING
                          </Button>
                        </div>
                      </div>
                    )}

                  {!walletAddress ||
                    (!itemOwner && (
                      <div>
                        <div className="mt-4 w-100">
                          <Button className="buy-btn">-</Button>
                        </div>
                      </div>
                    ))}
                </div>
              </CardBody>
            </Card>
          )}

          {/* Link Wallet Modal Request */}

          {!isWalletConnected && (
            <SequenceLinkWalletModal
              isOpened={connectWalletmodal}
              toggleModal={() => {
                setconnectWalletmodal(!connectWalletmodal);
              }}
            />
          )}

          {/* Rent Button (I'm Owner and not on rent status) */}

          {walletAddress?.toLocaleLowerCase() ==
            itemOwner?.toLocaleLowerCase() &&
            m?.lendingId === "N/A" && (
              <Card className="infocard priceinfo-mobile">
                <CardBody className="single-price-card">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="bid mb-0 status">Status</p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-0 rent-subtitle">Not listed</p>
                    <div className="buy-btn-grad w-100">
                      <Button className="buy-btn" onClick={() => setOpen(true)}>
                        LIST
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
        </Col>
      </Row>

      {/* Remove Modal */}

      <Dialog
        className="list-hunter-modal"
        // fullWidth
        maxWidth="xs"
        open={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
      >
        <Stack>
          <Stack direction={"row"} justifyContent={"end"} alignItems={"end"}>
            <IconButton
              style={{ color: "white" }}
              onClick={() => setOpenRemoveModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction={"column"}
            spacing={3}
            pt={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={logo}
              alt="Logo"
              className="header-logo-image"
              style={{
                width: "150px",
                height: "100px",
              }}
            />
            <Typography
              fontSize={"34px"}
              fontWeight={700}
              lineHeight={"63.72px"}
              className="remove-title"
            >
              Remove from Listing
            </Typography>
            <Typography
              fontSize={"19px"}
              fontWeight={400}
              lineHeight={"35.61px"}
              letterSpacing={"2%"}
              textAlign={"center"}
              px={6}
              className="remove-subtitle"
            >
              Are you sure you want to remove it from listing?
            </Typography>
            <Button
              className="remove-btn py-3"
              onClick={() => {
                setIsSubmitting(true);
                removeItem();
                cancelHunterLendingOffer();
                setOpenRemoveModal(false);
                setIsSubmitting(false);
              }}
            >
              {isSubmitting ? (
                <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
              ) : (
                "REMOVE"
              )}
            </Button>
            <Button
              className="buy-btn py-3"
              onClick={() => setOpenRemoveModal(false)}
            >
              CANCEL
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* Rent Now Modal (Borrow) */}

      <Dialog
        className="list-hunter-modal"
        fullWidth
        maxWidth="xs"
        open={openRentModal}
        onClose={() => setOpenRentModal(false)}
      >
        <Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={"34px"}
              fontWeight={600}
              className="rent-modal-title"
            >
              Rent now
            </Typography>
            <IconButton
              style={{ color: "white" }}
              onClick={() => setOpenRentModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack>
            <p
              className="mb-0 name"
              style={{
                fontSize: "25px",
                lineHeight: "37.5px",
                fontWeight: "700",
              }}
            >
              {m?.name}
            </p>
            <p
              className="mb-0"
              style={{
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "600",
              }}
            >
              #{m?.nftId}
            </p>
            <p
              style={{ fontSize: "20px" }}
              className={`mb-0 rarity
                                    ${m?.rarity === 0 && "text-common"}
                                    ${m?.rarity === 1 && "text-uncommon"}
                                    ${m?.rarity === 2 && "text-rare"}
                                    ${m?.rarity === 3 && "text-epic"}
                                    ${m?.rarity === 4 && "text-legendary"}
                                `}
            >
              {m?.rarity === 0 && "COMMON"}
              {m?.rarity === 1 && "UNCOMMON"}
              {m?.rarity === 2 && "RARE"}
              {m?.rarity === 3 && "EPIC"}
              {m?.rarity === 4 && "LEGENDARY"}
            </p>
          </Stack>

          <Stack direction={"row"} spacing={3} pt={3} alignItems={"center"}>
            <img
              src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${m?.generatedName}.gif`}
              style={{ width: "100%" }}
            />
          </Stack>

          <Stack spacing={1} pt={3} pb={2}>
            <Typography
              fontWeight={600}
              fontSize={"22px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              Revenue Share
            </Typography>
            <Typography
              fontWeight={300}
              fontSize={"14px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              (sent to the owner of the hunter)
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{
                borderRadius: "10px",
                background: "#413049",
                color: "white",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontSize={"22px"}
                  fontWeight={600}
                  className="rent-modal-subtitles"
                  lineHeight={"30.42px"}
                  fontFamily={"Poppins"}
                >
                  {m?.lendingRevenueShare} %
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Typography
              fontWeight={600}
              fontSize={"22px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              Rental Period
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{
                color: "white",
              }}
            >
              <button
                onClick={() => setBorrowDays(m?.lendingMinDays)}
                className="flex w-full"
              >
                <Box
                  sx={{
                    backgroundColor: "#413049",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rental-period-label"
                    sx={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "33px",
                      backgroundColor: "#2D1C35",
                      borderRadius: "5px",
                      textAlign: "center",
                      margin: "5px 10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                      "@media screen and (max-width: 430px)": {
                        margin: "5px 2px",
                        fontSize: "20px !important",
                      },
                    }}
                  >
                    min
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "37.5px",
                      textAlign: "center",
                      margin: "6px 0 16px 0",
                      "@media screen and (max-width: 430px)": {
                        fontSize: "18px !important",
                      },
                    }}
                  >
                    {`${m?.lendingMinDays} ${
                      m?.lendingMinDays > 1 ? "days" : "day"
                    }`}
                  </Typography>
                </Box>
              </button>
              <button
                onClick={() => setBorrowDays(m?.lendingMaxDays)}
                className="flex w-full"
              >
                <Box
                  sx={{
                    backgroundColor: "#413049",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rental-period-label"
                    sx={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "33px",
                      backgroundColor: "#2D1C35",
                      borderRadius: "5px",
                      textAlign: "center",
                      margin: "5px 10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                      "@media screen and (max-width: 430px)": {
                        margin: "5px 2px",
                        fontSize: "20px !important",
                      },
                    }}
                  >
                    max
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "37.5px",
                      textAlign: "center",
                      margin: "6px 0 16px 0",
                      "@media screen and (max-width: 430px)": {
                        fontSize: "18px !important",
                      },
                    }}
                  >
                    {`${m?.lendingMaxDays} ${
                      m?.lendingMaxDays > 1 ? "days" : "day"
                    }`}
                  </Typography>
                </Box>
              </button>
            </Stack>

            <Stack spacing={1} pb={2}>
              <Typography
                fontWeight={600}
                fontSize={"22px"}
                variant="subtitle1"
                className="rent-modal-subtitles"
              >
                Rent for
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{
                  color: "white",
                }}
              >
                <Select
                  value={borrowDays}
                  onChange={(e) => setBorrowDays(e.target.value)}
                  sx={{
                    minWidth: "120px",
                    color: "white",
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "37.5px",
                    textAlign: "center",
                    backgroundColor: "#000000",
                    borderRadius: "9px",
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {m &&
                    Array.from(
                      { length: m.lendingMaxDays - m.lendingMinDays + 1 },
                      (_, i) => {
                        const days = i + m.lendingMinDays;
                        return (
                          <MenuItem key={days} value={days}>
                            {`${days} ${days > 1 ? "days" : "day"}`}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Typography
              fontWeight={600}
              fontSize={"22px"}
              className="rent-modal-subtitles"
              lineHeight={"30.42px"}
            >
              Total Cost
            </Typography>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: "10px",
                background: "#413049",
                color: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "55px",
                  flexDirection: "row",
                }}
              >
                <img
                  src={$BOOM}
                  alt="Boom"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "5px",
                  }}
                />
                <Typography
                  fontSize={"22px"}
                  fontWeight={600}
                  className="rent-modal-subtitles"
                  lineHeight={"30.42px"}
                  fontFamily={"Poppins"}
                >
                  {m?.lendingPricePerDay * (borrowDays || 0)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          {errorMessage && (
            <span className="self-center text-red-500 mb-1">
              {errorMessage}
            </span>
          )}
          <Button
            className="default-btn"
            size={"lg"}
            disabled={
              !borrowDays ||
              isSubmitting ||
              boomBalance < m?.lendingPricePerDay * (borrowDays || 0)
            }
            onClick={async () => {
              if (!borrowDays || isSubmitting) {
                return;
              }

              setIsSubmitting(true);
              const approveTx = await tokenApprove();
              await acceptHunterLendingOffer(approveTx);
              setIsSubmitting(false);
              setOpenRentModal(false);
              getBoomBalance();
            }}
          >
            {isSubmitting ? (
              <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
            ) : (
              "RENT NOW"
            )}
          </Button>
        </Stack>
      </Dialog>

      {/* List it for Rent Modal */}

      <Dialog
        className="list-hunter-modal"
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={"34px"}
              fontWeight={600}
              className="rent-modal-title"
            >
              List it for Rent
            </Typography>
            <IconButton
              style={{ color: "white" }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack>
            <p
              className="mb-0 name"
              style={{
                fontSize: "25px",
                lineHeight: "37.5px",
                fontWeight: "700",
              }}
            >
              {m?.name}
            </p>
            <p
              className="mb-0"
              style={{
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "600",
              }}
            >
              #{m?.nftId}
            </p>
            <p
              style={{ fontSize: "20px" }}
              className={`mb-0 rarity
                                    ${m?.rarity === 0 && "text-common"}
                                    ${m?.rarity === 1 && "text-uncommon"}
                                    ${m?.rarity === 2 && "text-rare"}
                                    ${m?.rarity === 3 && "text-epic"}
                                    ${m?.rarity === 4 && "text-legendary"}
                                `}
            >
              {m?.rarity === 0 && "COMMON"}
              {m?.rarity === 1 && "UNCOMMON"}
              {m?.rarity === 2 && "RARE"}
              {m?.rarity === 3 && "EPIC"}
              {m?.rarity === 4 && "LEGENDARY"}
            </p>
          </Stack>

          <Stack direction={"row"} spacing={3} pt={3} alignItems={"center"}>
            <img
              src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${m?.generatedName}.gif`}
              style={{ width: "100%" }}
            />
          </Stack>
          <Stack spacing={1} pt={3} pb={2}>
            <Typography
              className="rent-modal-subtitles"
              sx={{
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "30.42px",
              }}
            >
              Cost per Day
            </Typography>

            <Stack
              sx={{
                borderRadius: "10px",
                background: "#11090B",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <OutlinedInput
                type="number"
                value={lendingPrice}
                className="input-cost"
                sx={{
                  "& input": {
                    min: 0,
                    fontWeight: 600,
                    background: "#11090B",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "30px",
                    lineHeight: "45px",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                  background: "#11090B",
                  color: "white",
                  borderRadius: "10px",
                  marginLeft: "135px",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                onChange={(e) =>
                  setLendingPrice(
                    e.target.value > 1000000
                      ? 1000000
                      : Math.abs(e.target.value)
                  )
                }
                startAdornment={
                  <InputAdornment position="center">
                    <img
                      src={$BOOM}
                      alt="Boom"
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </InputAdornment>
                }
              />
            </Stack>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Typography
              fontWeight={600}
              fontSize={"22px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              Revenue Share
            </Typography>
            <Typography
              fontWeight={600}
              fontSize={"14px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              (sent to the owner of the hunter)
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{
                borderRadius: "10px",
                background: "#11090B",
                color: "white",
                paddingX: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={handleDecreaseClick}
                className="btns-revenue"
                sx={{
                  color: "white",
                  bgcolor: "#413049",
                  borderRadius: "5px",
                  padding: "20px",
                  "&:hover": {
                    bgcolor: "#413049",
                    color: "white",
                  },
                }}
              >
                <Remove />
              </IconButton>
              <OutlinedInput
                style={{ marginLeft: "80px" }}
                className="input-revenue"
                type="number"
                value={share}
                onChange={handleShareChange}
                sx={{
                  "& input": {
                    fontWeight: 600,
                    background: "#11090B",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "30px",
                    lineHeight: "45px",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                  "& fieldset": {
                    border: "none",
                  },
                  textAlign: "center",
                  borderRadius: "10px",
                  background: "#11090B",
                  color: "white",
                }}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                startAdornment={<Typography>%</Typography>}
              />
              <IconButton
                size="small"
                className="btns-revenue"
                sx={{
                  color: "white",
                  bgcolor: "#413049",
                  borderRadius: "5px",
                  padding: "20px",
                  marginLeft: "0px !important",
                  "&:hover": {
                    bgcolor: "#413049",
                    color: "white",
                  },
                }}
                onClick={handleIncreaseClick}
              >
                <Add />
              </IconButton>
            </Stack>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Typography
              fontWeight={600}
              fontSize={"22px"}
              variant="subtitle1"
              className="rent-modal-subtitles"
            >
              Rental Period
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{
                color: "white",
              }}
            >
              {["min", "max"].map((label) => (
                <FormControl
                  key={label}
                  sx={{
                    minWidth: "120px",
                    width: "50%",
                    borderRadius: "10px",
                    bgcolor: "#11090B",
                    color: "white",
                  }}
                >
                  <InputLabel
                    className="rental-period-label"
                    sx={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "33px",
                      bgcolor: "#413049",
                      paddingX: "20px",
                      borderRadius: "5px",
                      width: "100%",
                      textAlign: "center",
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    {label}
                  </InputLabel>
                  <Select
                    value={label === "min" ? minRentalPeriod : maxRentalPeriod}
                    onChange={(e) =>
                      label === "min"
                        ? setMinRentalPeriod(e.target.value)
                        : setMaxRentalPeriod(e.target.value)
                    }
                    sx={{
                      minWidth: "120px",
                      color: "white",
                      width: "100%",
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: "600",
                      lineHeight: "37.5px",
                      textAlign: "center",
                      paddingTop: "15px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                      "& .MuiSelect-icon": {
                        color: "white",
                        marginTop: "8px",
                      },
                      "@media screen and (max-width: 430px)": {
                        fontSize: "18px !important",
                      },
                    }}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                  >
                    {[...Array(30)].map((_, i) => {
                      const days = i + 1;
                      return (
                        <MenuItem key={days} value={days}>
                          {days} days
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ))}
            </Stack>
          </Stack>
          <Stack>
            {validationError && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: "red",
                  mt: 1,
                  visibility: validationError ? "visible" : "hidden",
                  transition: "visibility 3s",
                }}
              >
                {validationMessage}
              </Typography>
            )}
          </Stack>
          <Stack>
            {!validationError && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(255, 255, 255, 0.5) !important",
                  mt: 1,
                  visibility: "visible",
                }}
              >
                This listed item will be available for rent for 90 days.
              </Typography>
            )}
          </Stack>

          <Button
            className="remove-btn"
            size={"lg"}
            onClick={() => {
              setIsSubmitting(true);
              listHunterLendingOffer();
            }}
          >
            {isSubmitting ? (
              <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
            ) : (
              "LIST NOW"
            )}
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
};

export default AssetHunter;
