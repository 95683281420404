/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */

/**
 * @file sequenceWallet.js
 * @description Holds the wallet instance
 */

import { CHAIN_NAME } from "./chainConfig";
import { sequence, Wallet } from "0xsequence";

/** @type { Wallet | null } */
let sequenceWallet = null;

// Initialize the wallet
try {
  sequenceWallet = sequence.initWallet({ chain: CHAIN_NAME });
} catch (e) {
  console.error(e);
  console.warn("[SEQUENCE] Connection failed");
}

/**
 * @returns {sequence.Wallet}
 */
export default function () {
  if (sequenceWallet == null) throw new Error("Wallet not initialized");
  return sequenceWallet;
}
