import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import direction_down from "../../assets/images/direction-down.svg";
import Right_icon from "../../assets/images/Right-icon.svg";
import { setOrder, setOrderBy } from "../../store/Items/actions";
import x from "../../assets/images/x.svg";

const Sort = ({ sortOpen, togglesort }) => {
  const dispatch = useDispatch();
  const [Selected, setselected] = useState("Asset #: high to low");

  const handleChange = async (orderBy, order) => {
    dispatch(setOrder(order));
    dispatch(setOrderBy(orderBy));
  };

  const handleSelectFilterClick = (filter) => {
    setselected(filter);
    handleChangeOrder(filter);
  };

  const handleChangeOrder = (filter) => {
    switch (filter) {
      case "Asset #: low to high":
        handleChange("nftId", "asc");
        break;
      case "Asset #: high to low":
        handleChange("nftId", "desc");
        break;
      case "Alphabethical: A to Z":
        handleChange("name", "asc");
        break;
      case "Alphabetical: Z to A":
        handleChange("name", "desc");
        break;
      case "Rarity: high to low":
        handleChange("rarity", "desc");
        break;
      case "Rarity: low to high":
        handleChange("rarity", "asc");
        break;
      case "Price per day: low to high":
        handleChange("price", "asc");
        break;
      case "Price per day: high to low":
        handleChange("price", "desc");
        break;
      case "Revenue share: low to high":
        handleChange("revenue", "asc");
        break;
      case "Revenue share: high to low":
        handleChange("revenue", "desc");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal isOpen={sortOpen} toggle={togglesort} className="Sidebar-Mobile">
        <div className="filterheader d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Filters</h4>
          <img className="btn-close-modal" src={x} onClick={togglesort} />
        </div>
        <ModalBody className="filterbody">
          <div>
            <Dropdown className="Drop-down">
              <div className="sort-mobile-menu">
                <Dropdown.Item
                  className={`${
                    Selected === "Asset #: low to high" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Asset #: low to high");
                    handleChange("nftId", "asc");
                  }}
                >
                  Asset #: low to high
                  {Selected === "Asset #: low to high" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Asset #: high to low" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Asset #: high to low");
                    handleChange("nftId", "desc");
                  }}
                >
                  Asset #: high to low
                  {Selected === "Asset #: high to low" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Alphabethical: A to Z" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Alphabethical: A to Z");
                    handleChange("name", "asc");
                  }}
                >
                  Alphabethical: A to Z
                  {Selected === "Alphabethical: A to Z" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Alphabetical: Z to A" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Alphabetical: Z to A");
                    handleChange("name", "desc");
                  }}
                >
                  Alphabetical: Z to A
                  {Selected === "Alphabetical: Z to A" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Rarity: high to low" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Rarity: high to low");
                    handleChange("rarity", "desc");
                  }}
                >
                  Rarity: high to low
                  {Selected === "Rarity: high to low" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Rarity: low to high" ? "ActiveSelect" : ""
                  }`}
                  onClick={() => {
                    setselected("Rarity: low to high");
                    handleChange("rarity", "asc");
                  }}
                >
                  Rarity: low to high
                  {Selected === "Rarity: low to high" && (
                    <img src={Right_icon} />
                  )}
                </Dropdown.Item>

                <Dropdown.Item
                  className={`${
                    Selected === "Price per day: high to low"
                      ? "ActiveSelect"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectFilterClick("Price per day: high to low")
                  }
                >
                  Price per day: high to low
                  {Selected === "Price per day: high to low" && (
                    <img src={Right_icon} alt="right icon" />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Price per day: low to high"
                      ? "ActiveSelect"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectFilterClick("Price per day: low to high")
                  }
                >
                  Price per day: low to high
                  {Selected === "Price per day: low to high" && (
                    <img src={Right_icon} alt="right icon" />
                  )}
                </Dropdown.Item>

                <Dropdown.Item
                  className={`${
                    Selected === "Revenue share: high to low"
                      ? "ActiveSelect"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectFilterClick("Revenue share: high to low")
                  }
                >
                  Revenue share: high to low
                  {Selected === "Revenue share: high to low" && (
                    <img src={Right_icon} alt="right icon" />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    Selected === "Revenue share: low to high"
                      ? "ActiveSelect"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectFilterClick("Revenue share: low to high")
                  }
                >
                  Revenue share: low to high
                  {Selected === "Revenue share: low to high" && (
                    <img src={Right_icon} alt="right icon" />
                  )}
                </Dropdown.Item>
              </div>
            </Dropdown>
          </div>
        </ModalBody>
        <ModalFooter className="filterfooter">
          <div className="Applysort-btn-grad">
            <Button onClick={togglesort} className="Apply-btn">
              <span>Apply</span>
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Sort;
