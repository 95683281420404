import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer" id='community'>
        <div className="container text-center">
          <div className="row">
            <div className="col-12">
              <nav className="footer-nav">
                <a href="https://discord.gg/boomland" target="_blank">
                  <i className="fab fa-discord" />
                </a>
                <a href="https://t.me/BoomlandChat" className="position-relative" target="_blank">
                  <i className="fab fa-telegram" />
                  <span className="supscript">chat</span>
                </a>
                <a href="https://t.me/BoomlandAnnouncements" className="position-relative" target="_blank">
                  <i className="fab fa-telegram" />
                  <span className="supscript">news</span>
                </a>
                <a href="https://twitter.com/BoomLandGames" target="_blank">
                  <i className="fab fa-twitter" />
                </a>
                <a href="https://www.instagram.com/boomlandgames" target="_blank">
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/boomlandgames/" target="_blank">
                  <i className="fab fa-linkedin" />
                </a>
                <a href="https://boomlandgames.medium.com/" target="_blank">
                  <i className="fab fa-medium" />
                </a>
              </nav>
              <p className="footer-copyright">
                Copyright © 2023 The BoomLand Group. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
