import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { MetamaskConnectProvider } from "../MetamaskConnectContext";
import { WalletConnectProvider } from "../WalletConnectContext";
import Header from "./Header";

const MainLayout = ({ children, disableWallet }) => {
  const loaderon = useSelector((state) => state.Items.loaderon);
  return (
    <MetamaskConnectProvider>
      <WalletConnectProvider>
        <div className="main">
          <Header disableWallet={disableWallet} />
          {children}
          {loaderon && <Loader />}
        </div>
      </WalletConnectProvider>
    </MetamaskConnectProvider>
  );
};

export default MainLayout;
