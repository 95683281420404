import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import {
  Button,
  Progress,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
  Col,
  Row,
} from "reactstrap";
import Header from "../../components/Header";

import { chestData } from "../../data";

import { SocialMediaContainer } from "../../components/SocialMediaContainer";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import Tier from "../../assets/store/tier.png";
import TierSm from "../../assets/store/tier-sm.png";
import ShoppingBasket from "../../assets/store/shopping-basket.png";
import BuyBox from "../../assets/chest/buy-box.png";
import storeleft from "../../assets/store/storeleft.png";
import storeright from "../../assets/store/storeright.png";
import storediamond from "../../assets/store/storediamond.png";
import l1 from "../../assets/store/l1.png";
import e1 from "../../assets/store/e1.png";
import r1 from "../../assets/store/r1.png";
import u1 from "../../assets/store/u1.png";
import cc from "../../assets/store/CharacterCard.png";

import backgroundclean from "../../assets/store/background-clean.png";
import buybutton from "../../assets/store/buynow.png";
import walletempty from "../../assets/store/walletempty.png";

import openbutton from "../../assets/store/open.png";

import { Route, Link, Routes, useParams, useNavigate } from "react-router-dom";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getChestCount } from "../../store/Store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useWalletConnect } from "../../context";
import { openChestAndGetHunter } from "../../chain/chest";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);

export const ItemTypes = {
  BOX: "box",
};

const style = {
  cursor: "move",
  float: "left",
};

const styledrop = {
  height: "100%",
  width: "100%",
};

const ChestOpen = () => {
  const { walletAddress } = useWalletConnect();
  const [tooltipOpen, settooltipOpen] = useState(false);
  const [ytooltipOpen, setytooltipOpen] = useState(false);
  const [rtooltipOpen, setrtooltipOpen] = useState(false);

  const [showchestBtn, setshowchestBtn] = useState(false);

  const [labelState, setLabelState] = useState(0);
  const [blankWallet, setBlankWallet] = useState(true);

  const [Dragabletrue, setDragabletrue] = useState(false);

  const Chestcount = useSelector((state) => state.StoreHunter.Chestcount);

  const getButtonLabel = (state) => {
    if (state == 0) return "Connect your wallet";
    if (state == 1) return "Your wallet is empty";
  };

  useEffect(() => {
    if (Chestcount == 0) {
      setLabelState(1);
    } else {
      setLabelState(2);
    }
  }, [Chestcount]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (walletAddress) {
      dispatch(getChestCount(walletAddress));
      setLabelState(1);
    } else {
      setLabelState(0);
    }
  }, [walletAddress]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  const toggle = () => {
    settooltipOpen(!tooltipOpen);
  };
  const ytoggle = () => {
    setytooltipOpen(!ytooltipOpen);
  };
  const rtoggle = () => {
    setrtooltipOpen(!rtooltipOpen);
  };

  const Box = function Box({ name }) {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.BOX,
      item: { name },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          // navigate('/storedetail')
          if (Dragabletrue) {
            setshowchestBtn(true);
          }
        }
      },
      collect: (monitor) => ({
        isDragging: Dragabletrue ? monitor.isDragging() : false,
        handlerId: monitor.getHandlerId(),
      }),
    }));
    const opacity = isDragging ? 0.0 : 1;
    return (
      <div ref={drag} style={{ ...style, opacity }} data-testid={`box`}>
        <img src={storeleft} alt="storeleft" />
      </div>
    );
  };

  const Dustbin = () => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.BOX,
      drop: () => ({ name: "Dustbin" }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }));

    return (
      <div
        className={`store-right`}
        ref={drop}
        style={{ ...styledrop }}
        data-testid="dustbin"
      >
        <img
          src={storeright}
          className={`${
            showchestBtn ? "v-store-img-hide d-none" : "v-store-img-show"
          } storeright-img `}
          alt="storeright-img"
        />
        <img
          src={require("../../assets/store/altarright.png")}
          className={`${
            showchestBtn ? "v-store-img-show" : "v-store-img-hide d-none"
          } storeright-img`}
          alt="storeright-img"
        />
        <div
          onClick={() => {
            openChestAndGetHunter(walletAddress);
            navigate("/storedetail");
          }}
          className={`${
            showchestBtn ? "v-store-img-show" : "v-store-img-hide d-none"
          } btnopen-chest px-5 mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center`}
        >
          <img src={openbutton} className="open-chest-button" />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="container-x">
        <div className="sortbar">
          <h3> Open Chests</h3>
        </div>
        <span className="border-b" />
        <div className="mt-30 store-card-bg">
          <Row gap="4">
            <Col
              className="open-chest-buy-banner"
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <div className="bg-img-openchest">
                <img src={backgroundclean} />
              </div>
              <div className="btn-buy-openchest btn-buy-now mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show">
                <a
                  href="https://polygon.magiceden.io/collections/polygon/0x5fdf45a0ff4883b9f681b1800d6670d1d155811d"
                  target="_blank"
                >
                  <img src={buybutton} />
                </a>
              </div>
              <div className="btn-chest-rectangle mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show">
                <div className="btn-chest-rectangle-text mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show font-face-bad-blockhead">
                  <p> {getButtonLabel(labelState)}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* 
        <div className="mt-30 store-card-bg">
          <h4 className="store-header-h4">What’s inside the Chest?</h4>
          <Row gap="4">
            <Col className="mb-4 mb-lg-0" sm={12} md={12} lg={5} xl={5}>
              <Card className=" tiercard store-card-shadow">
                <CardHeader className="store-card-headername d-flex justify-content-between align-items-center">
                  <p className="">HUNTERS PER CHEST</p>
                  <p className="">1</p>
                </CardHeader>
                <CardBody className="store-card-info">
                  <div className="px-2">
                    <p className="store-card-body-title">
                      Genesis Artifact equipped
                    </p>
                    <p className="store-card-body-description">
                      Legendary BGEM
                    </p>
                    <p className="store-card-body-description">
                      100% More Earnings
                    </p>
                    <p className="store-card-body-description">
                      Premium Pass for Future Game Content
                    </p>
                    <p className="store-card-body-description">
                      Boost in Boom Power (Tier 4)
                    </p>
                  </div>
                  <div>
                    <img src={storediamond} alt="storediamond" />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7}>
              <div>
                <img src={cc} />
              </div>
            </Col>
          </Row>
        </div>
        */}
        <div className="pb-3 defi-footer">
          <div className="defi-footer-content">
            <a href="https://discord.com/invite/boomland" target="_blank">
              <img src={discord} />
            </a>
            <a href="https://t.me/BoomlandAnnouncements" target="_blank">
              <img src={teligram} />
            </a>
            <a href="https://twitter.com/BoomLandGames" target="_blank">
              <img src={twitter} />
            </a>
            <a href="https://boomlandgames.medium.com/" target="_blank">
              <img src={medium} />
            </a>
          </div>
        </div>
        <div className="defi-copright">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </Fragment>
  );
};

export default ChestOpen;
