import React, { Fragment, useState } from "react";
import Header from "../../components/Header";
import "./goldenticket.css";
import "./custom.css";
import { useGoldenTicket, useMetamaskConnect } from "../../context";
import { SocialMediaContainer } from "../../components/SocialMediaContainer";
import Image from "./goldenticket.png";
import { Modal } from "./Modal";
import { useChainConfig } from "../../utils/useChainConfig";
import { useStepCountdownTimer } from "../../utils";
import { kycService } from "../../services";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";

const GoldenTicketContractErrors = {
  InsufficentMintFee: "Insufficient mint fee",
  MaxMintPerWallet: "Max mint per wallet exceeded",
  UnknownError: "An error occured",
};

const getGoldenTicketContractErrorMessage = (e) => {
  if (typeof e !== "string") {
    return;
  }

  for (const [k, v] of Object.entries(GoldenTicketContractErrors)) {
    if (e.includes(v)) {
      return GoldenTicketContractErrors[k];
    }
  }

  return GoldenTicketContractErrors.UnknownError;
};

const kycFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("This is not a valid email")
    .required("Email is required!"),
});

export function GoldenTicket() {
  const { account } = useMetamaskConnect();

  const { counter, step } = useStepCountdownTimer([
    "25 November 2022 11:00:00 GMT+0200",
  ]);

  const kycForm = useForm({
    resolver: yupResolver(kycFormSchema),
  });

  const [goldenTicketModal, setGoldenTicketModal] = useState({
    isOpen: false,
    isSucceed: true,
    description: "",
  });

  const goldenTicket = useGoldenTicket();

  const chainConfig = useChainConfig();

  const mint = async () => {
    await chainConfig.switchToNetwork();
    if (!(await chainConfig.isCorrectNetwork())) {
      setGoldenTicketModal({
        isOpen: true,
        isSucceed: false,
        description: "You're in the wrong network!",
      });
      return;
    }

    try {
      // const provider = ethers.getDefaultProvider();
      // console.log(account)
      // const balance = await provider.getBalance(account).then(res => {
      //     console.log(res)
      //     const balanceInEth = ethers.utils.formatEther(res)
      //     return balanceInEth
      // });
      // console.log(balance)
      // if (balance < 0.2) {
      //     setGoldenTicketModal({
      //         isOpen: true,
      //         isSucceed: false,
      //         description: "Insufficent balance for minting golden ticket"
      //     });
      //     return;
      // }
      await goldenTicket?.mintGoldenTicket();
      setGoldenTicketModal({
        isOpen: true,
        isSucceed: true,
        description: "You successfully got the golden ticket",
      });
    } catch (e) {
      // error message
      // console.log("basarisiz", e);
      setGoldenTicketModal({
        isOpen: true,
        isSucceed: false,
        description: getGoldenTicketContractErrorMessage(e.message),
      });
    }
  };

  const closeGoldenTicketModal = () => {
    setGoldenTicketModal({
      isOpen: false,
      description: "",
    });
  };

  const handleKycFormSubmit = (data) => {
    if (!account) {
      // you need to connect your wallet first
      setGoldenTicketModal({
        isOpen: true,
        isSucceed: false,
        description: "You need to connect your wallet first!",
      });
    }
    kycCreateMutation.mutate({ email: data.email, address: account });
  };

  const kycCreateMutation = useMutation(
    (data) => {
      return kycService.matchEmailAndAddress(data);
    },
    {
      onSuccess: () => {
        // show modal
        setGoldenTicketModal({
          isOpen: true,
          isSucceed: true,
          description: "You successfully completed the registration step",
        });
      },
      onError: () => {
        // show error modal
        setGoldenTicketModal({
          isOpen: true,
          isSucceed: false,
          description: "A network error occured",
        });
      },
    }
  );

  return (
    <Fragment>
      <Header />
      <div
        className="section section-home section-welcome2"
        style={{
          padding: 0,
          marginTop: 150,
        }}
      >
        <div className="grid">
          {/* <div className="description">
            <div className="description-first-text">
              Get A <span style={{ color: "#ECAD2C" }}>Golden Ticket</span> Into
            </div>
            <div className="description-title">BOOMLAND</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                margin: "30px 20px",
              }}
            >
              <p>
                Golden Tickets help you get a guaranteed whitelist and a
                headstart in <strong>BoomLand.</strong>
              </p>
              <p>Purchase your chest without FOMO, Gas Wars or a worry.</p>
              <p>
                Get your Golden Ticket now and become on of{" "}
                <strong>the Founders of BoomLand!</strong>
              </p>
            </div>
          </div> */}
          <div className="golden-ticket-cards">
            <div className="get-ticket">
              <div className="heading">
                Get A <span style={{ color: "#ECAD2C" }}>Golden Ticket</span>{" "}
                Into
              </div>
              <div className="boomland-title">BOOMLAND</div>
              <p>
                Golden Tickets help you get a guaranteed whitelist and a
                headstart in <strong>BoomLand.</strong>
              </p>
            </div>

            <div className="benefits">
              <div className="heading" style={{ fontSize: 32 }}>
                Benefits
              </div>
              <p>
                Golden tickets give you a guaranteed whitelist as well as these
                exclusive benefits:
              </p>
              <ol>
                <li>Early access to minting event for the best prices</li>
                <li>Early access to the game</li>
              </ol>
            </div>

            <div className="how-to-get">
              <div className="heading" style={{ fontSize: 32 }}>
                How To Get
              </div>
              <p>You have 2 options, choose the one that works for you:</p>
              <ol>
                <li>Buy your ticket for 25 $MATIC</li>
                <li>Visit our Discord and participate in contests</li>
              </ol>
              <p>
                Get your Golden Ticket and become one of the Founders of
                BoomLand!
              </p>
            </div>

            <form
              className="time"
              onSubmit={kycForm.handleSubmit(handleKycFormSubmit)}
            >
              <div className="time-title">Don't Miss Out: Mint Now!</div>
              <div className="hours">
                <div className="hour">
                  <span className="time-time-title">{counter[0].value}</span>
                  <span className="desc">{counter[0].type}</span>
                </div>
                <div className="seperator">:</div>
                <div className="hour">
                  <span className="time-time-title">{counter[1].value}</span>
                  <span className="desc">{counter[1].type}</span>
                </div>
                <div className="seperator">:</div>
                <div className="hour">
                  <span className="time-time-title">{counter[2].value}</span>
                  <span className="desc">{counter[2].type}</span>
                </div>
              </div>
              {step === 0 && (
                <>
                  <input
                    type="text"
                    placeholder="Your Email"
                    className={`golden-ticket-email ${
                      kycForm.formState.errors.email ? "error" : ""
                    }`}
                    {...kycForm.register("email")}
                    onBlur={() => kycForm.clearErrors("email")}
                  />
                  {kycForm.formState.errors.email && (
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#ff4436",
                        fontSize: 16,
                      }}
                    >
                      {kycForm.formState.errors.email.message}
                    </p>
                  )}
                  <div className="buttons" style={{ width: "100%" }}>
                    <button className="sec" type="submit">
                      Get notified when sales start
                    </button>
                  </div>
                </>
              )}
              {step === 0 && <>
              <button className="sec">Earn For Free</button>
              <button className="sec">Buy For 25 Matic</button>
              </>}
            </form>
          </div>

          <div
            className="image"
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
      <SocialMediaContainer />
      <Modal
        isOpen={goldenTicketModal.isOpen}
        isSucceed={goldenTicketModal.isSucceed}
        description={goldenTicketModal.description}
        close={closeGoldenTicketModal}
      />
    </Fragment>
  );
}
