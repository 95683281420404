import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { SequenceWalletConnectProvider } from "../SequenceWalletConnectContext";
import Header from "../../components/Header";

const SequenceMainLayout = ({ children }) => {
  const loaderon = useSelector((state) => state.Items.loaderon);
  return (
    <SequenceWalletConnectProvider>
      <div className="main">
        <Header />
        {children}
        {loaderon && <Loader />}
      </div>
    </SequenceWalletConnectProvider>
  );
};

export default SequenceMainLayout;
