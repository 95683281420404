import {
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_HUNTERS,
  GET_HUNTER_LENDING_OFFERS,
  GET_HUNTER_LENDING_OFFERS_FAIL,
  GET_HUNTERS_FAIL,
  PLAYABLE,
  ITEMS,
  COMMON_TRUE,
  UNCOMMON_TRUE,
  RARE_TRUE,
  EPIC_TRUE,
  LEGENDARY_TRUE,
  LOADER,
  LENT_TRUE,
  BORROWED_TRUE,
  GET_HUNTER_BY_ID,
  GET_HUNTER_BY_ID_FAIL,
  GET_ARTIFACT_BY_ID,
  GET_ARTIFACT_BY_ID_FAIL,
  GET_EQUIPMENT_BY_ID,
  GET_EQUIPMENT_BY_ID_FAIL,
  GET_SALE_HUNTERS_FAIL,
  GET_SALE_HUNTERS,
  GET_ALL_LISTINGS_FAIL,
  GET_ALL_LISTINGS,
  GET_SHARD_ITEM_FAIL,
  GET_SHARD_ITEM,
  LIST_HUNTER,
  LIST_HUNTER_FAIL,
  SET_SALE,
  SET_LEVEL,
  SET_REVENUE_SHARE,
  SET_DAMAGE,
  SET_HP,
  SET_MVT,
  SET_SPEED,
  SET_RANGE,
  SET_ATTACK,
  GET_LISTING_BY_ID,
  GET_LISTING_BY_ID_FAIL,
  SET_SEARCH_STRING,
  SET_ORDER,
  SET_ORDER_BY,
  INCREMENT_PENDING_HUNTERS,
  RESET_PENDING_HUNTERS,
  SET_PENDING_HUNTERS,
  RESET_ALL_FILTERS,
  HIDE_MY_ASSETS,
  SET_PRICE_PER_DAY,
} from "./actionTypes";

import {
  AllItems,
  AllHunters,
  Hunteritem,
  Artifactitem,
  Equpmentitem,
  AllSaleHunters,
} from "../../services/items/items.service";

import * as url from "../../helpers/url.helper";
import { get, post } from "../../helpers/api.helper";

export const getItems = (params, address) => async (dispatch) => {
  return await AllItems(url.GET_ITEMS(address), params)
    .then((response) => {
      return dispatch(Items(response.data.response));
    })
    .catch((error) => {
      dispatch(ItemsFail(error));
    });
};

export const getHunters = (params, address) => async (dispatch) => {
  return await AllHunters(url.GET_HUNTERS(address), params)
    .then((response) => {
      return dispatch(Hunters(response.data.response));
    })
    .catch((error) => {
      dispatch(HuntersFail(error));
    });
};

export const getHunterLendingOffers = (params) => async (dispatch) => {
  return await AllHunters(url.GET_HUNTER_LENDING_OFFERS, params)
    .then((response) => {
      return dispatch(LentHunters(response.data.response));
    })
    .catch((error) => {
      dispatch(HuntersFail(error));
    });
};

export const getSaleHunters = (params) => async (dispatch) => {
  return await AllSaleHunters(url.GET_SALE_HUNTERS, params)
    .then((response) => {
      return dispatch(SaleHunters(response.data.response));
    })
    .catch((error) => {
      dispatch(SaleHuntersFail(error));
    });
};

export const getAllListings = (params) => async (dispatch) => {
  return await get(url.GET_ALL_LISTINGS, params, {})
    .then((response) => {
      return dispatch(AllListings(response.data.response));
    })
    .catch((error) => {
      dispatch(AllListingsFail(error));
    });
};

export const getHunterbyid = (id, params) => async (dispatch) => {
  return await Hunteritem(url.GET_HUNTER_BY_ID(id), params, {})
    .then((response) => {
      return dispatch(gethunteritem(response.data.response));
    })
    .catch((error) => {
      dispatch(gethunteritemFail(error));
    });
};

export const getArtifactbyid = (id, params) => async (dispatch) => {
  return await Artifactitem(url.GET_ARTIFACT_BY_ID(id), params)
    .then((response) => {
      return dispatch(getartifactitem(response.data.response));
    })
    .catch((error) => {
      dispatch(getartifactitemFail(error));
    });
};

export const getEqupmentbyid = (id, params) => async (dispatch) => {
  return await Equpmentitem(url.GET_EQUPMENT_BY_ID(id), params)
    .then((response) => {
      return dispatch(getequpmentitem(response.data.response));
    })
    .catch((error) => {
      dispatch(getequpmentitemFail(error));
    });
};

export const getShardbyid = (id, params) => async (dispatch) => {
  return await Equpmentitem(url.GET_SHARD_BY_ID(id), params, {})
    .then((response) => {
      return dispatch(ShardItem(response.data.response));
    })
    .catch((error) => {
      dispatch(ShardItemFail(error));
    });
};

export const listingAsset = (params) => async (dispatch) => {
  return await post(url.POST_HUNTER_LISTINGS, params, {})
    .then((response) => {
      return dispatch(ListingHunter(response.data.response));
    })
    .catch((error) => {
      dispatch(ListingHunterFail(error));
    });
};

export const getListingDataById = (params) => async (dispatch) => {
  return await get(url.GET_LISTING_BY_ID, params, {})
    .then((response) => {
      return dispatch(ListingDataById(response.data.response));
    })
    .catch((error) => {
      dispatch(ListingDataByIdFail(error));
    });
};

export const Items = (data) => {
  return {
    type: GET_ITEMS,
    payload: data,
  };
};

export const Hunters = (data) => {
  return {
    type: GET_HUNTERS,
    payload: data,
  };
};

export const LentHunters = (data) => {
  return {
    type: GET_HUNTER_LENDING_OFFERS,
    payload: data,
  };
};

export const ItemsFail = (err) => {
  return {
    type: GET_ITEMS_FAIL,
    payload: err,
  };
};

export const HuntersFail = (err) => {
  return {
    type: GET_HUNTERS_FAIL,
    payload: err,
  };
};

export const LentHuntersFail = (err) => {
  return {
    type: GET_HUNTER_LENDING_OFFERS_FAIL,
    payload: err,
  };
};

export const playabletrue = (Boolean) => {
  return {
    type: PLAYABLE,
    payload: Boolean,
  };
};

export const itemstrue = (Boolean) => {
  return {
    type: ITEMS,
    payload: Boolean,
  };
};

export const CommonTrue = (number) => {
  return {
    type: COMMON_TRUE,
    payload: number,
  };
};

export const UncommonTrue = (number) => {
  return {
    type: UNCOMMON_TRUE,
    payload: number,
  };
};

export const RareTrue = (number) => {
  return {
    type: RARE_TRUE,
    payload: number,
  };
};

export const EpicTrue = (number) => {
  return {
    type: EPIC_TRUE,
    payload: number,
  };
};
export const LegendaryTrue = (number) => {
  return {
    type: LEGENDARY_TRUE,
    payload: number,
  };
};
export const HideMyAssetsTrue = (number) => {
  return {
    type: HIDE_MY_ASSETS,
    payload: number,
  };
};
export const loaderonoff = (Boolean) => {
  return {
    type: LOADER,
    payload: Boolean,
  };
};

export const LentTrue = (number) => {
  return {
    type: LENT_TRUE,
    payload: number,
  };
};

export const BorrowedTrue = (number) => {
  return {
    type: BORROWED_TRUE,
    payload: number,
  };
};

export const gethunteritem = (data) => {
  return {
    type: GET_HUNTER_BY_ID,
    payload: data,
  };
};
export const gethunteritemFail = (err) => {
  return {
    type: GET_HUNTER_BY_ID_FAIL,
    payload: err,
  };
};

export const getartifactitem = (data) => {
  return {
    type: GET_ARTIFACT_BY_ID,
    payload: data,
  };
};
export const getartifactitemFail = (err) => {
  return {
    type: GET_ARTIFACT_BY_ID_FAIL,
    payload: err,
  };
};

export const getequpmentitem = (data) => {
  return {
    type: GET_EQUIPMENT_BY_ID,
    payload: data,
  };
};
export const getequpmentitemFail = (err) => {
  return {
    type: GET_EQUIPMENT_BY_ID_FAIL,
    payload: err,
  };
};

export const SaleHunters = (data) => {
  return {
    type: GET_SALE_HUNTERS,
    payload: data,
  };
};

export const SaleHuntersFail = (err) => {
  return {
    type: GET_SALE_HUNTERS_FAIL,
    payload: err,
  };
};

export const AllListings = (data) => {
  return {
    type: GET_ALL_LISTINGS,
    payload: data,
  };
};

export const AllListingsFail = (err) => {
  return {
    type: GET_ALL_LISTINGS_FAIL,
    payload: err,
  };
};

export const ShardItem = (data) => {
  return {
    type: GET_SHARD_ITEM,
    payload: data,
  };
};

export const ShardItemFail = (err) => {
  return {
    type: GET_SHARD_ITEM_FAIL,
    payload: err,
  };
};

export const ListingHunter = (err) => {
  return {
    type: LIST_HUNTER,
    payload: err,
  };
};

export const ListingHunterFail = (err) => {
  return {
    type: LIST_HUNTER_FAIL,
    payload: err,
  };
};

export const setOnSale = (Boolean) => {
  return {
    type: SET_SALE,
    payload: Boolean,
  };
};

export const setLevel = (data) => {
  return {
    type: SET_LEVEL,
    payload: data,
  };
};

export const setRevenueShare = (data) => {
  return {
    type: SET_REVENUE_SHARE,
    payload: data,
  };
};

export const setPricePerDay = (data) => {
  return {
    type: SET_PRICE_PER_DAY,
    payload: data,
  };
};

export const setSearchString = (data) => {
  return {
    type: SET_SEARCH_STRING,
    payload: data,
  };
};

export const setOrderBy = (data) => {
  return {
    type: SET_ORDER_BY,
    payload: data,
  };
};

export const setOrder = (data) => {
  return {
    type: SET_ORDER,
    payload: data,
  };
};

export const setDamage = (data) => {
  return {
    type: SET_DAMAGE,
    payload: data,
  };
};
export const setHp = (data) => {
  return {
    type: SET_HP,
    payload: data,
  };
};

export const setMVT = (data) => {
  return {
    type: SET_MVT,
    payload: data,
  };
};
export const setSpeed = (data) => {
  return {
    type: SET_SPEED,
    payload: data,
  };
};

export const setAttack = (data) => {
  return {
    type: SET_ATTACK,
    payload: data,
  };
};

export const setRange = (data) => {
  return {
    type: SET_RANGE,
    payload: data,
  };
};

export const ListingDataById = (err) => {
  return {
    type: GET_LISTING_BY_ID,
    payload: err,
  };
};

export const ListingDataByIdFail = (err) => {
  return {
    type: GET_LISTING_BY_ID_FAIL,
    payload: err,
  };
};

export const IncrementPendingHunters = () => {
  return {
    type: INCREMENT_PENDING_HUNTERS,
  };
};

export const SetPendingHunters = (data) => {
  return {
    type: SET_PENDING_HUNTERS,
    payload: data,
  };
};

export const ResetPendingHunters = () => {
  return {
    type: RESET_PENDING_HUNTERS,
  };
};

export const ResetAllFilters = (data) => {
  return {
    type: RESET_ALL_FILTERS,
    payload: data,
  };
};
