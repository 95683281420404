import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import direction_down from "../../assets/images/direction-down.svg";
import Right_icon from "../../assets/images/Right-icon.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { setOrder, setOrderBy } from "../../store/Items/actions";
import { useDispatch } from "react-redux";

const All = (props) => {
  useEffect(() => {
    if (!localStorage.getItem("isPageReloaded")) {
      localStorage.setItem("isPageReloaded", true);
      window.location.reload();
    }
  }, []);

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [sortAllOpen, setSortAllOpen] = useState(false);

  useEffect(() => {
    dispatch(setOrder("desc"));
    dispatch(setOrderBy("nftId"));
  }, [dispatch]);

  const handleChange = useCallback(
    (orderBy, order) => {
      dispatch(setOrder(order));
      dispatch(setOrderBy(orderBy));
    },
    [dispatch]
  );

  const handleDropdownToggle = () => {
    setSortAllOpen(!sortAllOpen);
  };

  // const handleDropdownItemClick = useCallback(
  //   (selectedItem, orderBy, order) => {
  //     setSelected(selectedItem);
  //     handleChange(orderBy, order);
  //   },
  //   [handleChange]
  // );

  useEffect(() => {
    const selectedFilter = sessionStorage.getItem("selectedFilter");
    if (selectedFilter) {
      setSelected(selectedFilter);
    } else {
      setSelected(null);
      handleChange(null);
    }
  }, [handleChange]);

  useEffect(() => {
    if (selected) {
      sessionStorage.setItem("selectedFilter", selected);
    } else {
      sessionStorage.removeItem("selectedFilter");
    }
  }, [selected]);

  const handleSelectFilterClick = (filter) => {
    setSelected(filter);
    handleChangeOrder(filter);
  };

  const handleChangeOrder = (filter) => {
    switch (filter) {
      case "Asset #: low to high":
        handleChange("nftId", "asc");
        break;
      case "Asset #: high to low":
        handleChange("nftId", "desc");
        break;
      case "Alphabethical: A to Z":
        handleChange("name", "asc");
        break;
      case "Alphabetical: Z to A":
        handleChange("name", "desc");
        break;
      case "Rarity: high to low":
        handleChange("rarity", "desc");
        break;
      case "Rarity: low to high":
        handleChange("rarity", "asc");
        break;
      case "Price per day: low to high":
        handleChange("price", "asc");
        break;
      case "Price per day: high to low":
        handleChange("price", "desc");
        break;
      case "Revenue share: low to high":
        handleChange("revenue", "asc");
        break;
      case "Revenue share: high to low":
        handleChange("revenue", "desc");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown
        className="drop"
        isOpen={sortAllOpen}
        toggle={handleDropdownToggle}
      >
        <DropdownToggle caret className="drop-btn d-flex align-items-center">
          {selected ? selected : "Select Filter"}
          <img src={direction_down} alt="dropdown icon" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className={`${
              selected === "Asset #: low to high" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Asset #: low to high")}
          >
            Asset #: low to high
            {selected === "Asset #: low to high" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Asset #: high to low" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Asset #: high to low")}
          >
            Asset #: high to low
            {selected === "Asset #: high to low" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Alphabethical: A to Z" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Alphabethical: A to Z")}
          >
            Alphabethical: A to Z
            {selected === "Alphabethical: A to Z" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Alphabetical: Z to A" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Alphabetical: Z to A")}
          >
            Alphabetical: Z to A
            {selected === "Alphabetical: Z to A" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Rarity: high to low" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Rarity: high to low")}
          >
            Rarity: high to low
            {selected === "Rarity: high to low" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Rarity: low to high" ? "ActiveSelect" : ""
            }`}
            onClick={() => handleSelectFilterClick("Rarity: low to high")}
          >
            Rarity: low to high
            {selected === "Rarity: low to high" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>

          <DropdownItem
            className={`${
              selected === "Price per day: high to low" ? "ActiveSelect" : ""
            }`}
            onClick={() =>
              handleSelectFilterClick("Price per day: high to low")
            }
          >
            Price per day: high to low
            {selected === "Price per day: high to low" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Price per day: low to high" ? "ActiveSelect" : ""
            }`}
            onClick={() =>
              handleSelectFilterClick("Price per day: low to high")
            }
          >
            Price per day: low to high
            {selected === "Price per day: low to high" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>

          <DropdownItem
            className={`${
              selected === "Revenue share: high to low" ? "ActiveSelect" : ""
            }`}
            onClick={() =>
              handleSelectFilterClick("Revenue share: high to low")
            }
          >
            Revenue share: high to low
            {selected === "Revenue share: high to low" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
          <DropdownItem
            className={`${
              selected === "Revenue share: low to high" ? "ActiveSelect" : ""
            }`}
            onClick={() =>
              handleSelectFilterClick("Revenue share: low to high")
            }
          >
            Revenue share: low to high
            {selected === "Revenue share: low to high" && (
              <img src={Right_icon} alt="right icon" />
            )}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default All;
