import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { LentTrue, BorrowedTrue } from "../../store/Items/actions";

import line from "../../assets/images/line.svg";
import plus from "../../assets/images/plus.svg";

const RentType = (props) => {
  const toggleRentType = () => props.setRentTypeOpen(!props.RentTypeOpen);

  const filterItems = useSelector((state) => state.Items);

  const [lentTrue, setLentTrue] = useState(true);
  const [borrowedTrue, setBorrowedTrue] = useState(true);

  useEffect(() => {
    setLentTrue(filterItems?.lentTrue === 1);
    setBorrowedTrue(filterItems?.borrowedTrue === 1);
  }, [filterItems]);

  const dispatch = useDispatch();

  const rentalfilterhandler = (e) => {
    const val = e.target.checked ? 1 : 0;
    switch (e.target.id) {
      case "Rent":
        dispatch(LentTrue(val));
        break;
      case "Borrowed":
        dispatch(BorrowedTrue(val));
        break;
      default:
    }
  };

  return (
    <div>
      <div
        onClick={toggleRentType}
        className={`${
          props.RentTypeOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Rent Type</div>
        {props.RentTypeOpen ? (
          <img src={line} alt="line" className="cursor-pointer" />
        ) : (
          <img src={plus} alt="plus" className="cursor-pointer" />
        )}
      </div>
      {props.RentTypeOpen && (
        <Collapse isOpen={props.RentTypeOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Rent"
                  className="m-0 cursor-pointer"
                  type="checkbox"
                  onChange={rentalfilterhandler}
                  checked={lentTrue}
                />
                <Label for="Rent" className="mb-0">
                  Listed
                </Label>
              </div>
              <div className=" row-1 d-flex align-items-center">
                <Input
                  id="Borrowed"
                  className="m-0 cursor-pointer"
                  type="checkbox"
                  // onClick={(e) => rentalfilterhandler(e)}
                  // defaultChecked={borrowedTrue}
                  onChange={rentalfilterhandler}
                  checked={borrowedTrue}
                />
                <Label for="Rented" className="mb-0">
                  Rented
                </Label>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default RentType;
