import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import config from "../config";
import { useSequence } from "../hooks/useSequence";
import { setCookie, getCookie } from "typescript-cookie";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
// import {
//   UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
// } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";

const WalletConnectContext = React.createContext(null);

const injected = new InjectedConnector({
  supportedChainIds: [config.NetId],
});

export const WalletConnectProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [tried, setTried] = useState(false);
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [connectWalletError, setConnectWalletError] = useState(null);

  const {
    activate,
    active,
    account,
    deactivate,
    connector,
    error,
    setError,
    library,
  } = useWeb3React();

  const {
    connect,
    isConnected,
    disconnectSequence,
    address,
    userAddresses,
    setUserAddresses,
    sequenceWallet,
  } = useSequence();

  const connectWallet = async (type, opts) => {
    try {
      if (type === config.Wallet.METAMASK) {
        setCookie("close", "false");
        setConnectedWallet(config.Wallet.METAMASK);
        await activate(injected);
      } else if (type === config.Wallet.SEQUENCE) {
        setConnectedWallet(config.Wallet.SEQUENCE);
        await connect(false, opts);
      } else {
        setConnectedWallet(config.Wallet.SEQUENCE_TESTNET);
        await connect(true, opts);
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const disconnectWallet = async (type) => {
    if (type === config.Wallet.METAMASK) {
      setCookie("close", "true");
      deactivate();
      setUserAddresses({ ...userAddresses, metamask: "" });
    } else {
      disconnectSequence(type);
    }
  };

  const getErrorMessage = (error) => {
    if (error instanceof NoEthereumProviderError) {
      return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network.";
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return "Please authorize this website to access your Ethereum account.";
    } else {
      console.error(error);
      return "An unknown error occurred. Check the console for more details.";
    }
  };

  useEffect(() => {
    if (error) {
      if (error instanceof NoEthereumProviderError) {
        setConnectWalletError(
          "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
        );
      } else if (error instanceof UnsupportedChainIdError) {
        setConnectWalletError("You're connected to an unsupported network.");
      } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorFrame
      ) {
        setConnectWalletError(
          "Please authorize this website to access your Ethereum account."
        );
      } else {
        console.error(error);
        setConnectWalletError(
          "An unknown error occurred. Check the console for more details."
        );
      }
    }
  }, [error]);

  useEffect(() => {
    if (getCookie("close") === "false") {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      });
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  useEffect(() => {
    if (active) {
      // setError(null);
      setConnectWalletError(null);
    }
  }, [active]);

  useEffect(() => {
    // if (isConnected && active) {
    //   setWalletConnected(true);
    //   setConnectedWallet(config.Wallet.SEQUENCE);
    //   setWalletAddress(address);
    // } else if (isConnected) {
    //   setWalletConnected(true);
    //   setConnectedWallet(config.Wallet.SEQUENCE);
    //   setWalletAddress(address);
    // } else
    if (active) {
      setConnectedWallet(config.Wallet.METAMASK);
      setWalletAddress(account);
      setWalletConnected(true);
      setUserAddresses({ ...userAddresses, metamask: account });
    } else {
      setConnectedWallet(null);
      setWalletConnected(false);
      setWalletAddress(null);
      setUserAddresses({ ...userAddresses, metamask: "" });
      disconnectSequence();
    }
  }, [active]);

  return (
    <WalletConnectContext.Provider
      value={{
        setWalletAddress,
        walletAddress,
        isWalletConnected,
        connectedWallet,
        connectWallet,
        disconnectWallet,
        connectWalletError,
        setConnectWalletError,
        getErrorMessage,
        userAddresses,
        setUserAddresses,
        sequenceWallet,
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  );
};

export const useWalletConnect = () => useContext(WalletConnectContext);
