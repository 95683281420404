import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Collapse, Input, Label } from "reactstrap";
import line from "../../assets/images/line.svg";
import plus from "../../assets/images/plus.svg";
import {
  CommonTrue,
  EpicTrue,
  LegendaryTrue,
  RareTrue,
  UncommonTrue,
} from "../../store/Items/actions";

const Rarity = (props) => {
  const toggleRarity = () => props.setRarityOpen(!props.RarityOpen);

  const filterItems = useSelector((state) => state.Items);

  const [commontrue,setcommontrue] = useState(true)
  const [uncommontrue,setuncommontrue] = useState(true)
  const [raretrue,setraretrue] = useState(true)
  const [epictrue,setepictrue] = useState(true)
  const [legendarytrue,setlegendarytrue] = useState(true)

  useEffect(()=>{
    setcommontrue(filterItems?.commontrue === 1 ? true : false)
    setuncommontrue(filterItems?.uncommontrue === 1 ? true : false)
    setraretrue(filterItems?.raretrue === 1 ? true : false)
    setepictrue(filterItems?.epictrue === 1 ? true : false)
    setlegendarytrue(filterItems?.legendarytrue === 1 ? true : false)
  },[filterItems])

  const dispatch = useDispatch();

  const rarityfilterhandler = (e) => {
    const val = e.target.checked ? 1 : 0;
    switch (e.target.id) {
      case "Common":
        dispatch(CommonTrue(val));
        break;
      case "Uncommon":
        dispatch(UncommonTrue(val));
        break;
      case "Rare":
        dispatch(RareTrue(val));
        break;
      case "Epic":
        dispatch(EpicTrue(val));
        break;
      case "Legendary":
        dispatch(LegendaryTrue(val));
        break;
      default:
    }
  };

  return (
    <div>
      <div
        onClick={toggleRarity}
        className={`${
          props.RarityOpen ? "border-radiusA" : "border-radiusB"
        }  btn-primary-colapse d-flex justify-content-between`}
      >
        <div className="colapse-heading">Rarity</div>
        {props.RarityOpen ? <img src={line} /> : <img src={plus} />}
      </div>
      {props.RarityOpen && (
        <Collapse isOpen={props.RarityOpen}>
          <Card className="card-collapse">
            <CardBody className=" cardbody-content">
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Common"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => rarityfilterhandler(e)}
                  defaultChecked={commontrue}
                  checked={commontrue}
                />
                <Label for="Common" className="mb-0">
                  Common
                </Label>
              </div>
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Uncommon"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => rarityfilterhandler(e)}
                  defaultChecked={uncommontrue}
                  checked={uncommontrue}
                />
                <Label for="Uncommon" className="green mb-0">
                  Uncommon
                </Label>
              </div>
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Rare"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => rarityfilterhandler(e)}
                  defaultChecked={raretrue}
                  checked={raretrue}
                />
                <Label for="Rare" className="blue mb-0">
                  Rare
                </Label>
              </div>
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Epic"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => rarityfilterhandler(e)}
                  defaultChecked={epictrue}
                  checked={epictrue}
                />
                <Label for="Epic" className="purpal mb-0">
                  Epic
                </Label>
              </div>
              <div className="mb-2 row-1 d-flex align-items-center">
                <Input
                  id="Legendary"
                  className="m-0"
                  type="checkbox"
                  onClick={(e) => rarityfilterhandler(e)}
                  defaultChecked={legendarytrue}
                  checked={legendarytrue}
                />
                <Label for="Legendary" className="orange mb-0">
                  Legendary
                </Label>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
};

export default Rarity;
