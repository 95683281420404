import React from "react";
import VideoThumbnail from "../../assets/home/homeThumbnail.jpg";
import { MainnetBanner } from "./MainnetBanner";
import { TestnetBanner } from "./TestnetBanner";
import config from "../../config";

class Welcome extends React.Component {
  render() {
    return (
      <section className="section-home section-welcome">
        {config.NetId === 137 ? <MainnetBanner /> : <TestnetBanner />}
        <div className="container welcome">
          <div className="welcome-video-section">
            <div className="col-lg-5 col-12 order-2 order-lg-1">
              <p className="welcome-title mb-8">
                Bringing Fun to Blockchain Gaming
              </p>

              <p className="welcome-paragraph mb-4"></p>
              <div className="welcome-actions"></div>
            </div>
            <div className="col-lg-7 offset-lg-0  mb-8 mb-lg-0 order-1 order-lg-2 z-100">
              <video
                width="100%"
                preload="auto"
                controls
                poster={VideoThumbnail}
                className="main-video-container"
                autoPlay
                muted
              >
                <source
                  src={
                    "https://xpig.cdn.boombit.cloud/other/boomland-teaser.mp4"
                  }
                  type="video/mp4"
                  controls
                />
              </video>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Welcome;
